// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingCodeSchema, typeShippingQuantitySchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingSagawaLabelShippingCodeSchema = typeShippingCodeSchema;
export type FormShippingShippingSagawaLabelShippingCodeSchemaType = InferType<typeof formShippingShippingSagawaLabelShippingCodeSchema>;
export const formShippingShippingSagawaLabelShippingQuantitySchema = typeShippingQuantitySchema.optional();
export type FormShippingShippingSagawaLabelShippingQuantitySchemaType = InferType<typeof formShippingShippingSagawaLabelShippingQuantitySchema>;
export const formShippingShippingSagawaLabelIsReissueSchema = yup.boolean().required().label("schema.shipping.label.isreissue");
export type FormShippingShippingSagawaLabelIsReissueSchemaType = InferType<typeof formShippingShippingSagawaLabelIsReissueSchema>;
export const formShippingShippingSagawaLabelSchema = yup.object({
	shippingCode: formShippingShippingSagawaLabelShippingCodeSchema,
	shippingQuantity: formShippingShippingSagawaLabelShippingQuantitySchema,
	isReissue: formShippingShippingSagawaLabelIsReissueSchema,
}).required();
export type FormShippingShippingSagawaLabelSchemaType = InferType<typeof formShippingShippingSagawaLabelSchema>;
