// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupIdSchema, typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonQuantitySchema } from "./../../common";

export const formStockBreakSetProductScheduleEntrySkuGroupIdSchema = typeMasterProductSkuGroupIdSchema;
export type FormStockBreakSetProductScheduleEntrySkuGroupIdSchemaType = InferType<typeof formStockBreakSetProductScheduleEntrySkuGroupIdSchema>;
export const formStockBreakSetProductScheduleEntrySkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema;
export type FormStockBreakSetProductScheduleEntrySkuGroupCodeSchemaType = InferType<typeof formStockBreakSetProductScheduleEntrySkuGroupCodeSchema>;
export const formStockBreakSetProductScheduleEntrySkuGroupNameSchema = typeMasterProductSkuGroupNameSchema;
export type FormStockBreakSetProductScheduleEntrySkuGroupNameSchemaType = InferType<typeof formStockBreakSetProductScheduleEntrySkuGroupNameSchema>;
export const formStockBreakSetProductScheduleEntryQuantitySchema = typeCommonQuantitySchema;
export type FormStockBreakSetProductScheduleEntryQuantitySchemaType = InferType<typeof formStockBreakSetProductScheduleEntryQuantitySchema>;
export const formStockBreakSetProductScheduleEntrySchema = yup.object({
	skuGroupId: formStockBreakSetProductScheduleEntrySkuGroupIdSchema,
	skuGroupCode: formStockBreakSetProductScheduleEntrySkuGroupCodeSchema,
	skuGroupName: formStockBreakSetProductScheduleEntrySkuGroupNameSchema,
	quantity: formStockBreakSetProductScheduleEntryQuantitySchema,
}).required();
export type FormStockBreakSetProductScheduleEntrySchemaType = InferType<typeof formStockBreakSetProductScheduleEntrySchema>;
