// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formStockBreakSetProductInspectionResultSkuGroupDetailIdValueSchema = yup.number().optional().integer();
export type FormStockBreakSetProductInspectionResultSkuGroupDetailIdValueSchemaType = InferType<typeof formStockBreakSetProductInspectionResultSkuGroupDetailIdValueSchema>;
export const formStockBreakSetProductInspectionResultSkuGroupDetailIdSchema = yup.array(
	formStockBreakSetProductInspectionResultSkuGroupDetailIdValueSchema
).optional();
export type FormStockBreakSetProductInspectionResultSkuGroupDetailIdSchemaType = InferType<typeof formStockBreakSetProductInspectionResultSkuGroupDetailIdSchema>;
export const formStockBreakSetProductInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormStockBreakSetProductInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formStockBreakSetProductInspectionResultInspectionQuantityValueSchema>;
export const formStockBreakSetProductInspectionResultInspectionQuantitySchema = yup.array(
	formStockBreakSetProductInspectionResultInspectionQuantityValueSchema
).optional();
export type FormStockBreakSetProductInspectionResultInspectionQuantitySchemaType = InferType<typeof formStockBreakSetProductInspectionResultInspectionQuantitySchema>;
export const formStockBreakSetProductInspectionResultSchema = yup.object({
	skuGroupDetailId: formStockBreakSetProductInspectionResultSkuGroupDetailIdSchema,
	inspectionQuantity: formStockBreakSetProductInspectionResultInspectionQuantitySchema,
}).required();
export type FormStockBreakSetProductInspectionResultSchemaType = InferType<typeof formStockBreakSetProductInspectionResultSchema>;
