/** 出荷前検品 */
import { number, object } from 'yup';
import { BasicResponse } from '../../response';
import { formShippingShippingInspectionSchema } from '../../schema/form/shipping/shippingInspection';
import { formShippingShippingInspectionMobileEntrySchema } from '../../schema/form/shipping/shippingInspectionMobileEntry';
import { formShippingShippingInspectionResultSchema } from '../../schema/form/shipping/shippingInspectionResult';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formShippingShippingInspectionEntrySchema } from '../../schema/form/shipping/shippingInspectionEntry';

export type ShippingInspectionResponse = BasicResponse & {
	inspectionId: number;
	inspectionLog: ShippingInspectionLog[];

	shippingDetailId: number;
	shippingScheduledQuantity: number; // 出荷予定数量
	shippingQuantity: number | undefined; // 数量
	skuId: number; // SKUID
	skuCode: string; // SKUコード
	productId: number; // 商品ID
	productCode: string; // 商品コード
	productName: string; // 商品名

	shippingId: number; // 出荷ID
	shippingCode: string; // 出荷コード
	shippingScheduledDate: string;
	shippingDate: string | undefined;
};

type ShippingInspectionLog = {
	inspectionLogId: number;
	lotId: number;
	inspectionQuantity: number;
};

const findApi = createApiInterface()
	.request(formShippingShippingInspectionSchema)
	.response<{
		list: ShippingInspectionResponse[];
	}>();

const registApi = createApiInterface()
	.request(object({ form: formShippingShippingInspectionResultSchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

const mobileRegistApi = createApiInterface()
	.request(object({ form: formShippingShippingInspectionMobileEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();
	
const entryApi = createApiInterface()
	.request(object({ form: formShippingShippingInspectionEntrySchema, id: number().integer().required() }))
	.response<{
		isSuccess: true;
	}>();

export const shippingInspectionApi = createApiInterfaceMap({
	['/v1/shipping/inspection/find']: findApi,
	['/v1/shipping/inspection/regist']: registApi,
	['/v1/shipping/inspection/delete']: deleteApi,
	['/v1/shipping/inspection/mobile']: mobileRegistApi,

	['/v1/shipping/inspection/entry']: entryApi,
});
