// fv-generatorで生成されました
import * as yup from "yup";
import { typeIssuingCodeSchema, typeIssuingNameSchema, typeIssuingDateSchema, typeIssuingCategoryIdSchema, typeIssuingMovingSourceLocationDetailIdSchema, typeIssuingMovingSourceLocationDetailCodeSchema, typeIssuingMovingSourceLocationDetailNameSchema, typeIssuingDestinationLocationDetailIdSchema, typeIssuingDestinationLocationDetailCodeSchema, typeIssuingDestinationLocationDetailNameSchema, typeIssuingDetailIdSchema, typeIssuingScheduledQuantitySchema, typeIssuingQuantitySchema } from "./../../issuing";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";

export const formIssuingIssuingResultEntryReceiveIssueCodeSchema = typeIssuingCodeSchema;
export type FormIssuingIssuingResultEntryReceiveIssueCodeSchemaType = InferType<typeof formIssuingIssuingResultEntryReceiveIssueCodeSchema>;
export const formIssuingIssuingResultEntryReceiveIssueNameSchema = typeIssuingNameSchema;
export type FormIssuingIssuingResultEntryReceiveIssueNameSchemaType = InferType<typeof formIssuingIssuingResultEntryReceiveIssueNameSchema>;
export const formIssuingIssuingResultEntryIssuingDateSchema = typeIssuingDateSchema;
export type FormIssuingIssuingResultEntryIssuingDateSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDateSchema>;
export const formIssuingIssuingResultEntryReceiveIssueCategoryIdSchema = typeIssuingCategoryIdSchema;
export type FormIssuingIssuingResultEntryReceiveIssueCategoryIdSchemaType = InferType<typeof formIssuingIssuingResultEntryReceiveIssueCategoryIdSchema>;
export const formIssuingIssuingResultEntryMovingSourceLocationDetailIdSchema = typeIssuingMovingSourceLocationDetailIdSchema;
export type FormIssuingIssuingResultEntryMovingSourceLocationDetailIdSchemaType = InferType<typeof formIssuingIssuingResultEntryMovingSourceLocationDetailIdSchema>;
export const formIssuingIssuingResultEntryMovingSourceLocationDetailCodeSchema = typeIssuingMovingSourceLocationDetailCodeSchema;
export type FormIssuingIssuingResultEntryMovingSourceLocationDetailCodeSchemaType = InferType<typeof formIssuingIssuingResultEntryMovingSourceLocationDetailCodeSchema>;
export const formIssuingIssuingResultEntryMovingSourceLocationDetailNameSchema = typeIssuingMovingSourceLocationDetailNameSchema;
export type FormIssuingIssuingResultEntryMovingSourceLocationDetailNameSchemaType = InferType<typeof formIssuingIssuingResultEntryMovingSourceLocationDetailNameSchema>;
export const formIssuingIssuingResultEntryDestinationLocationDetailIdSchema = typeIssuingDestinationLocationDetailIdSchema.optional();
export type FormIssuingIssuingResultEntryDestinationLocationDetailIdSchemaType = InferType<typeof formIssuingIssuingResultEntryDestinationLocationDetailIdSchema>;
export const formIssuingIssuingResultEntryDestinationLocationDetailCodeSchema = typeIssuingDestinationLocationDetailCodeSchema.optional();
export type FormIssuingIssuingResultEntryDestinationLocationDetailCodeSchemaType = InferType<typeof formIssuingIssuingResultEntryDestinationLocationDetailCodeSchema>;
export const formIssuingIssuingResultEntryDestinationLocationDetailNameSchema = typeIssuingDestinationLocationDetailNameSchema.optional();
export type FormIssuingIssuingResultEntryDestinationLocationDetailNameSchemaType = InferType<typeof formIssuingIssuingResultEntryDestinationLocationDetailNameSchema>;
export const formIssuingIssuingResultEntryReceiveIssueNoteSchema = typeCommonNoteSchema;
export type FormIssuingIssuingResultEntryReceiveIssueNoteSchemaType = InferType<typeof formIssuingIssuingResultEntryReceiveIssueNoteSchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailIdSchema = typeIssuingDetailIdSchema.optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailIdSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailIdSchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueSkuIdSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueSkuIdSchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueIssuingScheduledQuantitySchema = typeIssuingScheduledQuantitySchema.optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueIssuingScheduledQuantitySchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueIssuingScheduledQuantitySchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueIssuingQuantitySchema = typeIssuingQuantitySchema.optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueIssuingQuantitySchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueIssuingQuantitySchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailNoteSchema = typeCommonNoteSchema;
export type FormIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailNoteSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailNoteSchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueProductIdSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueProductIdSchema>;
export const formIssuingIssuingResultEntryIssuingDetailValueSchema = yup.object({
	receiveIssueDetailId: formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailIdSchema,
	skuId: formIssuingIssuingResultEntryIssuingDetailValueSkuIdSchema,
	issuingScheduledQuantity: formIssuingIssuingResultEntryIssuingDetailValueIssuingScheduledQuantitySchema,
	issuingQuantity: formIssuingIssuingResultEntryIssuingDetailValueIssuingQuantitySchema,
	receiveIssueDetailNote: formIssuingIssuingResultEntryIssuingDetailValueReceiveIssueDetailNoteSchema,
	productId: formIssuingIssuingResultEntryIssuingDetailValueProductIdSchema,
}).optional();
export type FormIssuingIssuingResultEntryIssuingDetailValueSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailValueSchema>;
export const formIssuingIssuingResultEntryIssuingDetailSchema = yup.array(
	formIssuingIssuingResultEntryIssuingDetailValueSchema
).required();
export type FormIssuingIssuingResultEntryIssuingDetailSchemaType = InferType<typeof formIssuingIssuingResultEntryIssuingDetailSchema>;
export const formIssuingIssuingResultEntrySchema = yup.object({
	receiveIssueCode: formIssuingIssuingResultEntryReceiveIssueCodeSchema,
	receiveIssueName: formIssuingIssuingResultEntryReceiveIssueNameSchema,
	issuingDate: formIssuingIssuingResultEntryIssuingDateSchema,
	receiveIssueCategoryId: formIssuingIssuingResultEntryReceiveIssueCategoryIdSchema,
	movingSourceLocationDetailId: formIssuingIssuingResultEntryMovingSourceLocationDetailIdSchema,
	movingSourceLocationDetailCode: formIssuingIssuingResultEntryMovingSourceLocationDetailCodeSchema,
	movingSourceLocationDetailName: formIssuingIssuingResultEntryMovingSourceLocationDetailNameSchema,
	destinationLocationDetailId: formIssuingIssuingResultEntryDestinationLocationDetailIdSchema,
	destinationLocationDetailCode: formIssuingIssuingResultEntryDestinationLocationDetailCodeSchema,
	destinationLocationDetailName: formIssuingIssuingResultEntryDestinationLocationDetailNameSchema,
	receiveIssueNote: formIssuingIssuingResultEntryReceiveIssueNoteSchema,
	issuingDetail: formIssuingIssuingResultEntryIssuingDetailSchema,
}).required();
export type FormIssuingIssuingResultEntrySchemaType = InferType<typeof formIssuingIssuingResultEntrySchema>;
