// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingDateSchema, typeShippingScheduledDateSchema, typeShippingCodeSchema, typeShippingPackagingCodeSchema, typeShippingOrderCodeSchema, typeShippingStatusSchema } from "./../../shipping";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";

export const formShippingShippingPackagingInspectionSearchShippingDateToSchema = typeShippingDateSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingDateToSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingDateToSchema>;
export const formShippingShippingPackagingInspectionSearchShippingDateFromSchema = typeShippingDateSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingDateFromSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingDateFromSchema>;
export const formShippingShippingPackagingInspectionSearchShippingScheduledDateToSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingScheduledDateToSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingScheduledDateToSchema>;
export const formShippingShippingPackagingInspectionSearchShippingScheduledDateFromSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingScheduledDateFromSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingScheduledDateFromSchema>;
export const formShippingShippingPackagingInspectionSearchShippingCodeSchema = typeShippingCodeSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingCodeSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingCodeSchema>;
export const formShippingShippingPackagingInspectionSearchDeliveryInvoiceNumberSchema = typeShippingPackagingCodeSchema.optional();
export type FormShippingShippingPackagingInspectionSearchDeliveryInvoiceNumberSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchDeliveryInvoiceNumberSchema>;
export const formShippingShippingPackagingInspectionSearchOrderCodeSchema = typeShippingOrderCodeSchema.optional();
export type FormShippingShippingPackagingInspectionSearchOrderCodeSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchOrderCodeSchema>;
export const formShippingShippingPackagingInspectionSearchShippingStatusSchema = typeShippingStatusSchema.optional();
export type FormShippingShippingPackagingInspectionSearchShippingStatusSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchShippingStatusSchema>;
export const formShippingShippingPackagingInspectionSearchSearchItemsSchema = typeSearchItemsSchema;
export type FormShippingShippingPackagingInspectionSearchSearchItemsSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchSearchItemsSchema>;
export const formShippingShippingPackagingInspectionSearchSchema = yup.object({
	shippingDateTo: formShippingShippingPackagingInspectionSearchShippingDateToSchema,
	shippingDateFrom: formShippingShippingPackagingInspectionSearchShippingDateFromSchema,
	shippingScheduledDateTo: formShippingShippingPackagingInspectionSearchShippingScheduledDateToSchema,
	shippingScheduledDateFrom: formShippingShippingPackagingInspectionSearchShippingScheduledDateFromSchema,
	shippingCode: formShippingShippingPackagingInspectionSearchShippingCodeSchema,
	deliveryInvoiceNumber: formShippingShippingPackagingInspectionSearchDeliveryInvoiceNumberSchema,
	orderCode: formShippingShippingPackagingInspectionSearchOrderCodeSchema,
	shippingStatus: formShippingShippingPackagingInspectionSearchShippingStatusSchema,
	searchItems: formShippingShippingPackagingInspectionSearchSearchItemsSchema,
}).required();
export type FormShippingShippingPackagingInspectionSearchSchemaType = InferType<typeof formShippingShippingPackagingInspectionSearchSchema>;
