// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formReceivingReceivingInspectionResultReceiveIssueIdValueSchema = yup.number().optional().integer();
export type FormReceivingReceivingInspectionResultReceiveIssueIdValueSchemaType = InferType<typeof formReceivingReceivingInspectionResultReceiveIssueIdValueSchema>;
export const formReceivingReceivingInspectionResultReceiveIssueIdSchema = yup.array(
	formReceivingReceivingInspectionResultReceiveIssueIdValueSchema
).optional();
export type FormReceivingReceivingInspectionResultReceiveIssueIdSchemaType = InferType<typeof formReceivingReceivingInspectionResultReceiveIssueIdSchema>;
export const formReceivingReceivingInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormReceivingReceivingInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formReceivingReceivingInspectionResultInspectionQuantityValueSchema>;
export const formReceivingReceivingInspectionResultInspectionQuantitySchema = yup.array(
	formReceivingReceivingInspectionResultInspectionQuantityValueSchema
).optional();
export type FormReceivingReceivingInspectionResultInspectionQuantitySchemaType = InferType<typeof formReceivingReceivingInspectionResultInspectionQuantitySchema>;
export const formReceivingReceivingInspectionResultSchema = yup.object({
	receiveIssueId: formReceivingReceivingInspectionResultReceiveIssueIdSchema,
	inspectionQuantity: formReceivingReceivingInspectionResultInspectionQuantitySchema,
}).required();
export type FormReceivingReceivingInspectionResultSchemaType = InferType<typeof formReceivingReceivingInspectionResultSchema>;
