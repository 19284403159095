// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeDetailedConditionItemsValueValueItemsSchema = yup.string().optional().label("schema.common.search.item.field.target");
export type TypeDetailedConditionItemsValueValueItemsSchemaType = InferType<typeof typeDetailedConditionItemsValueValueItemsSchema>;
export const typeDetailedConditionItemsValueValueComparisonSchema = yup.string().optional().label("schema.common.search.item.field.comparison");
export type TypeDetailedConditionItemsValueValueComparisonSchemaType = InferType<typeof typeDetailedConditionItemsValueValueComparisonSchema>;
export const typeDetailedConditionItemsValueValueTextSchema = yup.string().optional().label("schema.common.search.item.field.target.name");
export type TypeDetailedConditionItemsValueValueTextSchemaType = InferType<typeof typeDetailedConditionItemsValueValueTextSchema>;
export const typeDetailedConditionItemsValueValueSchema = yup.object({
	items: typeDetailedConditionItemsValueValueItemsSchema,
	comparison: typeDetailedConditionItemsValueValueComparisonSchema,
	text: typeDetailedConditionItemsValueValueTextSchema,
}).optional();
export type TypeDetailedConditionItemsValueValueSchemaType = InferType<typeof typeDetailedConditionItemsValueValueSchema>;
export const typeDetailedConditionItemsValueSchema = yup.array(
	typeDetailedConditionItemsValueValueSchema
).optional();
export type TypeDetailedConditionItemsValueSchemaType = InferType<typeof typeDetailedConditionItemsValueSchema>;
export const typeDetailedConditionItemsSchema = yup.object({
	value: typeDetailedConditionItemsValueSchema,
}).optional();
export type TypeDetailedConditionItemsSchemaType = InferType<typeof typeDetailedConditionItemsSchema>;
