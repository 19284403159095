// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductJanCodeSchema } from "./../../master";
import { InferType } from "yup";
import { typeShippingPickingQuantitySchema } from "./../../shipping";

export const formShippingShippingPickingEntryJanCodeSchema = typeMasterProductJanCodeSchema.optional();
export type FormShippingShippingPickingEntryJanCodeSchemaType = InferType<typeof formShippingShippingPickingEntryJanCodeSchema>;
export const formShippingShippingPickingEntryPickingQuantitySchema = typeShippingPickingQuantitySchema.optional();
export type FormShippingShippingPickingEntryPickingQuantitySchemaType = InferType<typeof formShippingShippingPickingEntryPickingQuantitySchema>;
export const formShippingShippingPickingEntrySchema = yup.object({
	janCode: formShippingShippingPickingEntryJanCodeSchema,
	pickingQuantity: formShippingShippingPickingEntryPickingQuantitySchema,
}).required();
export type FormShippingShippingPickingEntrySchemaType = InferType<typeof formShippingShippingPickingEntrySchema>;
