// fv-generatorで生成されました
import * as yup from "yup";
import { typeIssuingCodeSchema, typeIssuingNameSchema, typeIssuingScheduledDateSchema, typeIssuingCategoryIdSchema, typeIssuingMovingSourceLocationDetailIdSchema, typeIssuingMovingSourceLocationDetailCodeSchema, typeIssuingMovingSourceLocationDetailNameSchema, typeIssuingDestinationLocationDetailIdSchema, typeIssuingDestinationLocationDetailCodeSchema, typeIssuingDestinationLocationDetailNameSchema, typeIssuingDetailIdSchema, typeIssuingScheduledQuantitySchema } from "./../../issuing";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";

export const formIssuingIssuingScheduleEntryReceiveIssueCodeSchema = typeIssuingCodeSchema;
export type FormIssuingIssuingScheduleEntryReceiveIssueCodeSchemaType = InferType<typeof formIssuingIssuingScheduleEntryReceiveIssueCodeSchema>;
export const formIssuingIssuingScheduleEntryReceiveIssueNameSchema = typeIssuingNameSchema;
export type FormIssuingIssuingScheduleEntryReceiveIssueNameSchemaType = InferType<typeof formIssuingIssuingScheduleEntryReceiveIssueNameSchema>;
export const formIssuingIssuingScheduleEntryIssuingScheduledDateSchema = typeIssuingScheduledDateSchema;
export type FormIssuingIssuingScheduleEntryIssuingScheduledDateSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingScheduledDateSchema>;
export const formIssuingIssuingScheduleEntryReceiveIssueCategoryIdSchema = typeIssuingCategoryIdSchema;
export type FormIssuingIssuingScheduleEntryReceiveIssueCategoryIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryReceiveIssueCategoryIdSchema>;
export const formIssuingIssuingScheduleEntryMovingSourceLocationDetailIdSchema = typeIssuingMovingSourceLocationDetailIdSchema;
export type FormIssuingIssuingScheduleEntryMovingSourceLocationDetailIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryMovingSourceLocationDetailIdSchema>;
export const formIssuingIssuingScheduleEntryMovingSourceLocationDetailCodeSchema = typeIssuingMovingSourceLocationDetailCodeSchema;
export type FormIssuingIssuingScheduleEntryMovingSourceLocationDetailCodeSchemaType = InferType<typeof formIssuingIssuingScheduleEntryMovingSourceLocationDetailCodeSchema>;
export const formIssuingIssuingScheduleEntryMovingSourceLocationDetailNameSchema = typeIssuingMovingSourceLocationDetailNameSchema;
export type FormIssuingIssuingScheduleEntryMovingSourceLocationDetailNameSchemaType = InferType<typeof formIssuingIssuingScheduleEntryMovingSourceLocationDetailNameSchema>;
export const formIssuingIssuingScheduleEntryDestinationLocationDetailIdSchema = typeIssuingDestinationLocationDetailIdSchema.optional();
export type FormIssuingIssuingScheduleEntryDestinationLocationDetailIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryDestinationLocationDetailIdSchema>;
export const formIssuingIssuingScheduleEntryDestinationLocationDetailCodeSchema = typeIssuingDestinationLocationDetailCodeSchema.optional();
export type FormIssuingIssuingScheduleEntryDestinationLocationDetailCodeSchemaType = InferType<typeof formIssuingIssuingScheduleEntryDestinationLocationDetailCodeSchema>;
export const formIssuingIssuingScheduleEntryDestinationLocationDetailNameSchema = typeIssuingDestinationLocationDetailNameSchema.optional();
export type FormIssuingIssuingScheduleEntryDestinationLocationDetailNameSchemaType = InferType<typeof formIssuingIssuingScheduleEntryDestinationLocationDetailNameSchema>;
export const formIssuingIssuingScheduleEntryReceiveIssueNoteSchema = typeCommonNoteSchema;
export type FormIssuingIssuingScheduleEntryReceiveIssueNoteSchemaType = InferType<typeof formIssuingIssuingScheduleEntryReceiveIssueNoteSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailIdSchema = typeIssuingDetailIdSchema.optional();
export type FormIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailIdSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormIssuingIssuingScheduleEntryIssuingDetailValueSkuIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueSkuIdSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueIssuingScheduledQuantitySchema = typeIssuingScheduledQuantitySchema.optional();
export type FormIssuingIssuingScheduleEntryIssuingDetailValueIssuingScheduledQuantitySchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueIssuingScheduledQuantitySchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailNoteSchema = typeCommonNoteSchema;
export type FormIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailNoteSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailNoteSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormIssuingIssuingScheduleEntryIssuingDetailValueProductIdSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueProductIdSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailValueSchema = yup.object({
	receiveIssueDetailId: formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailIdSchema,
	skuId: formIssuingIssuingScheduleEntryIssuingDetailValueSkuIdSchema,
	issuingScheduledQuantity: formIssuingIssuingScheduleEntryIssuingDetailValueIssuingScheduledQuantitySchema,
	receiveIssueDetailNote: formIssuingIssuingScheduleEntryIssuingDetailValueReceiveIssueDetailNoteSchema,
	productId: formIssuingIssuingScheduleEntryIssuingDetailValueProductIdSchema,
}).optional();
export type FormIssuingIssuingScheduleEntryIssuingDetailValueSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailValueSchema>;
export const formIssuingIssuingScheduleEntryIssuingDetailSchema = yup.array(
	formIssuingIssuingScheduleEntryIssuingDetailValueSchema
).required();
export type FormIssuingIssuingScheduleEntryIssuingDetailSchemaType = InferType<typeof formIssuingIssuingScheduleEntryIssuingDetailSchema>;
export const formIssuingIssuingScheduleEntrySchema = yup.object({
	receiveIssueCode: formIssuingIssuingScheduleEntryReceiveIssueCodeSchema,
	receiveIssueName: formIssuingIssuingScheduleEntryReceiveIssueNameSchema,
	issuingScheduledDate: formIssuingIssuingScheduleEntryIssuingScheduledDateSchema,
	receiveIssueCategoryId: formIssuingIssuingScheduleEntryReceiveIssueCategoryIdSchema,
	movingSourceLocationDetailId: formIssuingIssuingScheduleEntryMovingSourceLocationDetailIdSchema,
	movingSourceLocationDetailCode: formIssuingIssuingScheduleEntryMovingSourceLocationDetailCodeSchema,
	movingSourceLocationDetailName: formIssuingIssuingScheduleEntryMovingSourceLocationDetailNameSchema,
	destinationLocationDetailId: formIssuingIssuingScheduleEntryDestinationLocationDetailIdSchema,
	destinationLocationDetailCode: formIssuingIssuingScheduleEntryDestinationLocationDetailCodeSchema,
	destinationLocationDetailName: formIssuingIssuingScheduleEntryDestinationLocationDetailNameSchema,
	receiveIssueNote: formIssuingIssuingScheduleEntryReceiveIssueNoteSchema,
	issuingDetail: formIssuingIssuingScheduleEntryIssuingDetailSchema,
}).required();
export type FormIssuingIssuingScheduleEntrySchemaType = InferType<typeof formIssuingIssuingScheduleEntrySchema>;
