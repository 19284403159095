// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterSearchConditionCodeSchema, typeMasterSearchConditionNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema } from "./../../common";

export const formMasterSearchConditionListSearchConditionCodeSchema = typeMasterSearchConditionCodeSchema.optional();
export type FormMasterSearchConditionListSearchConditionCodeSchemaType = InferType<typeof formMasterSearchConditionListSearchConditionCodeSchema>;
export const formMasterSearchConditionListSearchConditionNameSchema = typeMasterSearchConditionNameSchema.optional();
export type FormMasterSearchConditionListSearchConditionNameSchemaType = InferType<typeof formMasterSearchConditionListSearchConditionNameSchema>;
export const formMasterSearchConditionListSearchTargetIdSchema = yup.number().optional().label("schema.master.searchCondition.label.target").integer();
export type FormMasterSearchConditionListSearchTargetIdSchemaType = InferType<typeof formMasterSearchConditionListSearchTargetIdSchema>;
export const formMasterSearchConditionListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterSearchConditionListIsAvailableSchemaType = InferType<typeof formMasterSearchConditionListIsAvailableSchema>;
export const formMasterSearchConditionListSchema = yup.object({
	searchConditionCode: formMasterSearchConditionListSearchConditionCodeSchema,
	searchConditionName: formMasterSearchConditionListSearchConditionNameSchema,
	searchTargetId: formMasterSearchConditionListSearchTargetIdSchema,
	isAvailable: formMasterSearchConditionListIsAvailableSchema,
}).required();
export type FormMasterSearchConditionListSchemaType = InferType<typeof formMasterSearchConditionListSchema>;
