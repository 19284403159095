import { useMediaQuery } from '@mui/material';
import { breakpointMobileWidth } from 'component/common/theme/theme';
import React from 'react';
import { createContext, lazy, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { useQuery } from 'react-query';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { GetResponseType } from 'type/share/api/root';
import { fetchTypedJSON } from 'util/fetchJSON';

// ログイン後のページを書きたい場合はこちら
const Dashboard = lazy(() => import('content/dashboard'));
const TopMenu = lazy(() => import('content/index'));

// #region --- ページのインポート

// #region --- 入荷
// 入荷
const ArrivalMenu = lazy(() => import('content/arrival/index'));
const ArrivalList = lazy(() => import('content/arrival/list/index'));
// 入荷予定
const ArrivalEntrySchedule = lazy(() => import('content/arrival/entry-schedule/index'));
// 入荷実績
const ArrivalEntryResult = lazy(() => import('content/arrival/entry-result/index'));
// 入庫前検品
const ArrivalBeforeInspectionEntry = lazy(() => import('content/arrival/inspection-entry/index'));
const ArrivalBeforeInspectionSearch = lazy(() => import('content/arrival/inspection-search/index'));
// #endregion --- 入荷

// #region --- 出荷
// 出荷
const ShippingMenu = lazy(() => import('content/shipping/index'));
const ShippingList = lazy(() => import('content/shipping/list/index'));
// 出荷予定
const ShippingEntrySchedule = lazy(() => import('content/shipping/entry-schedule/index'));
// 出荷実績
const ShippingEntryResult = lazy(() => import('content/shipping/entry-result/index'));
// 未引当一覧
const UnAllocatedList = lazy(() => import('content/shipping/allocation/list/index'));
// ピッキング
const PickingSearch = lazy(() => import('content/shipping/picking-search/index'));
const PickingEntry = lazy(() => import('content/shipping/picking-entry/index'));
const Picking = lazy(() => import('content/shipping/picking/index'));
// 出荷前検品
const ShippingBeforeInspectionSearch = lazy(() => import('content/shipping/inspection-search/index'));
const ShippingBeforeInspectionEntry = lazy(() => import('content/shipping/inspection-entry/index'));
// 梱包検品
const PackagingBeforeInspectionSearch = lazy(() => import('content/shipping/packaging-inspection-search/index'));
const PackagingAfterInspectionEnty = lazy(() => import('content/shipping/packaging-inspection/index'));
// 在庫引当
const StockAllocation = lazy(() => import('content/shipping/allocation/entry/index'));
// シングルピッキング
const SinglePickingList = lazy(() => import('content/shipping/single-picking/list/index'));
// トータルピッキング
const TotalPickingList = lazy(() => import('content/shipping/total-picking/list/index'));
// 佐川-出荷荷札
const ShippingSagawaLabel = lazy(() => import('content/shipping/sagawa/shipping-label/index'));
// #endregion --- 出荷

// #region --- 入庫
const ReceivingMenu = lazy(() => import('content/receiving/index'));
const ReceivingList = lazy(() => import('content/receiving/list/index'));
// 入庫予定
const ReceivingEntrySchedule = lazy(() => import('content/receiving/entry-schedule/index'));
// 入庫実績
const ReceivingEntryResult = lazy(() => import('content/receiving/entry-result/index'));
// 入庫前検品
const ReceivingBeforeInspectionEntry = lazy(() => import('content/receiving/inspection/index'));

// 入出庫グループ
const ReceivingAndIssuingGroupList = lazy(() => import('content/receiving/group/list/index'));
const ReceivingAndIssuingGroupEntry = lazy(() => import('content/receiving/group/entry/index'));
// #endregion --- 入庫

// #region --- 出庫
const IssuingMenu = lazy(() => import('content/issuing/index'));
const IssuingList = lazy(() => import('content/issuing/list/index'));
// 出庫予定
const IssuingEntrySchedule = lazy(() => import('content/issuing/entry-schedule/index'));
// 出庫実績
const IssuingEntryResult = lazy(() => import('content/issuing/entry-result/index'));
// 出庫前検品
const IssuingBeforeInspectionEntry = lazy(() => import('content/issuing/inspection/index'));
// 空きロケーション
const FreeLocationList = lazy(() => import('content/issuing/free-location/index'));
// #endregion --- 出庫

// #region --- 在庫
const StockMenu = lazy(() => import('content/stock/index'));
const StockList = lazy(() => import('content/stock/list/index'));
const StockEntry = lazy(() => import('content/stock/entry/index'));
const StockMove = lazy(() => import('content/stock/move/index'));
// セット組み
const SetProductMenu = lazy(() => import('content/stock/set-product/index'));
const SetProductList = lazy(() => import('content/stock/set-product/list/index'));
const SetProductEntryInspection = lazy(() => import('content/stock/set-product/inspection/index'));
const SetProductEntrySchedule = lazy(() => import('content/stock/set-product/entry-schedule/index'));
const SetProductEntryResult = lazy(() => import('content/stock/set-product/entry-result/index'));
// セット崩し
const BreakSetProductMenu = lazy(() => import('content/stock/break-set-product/index'));
const BreakSetProductList = lazy(() => import('content/stock/break-set-product/list/index'));
const BreakSetProductEntrySchedule = lazy(() => import('content/stock/break-set-product/entry-schedule/index'));
const BreakSetProductEntryResult = lazy(() => import('content/stock/break-set-product/entry-result/index'));
const BreakSetProductEntryInspection = lazy(() => import('content/stock/break-set-product/inspection/index'));

// 棚卸
const StockTakingMenu = lazy(() => import('content/stock/stock-taking/index'));
const StockTakingList = lazy(() => import('content/stock/stock-taking/list/index'));
const StockTakingEntrySchedule = lazy(() => import('content/stock/stock-taking/entry-schedule/index'));
const StockTakingEntryResult = lazy(() => import('content/stock/stock-taking/entry-result/index'));
const StockOutList = lazy(() => import('content/stock/stock-out/list/index'));

// 補充
const RestockMenu = lazy(() => import('content/stock/restock/index'));
const RestockList = lazy(() => import('content/stock/restock/list/index'));
// #endregion --- 在庫

// #region --- マスタ
const MasterMenu = lazy(() => import('content/master/index'));

const UserMenu = lazy(() => import('content/master/user/index'));
// システム利用ユーザー
const SystemUserList = lazy(() => import('content/master/user/user/list/index'));
const SystemUserEntry = lazy(() => import('content/master/user/user/entry/index'));
// 権限
const UserAuthorityList = lazy(() => import('content/master/user/authority/list/index'));
const UserAuthorityEntry = lazy(() => import('content/master/user/authority/entry/index'));

// #region --- 配送業者
const DeliveryCompanyMenu = lazy(() => import('content/master/delivery/index'));

// 配送業者拠点
const DeliveryCompanyList = lazy(() => import('content/master/delivery/company/list/index'));
const DeliveryCompanyEntry = lazy(() => import('content/master/delivery/company/entry/index'));
// 配送業者拠点
const DeliveryCompanyBaseList = lazy(() => import('content/master/delivery/company-base/list/index'));
const DeliveryCompanyBaseEntry = lazy(() => import('content/master/delivery/company-base/entry/index'));
// #endregion --- 配送業者

// 検索条件
const SearchConditionList = lazy(() => import('content/master/search-condition/list/index'));
// const SearchConditionEntry = lazy(() => import('content/master/search-condition/entry/index'));
// 入荷区分
const ArrivalCategoryList = lazy(() => import('content/master/arrival/category/list/index'));
const ArrivalCategoryEntry = lazy(() => import('content/master/arrival/category/entry/index'));

// #region --- 機能
const FeaturesMenu = lazy(() => import('content/system-maintenance/feature/index'));
// 機能
const FeaturesList = lazy(() => import('content/system-maintenance/feature/feature/list/index'));
const FeaturesEntry = lazy(() => import('content/system-maintenance/feature/feature/entry/index'));
// 機能区分
const FeaturesCategoryList = lazy(() => import('content/system-maintenance/feature/category/list/index'));
const FeaturesCategoryEntry = lazy(() => import('content/system-maintenance/feature/category/entry/index'));
// #endregion --- 機能

// #region --- 資産
const AssetMenu = lazy(() => import('content/master/asset-manage/index'));
// 資産
const AssetList = lazy(() => import('content/master/asset-manage/asset/list/index'));
const AssetEntry = lazy(() => import('content/master/asset-manage/asset/entry/index'));
// 資産区分
const AssetCategoryList = lazy(() => import('content/master/asset-manage/asset-category/list/index'));
const AssetCategoryEntry = lazy(() => import('content/master/asset-manage/asset-category/entry/index'));
// #endregion --- 資産

// #region --- 仕入先
const ClientMenu = lazy(() => import('content/master/client/index'));

// 仕入先
const ClientList = lazy(() => import('content/master/client/list/index'));
const ClientEntry = lazy(() => import('content/master/client/entry/index'));
// 仕入先区分
const ClientCategoryList = lazy(() => import('content/master/client/category/list/index'));
const ClientCategoryEntry = lazy(() => import('content/master/client/category/entry/index'));
// 仕入先出荷元
const ClientSenderList = lazy(() => import('content/master/client/client-sender/list/index'));
const ClientSenderEntry = lazy(() => import('content/master/client/client-sender/entry/index'));
// 仕入先輸送手段
const TransportMethodList = lazy(() => import('content/master/client/transport-method/list/index'));
const TransportMethodEntry = lazy(() => import('content/master/client/transport-method/entry/index'));
// #endregion --- 仕入先

// #region --- 顧客
const CustomerMenu = lazy(() => import('content/master/customer-manage/index'));
// 顧客
const CustomerList = lazy(() => import('content/master/customer-manage/customer/list/index'));
const CustomerEntry = lazy(() => import('content/master/customer-manage/customer/entry/index'));
// 顧客区分
const CustomerCategoryList = lazy(() => import('content/master/customer-manage/category/list/index'));
const CustomerCategoryEntry = lazy(() => import('content/master/customer-manage/category/entry/index'));
// 顧客配送先
const CustomerDeliveryDestinationList = lazy(() => import('content/master/customer-manage/delivery-destination/list/index'));
const CustomerDeliveryDestinationEntry = lazy(() => import('content/master/customer-manage/delivery-destination/entry/index'));
// #endregion --- 顧客

// #region --- 倉庫
const WarehouseMenu = lazy(() => import('content/master/warehouse/index'));
// 倉庫
const WarehouseList = lazy(() => import('content/master/warehouse/warehouse/list/index'));
const WarehouseEntry = lazy(() => import('content/master/warehouse/warehouse/entry/index'));
// 倉庫区分
const WarehouseCategoryList = lazy(() => import('content/master/warehouse/warehouse-category/list/index'));
const WarehouseCategoryEntry = lazy(() => import('content/master/warehouse/warehouse-category/entry/index'));
// ロケーション
const LocationList = lazy(() => import('content/master/warehouse/location/list/index'));
const LocationEntry = lazy(() => import('content/master/warehouse/location/entry/index'));
// ロケーション区分
const LocationCategoryList = lazy(() => import('content/master/warehouse/location-category/list/index'));
const LocationCategoryEntry = lazy(() => import('content/master/warehouse/location-category/entry/index'));
// ロケーション明細(棚)
const LocationDetailsList = lazy(() => import('content/master/warehouse/location-details/list/index'));
const LocationDetailsEntry = lazy(() => import('content/master/warehouse/location-details/entry/index'));
// 通路
const AisleList = lazy(() => import('content/master/warehouse/aisle/list/index'));
const AisleEntry = lazy(() => import('content/master/warehouse/aisle/entry/index'));
// 保管条件
const StoringConditionList = lazy(() => import('content/master/warehouse/storing-condition/list/index'));
const StoringConditionEntry = lazy(() => import('content/master/warehouse/storing-condition/entry/index'));
// #endregion --- 倉庫

// #region --- 梱包
const PackagingMenu = lazy(() => import('content/master/packaging/index'));

// 梱包区分
const PackagingCategoryList = lazy(() => import('content/master/packaging/category/list/index'));
const PackagingCategoryEntry = lazy(() => import('content/master/packaging/category/entry/index'));
// #endregion --- 梱包

// #region --- 商品
// /** 商品メニュー */
const ProductMenu = lazy(() => import('content/master/product/index'));
// /** 商品マスタ */
const ProductList = lazy(() => import('content/master/product/product/list/index'));
const ProductEntry = lazy(() => import('content/master/product/product/entry/index'));
// /** 品番 */
const PartNumberList = lazy(() => import('content/master/product/part-numbers/list/index'));
const PartNumberEntry = lazy(() => import('content/master/product/part-numbers/entry/index'));
// /** 品番区分 */
const PartNumberCategoryList = lazy(() => import('content/master/product/part-number-category/list/index'));
const PartNumberCategoryEntry = lazy(() => import('content/master/product/part-number-category/entry/index'));
// /** 商品詳細 */
// const ProductDetailList = lazy(() => import('content/master/products/detail/list/index'));
// const ProductDetailEntry = lazy(() => import('content/master/products/detail/entry/index'));
// /** 商品項目1(大項目) */
const ProductLargeCategoryList = lazy(() => import('content/master/product/large-category/list/index'));
const ProductLargeCategoryEntry = lazy(() => import('content/master/product/large-category/entry/index'));
// /** 商品項目2(小項目) */
const ProductSmallCategoryList = lazy(() => import('content/master/product/small-category/list/index'));
const ProductSmallCategoryEntry = lazy(() => import('content/master/product/small-category/entry/index'));
// /** 商品状態 */
const ProductStatusList = lazy(() => import('content/master/product/status/list/index'));
const ProductStatusEntry = lazy(() => import('content/master/product/status/entry/index'));
// /** SKUグループ */
const SKUGroupList = lazy(() => import('content/master/product/sku-group/list/index'));
const SKUGroupEntry = lazy(() => import('content/master/product/sku-group/entry/index'));
// #endregion --- 商品

// #endregion --- マスタ

// #region --- 帳票出力
const ShippingDetail = lazy(() => import('content/print/shipping/detail-list/index'));
const PrintArrivalList = lazy(() => import('content/print/arrival/list/index'));
const ReceiveIssueDetail = lazy(() => import('content/print/receive-issue/detail-list/index'));
const ReceiveIssueLabel = lazy(() => import('content/print/receive-issue/label/index'));
const StockLabel = lazy(() => import('content/print/stock/label/index'));
const StockMovementDetail = lazy(() => import('content/print/stock/movement-detail-list/index'));
const SinglePicking = lazy(() => import('content/print/shipping/single-picking/index'));
const TotalPicking = lazy(() => import('content/print/shipping/total-picking/index'));
const UnAllocated = lazy(() => import('content/print/shipping/unAllocated/index'));
const SagawaShippingLabel = lazy(() => import('content/print/sagawa/shipping/index'));
const SagawaShippingSummaryLabel = lazy(() => import('content/print/sagawa/shipping-summary/index'));
// #endregion --- 帳票出力

// #region --- システム設定
const OperationLogList = lazy(() => import('content/system-maintenance/operation-log/index'));
const SystemsettingList = lazy(() => import('content/master/system-setting/index'));
// #endregion --- システム設定

// #region --- システム保守
const SystemMaintenanceMenu = lazy(() => import('content/system-maintenance/index'));

// #endregion --- システム保守
// #endregion --- ページのインポート

// #region --- 会社管理
// 会社(システム利用)
// const CompanyList = lazy(() => import('content/system-maintenance/company/system-company/list/index'));
// const CompanyEntry = lazy(() => import('content/system-maintenance/company/system-company/entry/index'));

const ShipperMenu = lazy(() => import('content/master/shipper/index'));
/** 荷主 */
const ShipperList = lazy(() => import('content/master/shipper/shipper/list/index'));
const ShipperEntry = lazy(() => import('content/master/shipper/shipper/entry/index'));
/** 荷主契約 */
const ShipperContractList = lazy(() => import('content/master/shipper/contract/list/index'));
const ShipperContractEntry = lazy(() => import('content/master/shipper/contract/entry/index'));
// 会社情報(自社)

// #region --- 会社管理
const CompanyMenu = lazy(() => import('content/system-maintenance/company/index'));
// 会社(システム利用)
const CompanyList = lazy(() => import('content/system-maintenance/company/list/index'));
const CompanyEntry = lazy(() => import('content/system-maintenance/company/entry/index'));
// 会社情報(自社) Memo: 不要
// const OwnCompanyEntry = lazy(() => import('content/system-maintenance/own-company/entry/index'));
// #endregion --- 会社管理

// #region --- 地域
const RegionMenu = lazy(() => import('content/system-maintenance/region/index'));
// 国
const CountryCodeList = lazy(() => import('content/system-maintenance/region/country-code/list/index'));
// 都道府県
const PrefecturesCodeList = lazy(() => import('content/system-maintenance/region/prefecture-code/list/index'));
// #endregion

// #endregion --- システム保守

// #region --- 各ページのリンク定義

// #region --- アカウント

// ログイン
const Login = lazy(() => import('content/account/login/index'));

// #endregion

// #region --- CSV
const OrderCsvUploader = lazy(() => import('content/csv/order/index'));
const ArrivalCsvUploader = lazy(() => import('content/csv/arrival/index'));
const PackagingCsvUploader = lazy(() => import('content/csv/packaging/index'));
// #endregion --- CSV

const pageLinks = {
	arrival: '/arrival',
	shipping: '/shipping',
	receiving: '/receiving',
	issuing: '/issuing',
	stock: '/stock',
	master: '/master',
	print: '/print',
	systemMaintenance: '/system-maintenance',
	account: '/account',
	csv: '/csv',
};
const baseLinks = {
	menu: '/menu',
	list: '/list',
	entry: '/entry',
	entrySchedule: '/entry-schedule',
	entryResult: '/entry-result',
	entryInspection: '/entry-inspection',
};
type ContentNames = 'shipping' | 'arrival' | 'test' | 'receiving' | 'issuing' | 'stock' | 'master' | 'print' | 'systemMaintenance' | 'account' | 'csv';
interface ContentsBase {
	menu?: string;
	list?: string;
}

// 入庫
interface Receiving extends ContentsBase {
	entrySchedule?: string;
	entryResult?: string;
	inspection?: string;
	group?: string;
	groupEntry?: string;
}

// 出庫
interface Issuing extends ContentsBase {
	entrySchedule?: string;
	entryResult?: string;
	inspection?: string;
	freeLocationList?: string;
}
// 入荷
interface Arrival extends ContentsBase {
	entrySchedule?: string;
	entryResult?: string;
	inspection?: string;
	inspectionSearch?: string;
	inspectionEntry?: string;
	csvUploads?: string;
}

// 出荷
interface Shipping extends ContentsBase {
	entrySchedule?: string;
	entryResult?: string;
	unAllocationList?: string;
	stockAllocation?: string;
	singlePickingList?: string;
	totalPickingList?: string;
	inspection?: string;
	inspectionSearch?: string;
	inspectionEntry?: string;
	packagingInspectionSearch?: string;
	packagingInspection?: string;
	pickingSearch?: string;
	pickingEntry?: string;
	picking?: string;
	csvUploads?: string;
	shippingSagawaLabel?: string;
	packagingCsvUploads?: string;
}
// 在庫
interface Stock extends ContentsBase {
	entry?: string;
	move?: string;
	setProductMenu?: string;
	setProductList?: string;
	setProductEntryInspection?: string;
	setProductEntrySchedule?: string;
	setProductEntryResult?: string;
	breakSetProductMenu?: string;
	breakSetProductList?: string;
	breakSetProductEntrySchedule?: string;
	breakSetProductEntryResult?: string;
	breakSetProductEntryInspection?: string;
	stockTakingMenu?: string;
	stockTakingList?: string;
	entryStockTakingSchedule?: string;
	entryStockTakingResult?: string;
	stockoutList?: string;
	stockAllocation?: string;
	restockMenu?: string;
	restockList?: string;
}
// マスタ
interface Master extends ContentsBase {
	clientSenderList?: string;
	clientSenderEntry?: string;
	customerDeliveryDestinationList?: string;
	customerDeliveryDestinationEntry?: string;
	searchConditionList?: string;
	searchConditionEntry?: string;
	assetMenu?: string;
	assetList?: string;
	assetEntry?: string;
	assetCategoryList?: string;
	assetCategoryEntry?: string;
	userMenu?: string;
	systemUserList?: string;
	systemUserEntry?: string;
	userAuthorityList?: string;
	userAuthorityEntry?: string;
	deliveryCompanyMenu?: string;
	deliveryCompanyList?: string;
	deliveryCompanyEntry?: string;
	deliveryCompanyBaseList?: string;
	deliveryCompanyBaseEntry?: string;

	/** 入荷区分 */
	arrivalCategoryList?: string;
	arrivalCategoryEntry?: string;

	// #region --- 顧客
	customerMenu?: string;
	/** 顧客 */
	customerList?: string;
	customerEntry?: string;
	/** 顧客区分 */
	customerCategoryList?: string;
	customerCategoryEntry?: string;
	// #endregion --- 顧客

	// #region --- 仕入先
	clientMenu?: string;
	/** 仕入先 */
	clientList?: string;
	clientEntry?: string;
	/** 仕入先区分 */
	clientCategoryList?: string;
	clientCategoryEntry?: string;
	/** 仕入先輸送区分 */
	transportMethodList?: string;
	transportMethodEntry?: string;
	// #endregion --- 仕入先

	// #region --- 倉庫関連
	warehouseMenu?: string;
	/** 倉庫 */
	warehouseList?: string;
	warehouseEntry?: string;
	/** 倉庫区分 */
	warehouseCategoryList?: string;
	warehouseCategoryEntry?: string;
	/** ロケーション */
	locationList?: string;
	locationEntry?: string;
	/** ロケーション区分 */
	locationCategoryList?: string;
	locationCategoryEntry?: string;
	/** ロケーション明細 */
	locationDetailsList?: string;
	locationDetailsEntry?: string;
	/** 保管条件 */
	storingConditionList?: string;
	storingConditionEntry?: string;
	/** 通路 */
	aisleList?: string;
	aisleEntry?: string;
	// #endregion --- 倉庫関連

	// #region --- 商品関連
	productMenu?: string;
	/** 商品 */
	productList?: string;
	productEntry?: string;
	/** 品番 */
	partNumberList?: string;
	partNumberEntry?: string;
	/** 品番区分 */
	partNumberCategoryList?: string;
	partNumberCategoryEntry?: string;
	/** 商品詳細項目 */
	productDetailList?: string;
	productDetailEntry?: string;
	/** 商品項目1(大項目) */
	productLargeCategoryList?: string;
	productLargeCategoryEntry?: string;
	/** 商品項目2(小項目) */
	productSmallCategoryList?: string;
	productSmallCategoryEntry?: string;
	/** 商品状態 */
	productStatusList?: string;
	productStatusEntry?: string;
	/** SKUグループ */
	skuGroupList?: string;
	skuGroupEntry?: string;
	// #endregion --- 商品関連

	// #region --- 梱包
	packagingMenu?: string;
	/** 梱包区分 */
	packagingCategoryList?: string;
	packagingCategoryEntry?: string;
	// #endregion --- 梱包

	// #region --- 荷主関連
	shipperMenu?: string;
	/** 荷主マスタ */
	shipperList?: string;
	shipperEntry?: string;
	/** 荷主契約 */
	shipperContractList?: string;
	shipperContractEntry?: string;
	// #endregion --- 荷主関連

	systemSettingList?: string;
}

// 帳票出力
interface Print extends ContentsBase {
	shippingDetail?: string;
	printArrivalList?: string
	receiveIssueDetail?: string;
	receiveIssueLabel?: string;
	stockLabel?: string;
	stockMovementDetail?: string;
	singlePicking?: string;
	totalPicking?: string;
	unAllocated?: string;
	sagawaShippingLabel?: string;
	sagawaShippingSummaryLabel?: string;
}

// システム保守
interface SystemMaintenance extends ContentsBase {
	/** 地域メニュー */
	regionMenu?: string;
	/** 国コード */
	countryCodeList?: string;
	/** 都道府県コード */
	prefecturesCodeList?: string;
	/** システム利用会社マスタ */
	companyMenu?: string;
	companyList?: string;
	companyEntry?: string;
	/** 自社情報 Memo: 不要 */
	// ownCompanyEntry?: string;

	// #region --- 機能
	featureMenu?: string;
	/** 機能 */
	featureList?: string;
	featureEntry?: string;
	/** 機能区分 */
	featureCategoryList?: string;
	featureCategoryEntry?: string;
	// #endregion --- 機能

	operationLogList?: string;
}

interface Account extends ContentsBase {
	/** ログイン */
	login?: string;
}

interface Csv extends ContentsBase {
	/** CSV関連 */

	arrival?: string;
	order?: string;
	packaging?: string;
}

interface Test extends ContentsBase {
	layout?: string;
}
interface Contents extends ContentsBase, Issuing, Receiving, Arrival, Shipping, Stock, Master, Print, SystemMaintenance, Account, Csv, Test {}

// #endregion

// #region --- 各コンテンツ別リンク取得

// #region --- 入荷
/** 入荷 */
const getArrivalLinks = (): Arrival => {
	const l = pageLinks.arrival;
	return {
		menu: l + baseLinks.menu,
		list: l + baseLinks.list,
		entrySchedule: l + baseLinks.entrySchedule,
		entryResult: l + baseLinks.entryResult,
		inspection: l + '/inspection',
		inspectionSearch: l + '/inspection-search',
		inspectionEntry: l + '/inspection-entry',
		csvUploads: '/csv/arrival',
	};
};
// #endregion

// #region --- 出荷
/** 出荷 */
const getShippingLinks = (): Shipping => {
	const l = pageLinks.shipping;
	return {
		menu: l + baseLinks.menu,
		list: l + baseLinks.list,
		entrySchedule: l + baseLinks.entrySchedule,
		entryResult: l + baseLinks.entryResult,
		unAllocationList: l + '/allocation' + baseLinks.list,
		stockAllocation: l + '/allocation' + baseLinks.entry,
		singlePickingList: l + '/single-picking' + baseLinks.list,
		totalPickingList: l + '/total-picking' + baseLinks.list,
		inspection: l + '/inspection',
		inspectionSearch: l + '/inspection-search',
		inspectionEntry: l + '/inspection-entry',
		packagingInspection: l + '/packaging-inspection',
		packagingInspectionSearch: l + '/packaging-inspection-search',
		pickingSearch: l + '/picking-search',
		pickingEntry: l + '/picking-entry',
		picking: l + '/picking',
		csvUploads: '/csv/order',
		shippingSagawaLabel: l + '/sagawa/label',
		packagingCsvUploads: '/csv/packaging',
	};
};
// #endregion

// #region --- 入庫
/** 入庫 */
const getReceivingLinks = (): Receiving => {
	const l = pageLinks.receiving;
	return {
		menu: l + baseLinks.menu,
		list: l + baseLinks.list,
		entrySchedule: l + baseLinks.entrySchedule,
		entryResult: l + baseLinks.entryResult,
		inspection: l + '/inspection',
		group: l + '/group' + baseLinks.list,
		groupEntry: l + '/group' + baseLinks.entry,
	};
};
// #endregion

// #region --- 出庫
/** 出庫 */
const getIssuingLinks = (): Issuing => {
	const l = pageLinks.issuing;
	return {
		menu: l + baseLinks.menu,
		list: l + baseLinks.list,
		entrySchedule: l + baseLinks.entrySchedule,
		entryResult: l + baseLinks.entryResult,
		inspection: l + '/inspection',
		freeLocationList: l + '/free-location' + baseLinks.list,
	};
};
// #endregion

// #region --- 在庫
/** 在庫 */
const getStockLinks = (): Stock => {
	const l = pageLinks.stock;
	return {
		menu: l + baseLinks.menu,
		list: l + baseLinks.list,
		entry: l + baseLinks.entry,
		move: l + '/move',

		// セット商品
		setProductMenu: l + '/set-product' + baseLinks.menu,
		setProductList: l + '/set-product' + baseLinks.list,
		setProductEntryInspection: l + '/set-product' + baseLinks.entryInspection,
		setProductEntrySchedule: l + '/set-product' + baseLinks.entrySchedule,
		setProductEntryResult: l + '/set-product' + baseLinks.entryResult,

		// セット崩し
		breakSetProductMenu: l + '/break-set-product' + baseLinks.menu,
		breakSetProductList: l + '/break-set-product' + baseLinks.list,
		breakSetProductEntrySchedule: l + '/break-set-product' + baseLinks.entrySchedule,
		breakSetProductEntryResult: l + '/break-set-product' + baseLinks.entryResult,
		breakSetProductEntryInspection: l + '/break-set-product' + baseLinks.entryInspection,
		// 棚卸
		stockTakingMenu: l + '/stock-taking' + baseLinks.menu,
		stockTakingList: l + '/stock-taking' + baseLinks.list,
		entryStockTakingSchedule: l + '/stock-taking' + baseLinks.entrySchedule,
		entryStockTakingResult: l + '/stock-taking' + baseLinks.entryResult,

		stockoutList: l + '/stockout-list',

		stockAllocation: '/shipping/allocation' + baseLinks.entry,

		// 補充
		restockMenu: l + '/restock' + baseLinks.menu,
		restockList: l + '/restock' + baseLinks.list,
	};
};
// #endregion

// #region --- マスタ
/** マスタ */
const getMasterLinks = (): Master => {
	const l = pageLinks.master;
	const products = '/product';
	const warehouse = '/warehouse';
	const packing = '/packing';
	const client = '/client';
	const shipper = '/shipper';
	const customer = '/cusotmer';
	const asset = '/asset;';
	const delivery = '/delivery-company';

	return {
		menu: l + baseLinks.menu,
		clientSenderList: l + client + '/client-sender' + baseLinks.list,
		clientSenderEntry: l + client + '/client-sender' + baseLinks.entry,
		customerMenu: l + customer + baseLinks.menu,
		customerList: l + '/customer' + baseLinks.list,
		customerEntry: l + '/customer' + baseLinks.entry,
		customerCategoryList: l + '/customer/category' + baseLinks.list,
		customerCategoryEntry: l + '/customer/category' + baseLinks.entry,
		customerDeliveryDestinationList: l + '/delivery-destination' + baseLinks.list,
		customerDeliveryDestinationEntry: l + '/delivery-destination' + baseLinks.entry,
		searchConditionList: l + '/search-condition' + baseLinks.list,
		searchConditionEntry: l + '/search-condition' + baseLinks.entry,
		assetMenu: l + asset + baseLinks.menu,
		assetList: l + '/asset' + baseLinks.list,
		assetEntry: l + '/asset' + baseLinks.entry,
		assetCategoryList: l + '/asset-category' + baseLinks.list,
		assetCategoryEntry: l + '/asset-category' + baseLinks.entry,
		deliveryCompanyMenu: l + delivery + baseLinks.menu,
		// 配送業者
		deliveryCompanyList: l + '/delivery-company' + baseLinks.list,
		deliveryCompanyEntry: l + '/delivery-company' + baseLinks.entry,
		// 配送業者拠点
		deliveryCompanyBaseList: l + '/delivery-company-base' + baseLinks.list,
		deliveryCompanyBaseEntry: l + '/delivery-company-base' + baseLinks.entry,
		// 入荷区分
		arrivalCategoryList: l + '/arrival/category' + baseLinks.list,
		arrivalCategoryEntry: l + '/arrival/category' + baseLinks.entry,

		// #region --- 仕入先
		clientMenu: l + client + baseLinks.menu,
		clientList: l + client + baseLinks.list,
		clientEntry: l + client + baseLinks.entry,
		clientCategoryList: l + client + '/category' + baseLinks.list,
		clientCategoryEntry: l + client + '/category' + baseLinks.entry,
		// 仕入先輸送手段
		transportMethodList: l + client + '/transport-method' + baseLinks.list,
		transportMethodEntry: l + client + '/transport-method' + baseLinks.entry,
		// #endregion --- 仕入先

		// #region --- 倉庫
		warehouseMenu: l + warehouse + baseLinks.menu,
		// 倉庫
		warehouseList: l + warehouse + baseLinks.list,
		warehouseEntry: l + warehouse + baseLinks.entry,
		// 倉庫区分
		warehouseCategoryList: l + warehouse + '/warehouse-category' + baseLinks.list,
		warehouseCategoryEntry: l + warehouse + '/warehouse-category' + baseLinks.entry,
		// ロケーション
		locationList: l + warehouse + '/location' + baseLinks.list,
		locationEntry: l + warehouse + '/location' + baseLinks.entry,
		// ロケーション区分
		locationCategoryList: l + warehouse + '/location-category' + baseLinks.list,
		locationCategoryEntry: l + warehouse + '/location-category' + baseLinks.entry,
		// ロケーション明細
		locationDetailsList: l + warehouse + '/location-details' + baseLinks.list,
		locationDetailsEntry: l + warehouse + '/location-details' + baseLinks.entry,
		// 保管条件
		storingConditionList: l + warehouse + '/storing-condition' + baseLinks.list,
		storingConditionEntry: l + warehouse + '/storing-condition' + baseLinks.entry,
		// 通路
		aisleList: l + warehouse + '/aisle' + baseLinks.list,
		aisleEntry: l + warehouse + '/aisle' + baseLinks.entry,
		// #endregion --- 倉庫

		// #region --- 商品
		productMenu: l + products + baseLinks.menu,
		// 商品
		productList: l + products + baseLinks.list,
		productEntry: l + products + baseLinks.entry,
		// 品番
		partNumberList: l + products + '/part-numbers' + baseLinks.list,
		partNumberEntry: l + products + '/part-numbers' + baseLinks.entry,
		// 品番区分
		partNumberCategoryList: l + products + '/part-number-categories' + baseLinks.list,
		partNumberCategoryEntry: l + products + '/part-number-categories' + baseLinks.entry,
		// 商品詳細
		productDetailList: l + products + '/details' + baseLinks.list,
		productDetailEntry: l + products + '/details' + baseLinks.entry,
		// 商品項目1(大項目)
		productLargeCategoryList: l + products + '/large-categories' + baseLinks.list,
		productLargeCategoryEntry: l + products + '/large-categories' + baseLinks.entry,
		// 商品項目2(小項目)
		productSmallCategoryList: l + products + '/small-categories' + baseLinks.list,
		productSmallCategoryEntry: l + products + '/small-categories' + baseLinks.entry,
		// 商品状態
		productStatusList: l + products + '/statuses' + baseLinks.list,
		productStatusEntry: l + products + '/statuses' + baseLinks.entry,
		// SKUグループ
		skuGroupList: l + products + '/sku-group' + baseLinks.list,
		skuGroupEntry: l + products + '/sku-group' + baseLinks.entry,
		// #endregion --- 商品

		// #region --- 梱包
		packagingMenu: l + packing + baseLinks.menu,
		/** 梱包区分 */
		packagingCategoryList: l + packing + '/category' + baseLinks.list,
		packagingCategoryEntry: l + packing + '/category' + baseLinks.entry,
		// #endregion --- 梱包

		// #region --- 荷主
		shipperMenu: l + shipper + baseLinks.menu,
		// 荷主
		shipperList: l + shipper + baseLinks.list,
		shipperEntry: l + shipper + baseLinks.entry,
		// 荷主契約
		shipperContractList: l + shipper + '/shipper-contract' + baseLinks.list,
		shipperContractEntry: l + shipper + '/shipper-contract' + baseLinks.entry,
		// #endregion --- 荷主

		userMenu: l + '/user' + baseLinks.menu,
		// ユーザー
		systemUserList: l + '/system-user' + baseLinks.list,
		systemUserEntry: l + '/system-user' + baseLinks.entry,
		// ユーザー権限
		userAuthorityList: l + '/user-authority' + baseLinks.list,
		userAuthorityEntry: l + '/user-authority' + baseLinks.entry,
		// システム設定
		systemSettingList: l + '/system-setting',
	};
};
// #endregion

// #region --- 帳票出力
const getPrintLinks = (): Print => {
	const l = pageLinks.print;
	return {
		shippingDetail: l + '/shipping/detail-list',
		printArrivalList: l + '/arrival/list',
		receiveIssueDetail: l + '/receive-issue/detail-list',
		receiveIssueLabel: l + '/receive-issue/label',
		stockLabel: l + '/stock/label',
		stockMovementDetail: l + '/stock/movement-detail-list',
		singlePicking: l + '/shipping/single-picking-list',
		totalPicking: l + '/shipping/total-picking-list',
		unAllocated: l + '/shipping/unAllocated/list',
		sagawaShippingLabel: l + '/shipping/sagawa/label',
		sagawaShippingSummaryLabel: l + '/shipping/sagawa-summary/label',
	};
};
// #endregion --- 帳票出力

// #region --- システム保守
/** システム保守 */
const getSystemMaintenanceLinks = (): SystemMaintenance => {
	const l = pageLinks.systemMaintenance;
	const company = '/company';
	const feature = '/feature';
	return {
		menu: l + baseLinks.menu,

		// 会社(システム利用会社)
		companyMenu: l + company + baseLinks.menu,
		companyList: l + '/company' + baseLinks.list,
		companyEntry: l + '/company' + baseLinks.entry,
		// 自社 Memo: 不要
		// ownCompanyEntry: l + '/own-company' + baseLinks.entry,
		// 地域メニュー
		regionMenu: l + '/region' + baseLinks.menu,
		// 国コード
		countryCodeList: l + '/country-code' + baseLinks.list,
		// 都道府県
		prefecturesCodeList: l + '/prefecture-code' + baseLinks.list,
		// 機能
		featureMenu: l + feature + baseLinks.menu,
		featureList: l + '/feature' + baseLinks.list,
		featureEntry: l + '/feature' + baseLinks.entry,
		// 機能
		featureCategoryList: l + '/feature/category' + baseLinks.list,
		featureCategoryEntry: l + '/feature/category' + baseLinks.entry,

		// 画面操作ログ
		operationLogList: l + '/operation-log',
	};
};
// #endregion

// #region --- アカウント
const getAccountLinks = (): Account => {
	const l = pageLinks.account;
	return {
		login: l + '/login',
	};
};
// #endregion --- アカウント

// #region --- CSV
const getCsvLinks = (): Csv => {
	const l = pageLinks.csv;
	return {
		arrival: l + '/arrival',
		order: l + '/order',
		packaging: l + '/packaging',
	};
};

// #endregion --- 各コンテンツ別リンク取得

const LINKS = ((): { [content in ContentNames]: Contents } => ({
	shipping: getShippingLinks(),
	arrival: getArrivalLinks(),
	receiving: getReceivingLinks(),
	issuing: getIssuingLinks(),
	stock: getStockLinks(),
	master: getMasterLinks(),
	print: getPrintLinks(),
	systemMaintenance: getSystemMaintenanceLinks(),
	account: getAccountLinks(),
	csv: getCsvLinks(),
	test: { menu: '/test/menu', layout: '/test/layout' },
}))();

/**
 * リンク用の文字列を返却します。
 * @returns {Contents}
 */
export const getLinks = () => LINKS;

const role = {
	admin: 1,
	editor: 2,
	general: 3,
	unAuthorized: 99,
};
type Role = typeof role[keyof typeof role];

/**
 * ログインしているかチェックします。
 * ログイン出来ていない場合はログインページにリダイレクトし、
 * ログイン出来ていない場合はAuthPageRouterでルーティングします。
 *
 * @returns
 */
function AuthChecker() {
	const { data } = useQuery('login-user-data', () => {
		return fetchTypedJSON('/v1/account/fetch-account', {}).catch((error) => {
			console.error(error);
			return null;
		});
	});

	// ログインしていない時の処理
	if (data === null) return <Navigate to="/account/login" />;

	return <AuthPageRouter authorityId={data.authorityId} />;
}

type AuthPageRouterProps = {
	authorityId: number;
};
const AuthPageRouter = React.memo(function AuthPageRouter({ authorityId }: AuthPageRouterProps) {
	// #region --- 仮 ログイン権限

	const AuthorizeRoute = (role: Role) => {
		// TODO: 表示権限の設定未定
		if (role >= authorityId) return <Outlet />;
		return <Navigate to="/" />;
	};

	// #endregion --- 仮 ログイン権限

	// #region --- 携帯端末制御
	const isDesktopStyle: boolean = useMediaQuery(`(min-width:${breakpointMobileWidth}px)`);

	const MobileRoute = (isDesktopStyle: boolean) => {
		if (isDesktopStyle) return <Outlet />;
		return <Navigate to="/" />;
	};
	// #endregion ---  携帯端末制御

	return (
		<Routes>
			<Route
				path="/"
				element={isDesktopStyle ? <Dashboard /> : <TopMenu />}
			/>
			{
				// #region --- 入荷メニュー
			}
			{/* >>> 入荷メニュー項目 >>> */}
			{/* 入荷メニュー */}
			<Route
				path={getLinks().arrival.menu}
				element={<ArrivalMenu />}
			/>
			{/* 入荷一覧 */}
			<Route
				path={getLinks().arrival.list}
				element={<ArrivalList />}
			/>
			{/* 入荷予定入力 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().arrival.entrySchedule}
					element={<ArrivalEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<ArrivalEntrySchedule />}
					/>
				</Route>
			</Route>
			{/* 入荷実績入力 */}
			<Route
				path={getLinks().arrival.entryResult}
				element={<ArrivalEntryResult />}
			>
				<Route
					path={':id'}
					element={<ArrivalEntryResult />}
				/>
			</Route>
			{/* 入荷検品入力 */}
			<Route
				path={getLinks().arrival.inspectionSearch}
				element={<ArrivalBeforeInspectionSearch />}
			/>
			<Route
				path={getLinks().arrival.inspectionEntry}
				element={<ArrivalBeforeInspectionEntry />}
			>
				<Route
					path={':id'}
					element={<ArrivalBeforeInspectionEntry />}
				/>
			</Route>
			{/* <<< 入荷メニュー項目 <<< */}
			{
				// #endregion --- 入荷メニュー
			}
			{
				// #region --- 出荷メニュー
			}
			{/* >>> 出荷メニュー項目 >>> */}
			{/* 出荷メニュー */}
			<Route
				path={getLinks().shipping.menu}
				element={<ShippingMenu />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				{/* 出荷一覧 */}
				<Route
					path={getLinks().shipping.list}
					element={<ShippingList />}
				/>
				{/* 出荷予定入力 */}
				<Route
					path={getLinks().shipping.entrySchedule}
					element={<ShippingEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<ShippingEntrySchedule />}
					/>
				</Route>
			</Route>

			{/* MEMO: 権限によって制御する場合(仮) */}
			{/* <Route
				path="/"
				element={AuthorizeRoute(role.admin)}
			>
				<Route
					path={getLinks().shipping.entrySchedule}
					element={<ShippingEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<ShippingEntrySchedule />}
					/>
				</Route>
			</Route> */}
			{/* 出荷実績入力 */}
			<Route
				path={getLinks().shipping.entryResult}
				element={<ShippingEntryResult />}
			>
				<Route
					path={':id'}
					element={<ShippingEntryResult />}
				/>
			</Route>
			{/* 出荷検品入力 */}
			<Route
				path={getLinks().shipping.inspectionSearch}
				element={<ShippingBeforeInspectionSearch />}
			/>
			<Route
				path={getLinks().shipping.inspectionEntry}
				element={<ShippingBeforeInspectionEntry />}
			>
				<Route
					path={':id'}
					element={<ShippingBeforeInspectionEntry />}
				/>
			</Route>
			<Route
				path={getLinks().shipping.packagingInspectionSearch}
				element={<PackagingBeforeInspectionSearch />}
			/>
			{/* 梱包後検品 */}
			<Route
				path={getLinks().shipping.packagingInspection}
				element={<PackagingAfterInspectionEnty />}
			/>
			{/* ピッキング入力 */}
			<Route
				path={getLinks().shipping.pickingSearch}
				element={<PickingSearch />}
			/>
			<Route
				path={getLinks().shipping.pickingEntry}
				element={<PickingEntry />}
			>
				<Route
					path={':id'}
					element={<PickingEntry />}
				/>
			</Route>
			{/* ピッキングバッチ */}
			<Route
				path={getLinks().shipping.pickingSearch}
				element={<PickingSearch />}
			/>
			<Route
				path={getLinks().shipping.picking}
				element={<Picking />}
			>
				<Route
					path={':id'}
					element={<Picking />}
				/>
			</Route>
			{/* 未引当リスト */}
			<Route
				path={getLinks().shipping.unAllocationList}
				element={<UnAllocatedList />}
			/>
			{/* 在庫引当 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().shipping.stockAllocation}
					element={<StockAllocation />}
				/>
			</Route>
			{/* シングルピッキングリスト */}
			<Route
				path={getLinks().shipping.singlePickingList}
				element={<SinglePickingList />}
			/>
			{/* トータルピッキングリスト */}
			<Route
				path={getLinks().shipping.totalPickingList}
				element={<TotalPickingList />}
			/>
			{/* 佐川-出荷荷札 */}
			<Route
				path={getLinks().shipping.shippingSagawaLabel}
				element={<ShippingSagawaLabel />}
			/>
			{/* <<< 出荷メニュー項目 <<< */}
			{
				// #endregion --- 出荷メニュー
			}
			{
				// #region --- 入庫メニュー
			}
			{/* >>> 入庫メニュー項目 >>> */}
			{/* 入庫メニュー */}
			<Route
				path={getLinks().receiving.menu}
				element={<ReceivingMenu />}
			/>
			{/* 入庫一覧 */}
			<Route
				path={getLinks().receiving.list}
				element={<ReceivingList />}
			/>
			{/* 入庫予定入力 */}
			<Route
				path={getLinks().receiving.entrySchedule}
				element={<ReceivingEntrySchedule />}
			>
				<Route
					path={':id'}
					element={<ReceivingEntrySchedule />}
				/>
			</Route>
			{/* 入庫実績入力 */}
			<Route
				path={getLinks().receiving.entryResult}
				element={<ReceivingEntryResult />}
			>
				<Route
					path={':id'}
					element={<ReceivingEntryResult />}
				/>
			</Route>
			{/* 入庫前検品入力 */}
			<Route
				path={getLinks().receiving.inspection}
				element={<ReceivingBeforeInspectionEntry />}
			>
				<Route
					path={':id'}
					element={<ReceivingBeforeInspectionEntry />}
				/>
			</Route>
			{/* 入出庫グループ */}
			<Route
				path={getLinks().receiving.group}
				element={<ReceivingAndIssuingGroupList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().receiving.groupEntry}
					element={<ReceivingAndIssuingGroupEntry />}
				>
					<Route
						path={':id'}
						element={<ReceivingAndIssuingGroupEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 入庫メニュー
			}
			{
				// #region --- 出庫メニュー
			}
			{/* >>> 出庫メニュー項目 >>> */}
			{/* 出庫メニュー */}
			<Route
				path={getLinks().issuing.menu}
				element={<IssuingMenu />}
			/>
			{/* 出庫一覧 */}
			<Route
				path={getLinks().issuing.list}
				element={<IssuingList />}
			/>
			{/* 出庫予定入力 */}
			<Route
				path={getLinks().issuing.entrySchedule}
				element={<IssuingEntrySchedule />}
			>
				<Route
					path={':id'}
					element={<IssuingEntrySchedule />}
				/>
			</Route>
			{/* 出庫実績入力 */}
			<Route
				path={getLinks().issuing.entryResult}
				element={<IssuingEntryResult />}
			>
				<Route
					path={':id'}
					element={<IssuingEntryResult />}
				/>
			</Route>
			{/* 出庫前検品入力 */}
			<Route
				path={getLinks().issuing.inspection}
				element={<IssuingBeforeInspectionEntry />}
			>
				<Route
					path={':id'}
					element={<IssuingBeforeInspectionEntry />}
				/>
			</Route>
			{/* 空きロケーション・棚一覧 */}
			<Route
				path={getLinks().issuing.freeLocationList}
				element={<FreeLocationList />}
			/>
			{/* <<< 出庫メニュー項目 <<< */}
			{
				// #endregion --- 出庫メニュー
			}
			{
				// #region --- 在庫メニュー
			}
			{/* >>> 在庫メニュー項目 >>> */}
			{/* 在庫メニュー */}
			<Route
				path={getLinks().stock.menu}
				element={<StockMenu />}
			/>
			{/* 在庫一覧 */}
			<Route
				path={getLinks().stock.list}
				element={<StockList />}
			/>
			{/* 在庫数編集 */}
			<Route
				path={getLinks().stock.entry}
				element={<StockEntry />}
			>
				<Route
					path=":id"
					element={<StockEntry />}
				/>
			</Route>
			{/* 在庫移動 */}
			<Route
				path={getLinks().stock.move}
				element={<StockMove />}
			>
				<Route
					path=":id"
					element={<StockMove />}
				/>
			</Route>
			{/* セット組みメニュー */}
			<Route
				path={getLinks().stock.setProductMenu}
				element={<SetProductMenu />}
			/>
			{/* セット組み一覧 */}
			<Route
				path={getLinks().stock.setProductList}
				element={<SetProductList />}
			/>
			{/* セット組み 予定入力 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().stock.setProductEntrySchedule}
					element={<SetProductEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<SetProductEntrySchedule />}
					/>
				</Route>
			</Route>
			{/* セット組み 実績入力 */}
			<Route
				path={getLinks().stock.setProductEntryResult}
				element={<SetProductEntryResult />}
			>
				<Route
					path={':id'}
					element={<SetProductEntryResult />}
				/>
			</Route>
			{/* セット組み検品入力 */}
			<Route
				path={getLinks().stock.setProductEntryInspection}
				element={<SetProductEntryInspection />}
			>
				<Route
					path={':id'}
					element={<SetProductEntryInspection />}
				></Route>
			</Route>
			{/* セット崩しメニュー */}
			<Route
				path={getLinks().stock.breakSetProductMenu}
				element={<BreakSetProductMenu />}
			/>
			{/* セット崩し一覧 */}
			<Route
				path={getLinks().stock.breakSetProductList}
				element={<BreakSetProductList />}
			/>
			{/* セット崩し予定入力 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().stock.breakSetProductEntrySchedule}
					element={<BreakSetProductEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<BreakSetProductEntrySchedule />}
					/>
				</Route>
			</Route>
			{/* セット崩し実績入力 */}
			<Route
				path={getLinks().stock.breakSetProductEntryResult}
				element={<BreakSetProductEntryResult />}
			>
				<Route
					path={':id'}
					element={<BreakSetProductEntryResult />}
				/>
			</Route>
			{/* セット崩し検品入力 */}
			<Route
				path={getLinks().stock.breakSetProductEntryInspection}
				element={<BreakSetProductEntryInspection />}
			>
				<Route
					path={':id'}
					element={<BreakSetProductEntryInspection />}
				/>
			</Route>
			{/* 棚卸メニュー */}
			<Route
				path={getLinks().stock.stockTakingMenu}
				element={<StockTakingMenu />}
			/>
			{/* 棚卸リスト */}
			<Route
				path={getLinks().stock.stockTakingList}
				element={<StockTakingList />}
			/>
			{/* 棚卸予定入力 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().stock.entryStockTakingSchedule}
					element={<StockTakingEntrySchedule />}
				>
					<Route
						path={':id'}
						element={<StockTakingEntrySchedule />}
					/>
				</Route>
			</Route>
			{/* 棚卸実績入力 */}
			<Route
				path={getLinks().stock.entryStockTakingResult}
				element={<StockTakingEntryResult />}
			>
				<Route
					path={':id'}
					element={<StockTakingEntryResult />}
				/>
			</Route>
			{/* 欠品リスト */}
			<Route
				path={getLinks().stock.stockoutList}
				element={<StockOutList />}
			/>
			{/* 補充 */}
			<Route
				path={getLinks().stock.restockMenu}
				element={<RestockMenu />}
			/>
			<Route
				path={getLinks().stock.restockList}
				element={<RestockList />}
			/>
			{/* <<< 在庫メニュー項目 <<< */}
			{
				// #endregion --- 出庫メニュー
			}
			{
				// #region --- マスタメニュー
			}
			{/* >>> マスタメニュー項目 >>> */}
			{/* メニュー */}
			<Route
				path={getLinks().master.menu}
				element={<MasterMenu />}
			/>
			{
				// #region --- 顧客
			}
			<Route
				path={getLinks().master.customerMenu}
				element={<CustomerMenu />}
			/>
			{/* 顧客 */}
			<Route
				path={getLinks().master.customerList}
				element={<CustomerList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.customerEntry}
					element={<CustomerEntry />}
				>
					<Route
						path=":id"
						element={<CustomerEntry />}
					/>
				</Route>
				{/* 顧客区分 */}
				<Route
					path={getLinks().master.customerCategoryList}
					element={<CustomerCategoryList />}
				/>
				<Route
					path={getLinks().master.customerCategoryEntry}
					element={<CustomerCategoryEntry />}
				>
					<Route
						path={':id'}
						element={<CustomerCategoryEntry />}
					/>
				</Route>
				{/* 顧客配送先 */}
				<Route
					path={getLinks().master.customerDeliveryDestinationList}
					element={<CustomerDeliveryDestinationList />}
				/>
				<Route
					path={getLinks().master.customerDeliveryDestinationEntry}
					element={<CustomerDeliveryDestinationEntry />}
				>
					<Route
						path={':id'}
						element={<CustomerDeliveryDestinationEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 顧客
			}
			{/* 検索条件  */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.searchConditionList}
					element={<SearchConditionList />}
				/>
			</Route>
			{/* <Route
				path={getLinks().master.searchConditionEntry}
				element={<SearchConditionEntry />}
			/> */}
			{
				// #region --- 資産
			}
			{/* 資産  */}
			<Route
				path={getLinks().master.assetMenu}
				element={<AssetMenu />}
			/>
			<Route
				path={getLinks().master.assetList}
				element={<AssetList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.assetEntry}
					element={<AssetEntry />}
				>
					<Route
						path=":id"
						element={<AssetEntry />}
					/>
				</Route>
				{/* 資産区分  */}
				<Route
					path={getLinks().master.assetCategoryList}
					element={<AssetCategoryList />}
				/>
				<Route
					path={getLinks().master.assetCategoryEntry}
					element={<AssetCategoryEntry />}
				>
					<Route
						path=":id"
						element={<AssetCategoryEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 資産
			}

			{
				// #region --- 配送業者
			}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.deliveryCompanyMenu}
					element={<DeliveryCompanyMenu />}
				/>
				{/* 配送業者一覧 */}
				<Route
					path={getLinks().master.deliveryCompanyList}
					element={<DeliveryCompanyList />}
				/>
				<Route
					path={getLinks().master.deliveryCompanyEntry}
					element={<DeliveryCompanyEntry />}
				>
					<Route
						path=":id"
						element={<DeliveryCompanyBaseEntry />}
					/>
				</Route>
				{/* 配送業者拠点一覧 */}
				<Route
					path={getLinks().master.deliveryCompanyBaseList}
					element={<DeliveryCompanyBaseList />}
				/>
				<Route
					path={getLinks().master.deliveryCompanyBaseEntry}
					element={<DeliveryCompanyBaseEntry />}
				>
					<Route
						path=":id"
						element={<DeliveryCompanyBaseEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 配送業者
			}

			{/* 入荷区分 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.arrivalCategoryList}
					element={<ArrivalCategoryList />}
				/>
				<Route
					path={getLinks().master.arrivalCategoryEntry}
					element={<ArrivalCategoryEntry />}
				>
					<Route
						path=":id"
						element={<ArrivalCategoryEntry />}
					/>
				</Route>
			</Route>

			{
				// #region --- 仕入先
			}
			<Route
				path={getLinks().master.clientMenu}
				element={<ClientMenu />}
			/>
			{/* 仕入先  */}
			<Route
				path={getLinks().master.clientList}
				element={<ClientList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.clientEntry}
					element={<ClientEntry />}
				>
					<Route
						path=":id"
						element={<ClientEntry />}
					/>
				</Route>
				{/* 仕入先区分 */}
				<Route
					path={getLinks().master.clientCategoryList}
					element={<ClientCategoryList />}
				/>
				<Route
					path={getLinks().master.clientCategoryEntry}
					element={<ClientCategoryEntry />}
				>
					<Route
						path=":id"
						element={<ClientCategoryEntry />}
					/>
				</Route>
				{/* 仕入先出荷元 */}
				<Route
					path={getLinks().master.clientSenderList}
					element={<ClientSenderList />}
				/>
				<Route
					path={getLinks().master.clientSenderEntry}
					element={<ClientSenderEntry />}
				>
					<Route
						path=":id"
						element={<ClientSenderEntry />}
					/>
				</Route>
				{/* 仕入先輸送手段 */}
				<Route
					path={getLinks().master.transportMethodList}
					element={<TransportMethodList />}
				/>
				<Route
					path={getLinks().master.transportMethodEntry}
					element={<TransportMethodEntry />}
				>
					<Route
						path=":id"
						element={<TransportMethodEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 仕入先
			}
			{
				// #region --- 商品
			}
			<Route
				path={getLinks().master.productMenu}
				element={<ProductMenu />}
			/>
			{/* 商品 */}
			<Route
				path={getLinks().master.productList}
				element={<ProductList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.productEntry}
					element={<ProductEntry />}
				>
					<Route
						path={':id'}
						element={<ProductEntry />}
					/>
				</Route>
			</Route>
			{/* 品番 */}
			<Route
				path={getLinks().master.partNumberList}
				element={<PartNumberList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.partNumberEntry}
					element={<PartNumberEntry />}
				>
					<Route
						path=":id"
						element={<PartNumberCategoryEntry />}
					/>
				</Route>
			</Route>
			{/* 品番区分 */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.partNumberCategoryList}
					element={<PartNumberCategoryList />}
				/>
				<Route
					path={getLinks().master.partNumberCategoryEntry}
					element={<PartNumberCategoryEntry />}
				>
					<Route
						path=":id"
						element={<PartNumberCategoryEntry />}
					/>
				</Route>
			</Route>
			{/* 商品詳細項目 */}
			{/* <Route
				path={getLinks().master.productDetailList}
				element={<ProductDetailList />}
			/>
			<Route
				path={getLinks().master.productDetailEntry}
				element={<ProductDetailEntry />}
			>
				<Route
					path=":id"
					element={<ProductDetailEntry />}
				/>
			</Route> */}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				{/* 商品大項目 */}
				<Route
					path={getLinks().master.productLargeCategoryList}
					element={<ProductLargeCategoryList />}
				/>
				<Route
					path={getLinks().master.productLargeCategoryEntry}
					element={<ProductLargeCategoryEntry />}
				>
					<Route
						path=":id"
						element={<ProductLargeCategoryEntry />}
					/>
				</Route>
				{/* 商品小項目 */}
				<Route
					path={getLinks().master.productSmallCategoryList}
					element={<ProductSmallCategoryList />}
				/>
				<Route
					path={getLinks().master.productSmallCategoryEntry}
					element={<ProductSmallCategoryEntry />}
				>
					<Route
						path=":id"
						element={<ProductSmallCategoryEntry />}
					/>
				</Route>
				{/* 商品状態 */}
				<Route
					path={getLinks().master.productStatusList}
					element={<ProductStatusList />}
				/>
				<Route
					path={getLinks().master.productStatusEntry}
					element={<ProductStatusEntry />}
				>
					<Route
						path=":id"
						element={<ProductStatusEntry />}
					/>
				</Route>
			</Route>
			{/* SKUグループ */}
			<Route
				path={getLinks().master.skuGroupList}
				element={<SKUGroupList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.skuGroupEntry}
					element={<SKUGroupEntry />}
				>
					<Route
						path=":id"
						element={<SKUGroupEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 商品
			}
			{
				// #region --- 倉庫
			}
			<Route
				path={getLinks().master.warehouseMenu}
				element={<WarehouseMenu />}
			/>
			{/* 倉庫  */}
			<Route
				path={getLinks().master.warehouseList}
				element={<WarehouseList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.warehouseEntry}
					element={<WarehouseEntry />}
				>
					<Route
						path=":id"
						element={<WarehouseEntry />}
					/>
				</Route>
				{/* 倉庫区分  */}
				<Route
					path={getLinks().master.warehouseCategoryList}
					element={<WarehouseCategoryList />}
				/>
				<Route
					path={getLinks().master.warehouseCategoryEntry}
					element={<WarehouseCategoryEntry />}
				>
					<Route
						path=":id"
						element={<WarehouseEntry />}
					/>
				</Route>
			</Route>
			{/* ロケーション */}
			<Route
				path={getLinks().master.locationList}
				element={<LocationList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.locationEntry}
					element={<LocationEntry />}
				>
					<Route
						path=":id"
						element={<LocationEntry />}
					/>
				</Route>
				{/* ロケーション区分 */}
				<Route
					path={getLinks().master.locationCategoryList}
					element={<LocationCategoryList />}
				/>
				<Route
					path={getLinks().master.locationCategoryEntry}
					element={<LocationCategoryEntry />}
				>
					<Route
						path=":id"
						element={<LocationCategoryEntry />}
					/>
				</Route>
			</Route>
			{/* ロケーション明細*/}
			<Route
				path={getLinks().master.locationDetailsList}
				element={<LocationDetailsList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.locationDetailsEntry}
					element={<LocationDetailsEntry />}
				>
					<Route
						path={':id'}
						element={<LocationDetailsEntry />}
					/>
				</Route>
			</Route>
			{/* 通路 */}
			<Route
				path={getLinks().master.aisleList}
				element={<AisleList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.aisleEntry}
					element={<AisleEntry />}
				>
					<Route
						path=":id"
						element={<AisleEntry />}
					/>
				</Route>
				{/* 保管条件 */}
				<Route
					path={getLinks().master.storingConditionList}
					element={<StoringConditionList />}
				/>
				<Route
					path={getLinks().master.storingConditionEntry}
					element={<StoringConditionEntry />}
				>
					<Route
						path=":id"
						element={<StoringConditionEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 倉庫
			}
			{
				// #region --- 梱包
			}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.packagingMenu}
					element={<PackagingMenu />}
				/>
				{/* 梱包区分 */}
				<Route
					path={getLinks().master.packagingCategoryList}
					element={<PackagingCategoryList />}
				/>
				<Route
					path={getLinks().master.packagingCategoryEntry}
					element={<PackagingCategoryEntry />}
				>
					<Route
						path=":id"
						element={<PackagingCategoryEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 梱包
			}
			{
				// #region --- 荷主
			}
			<Route
				path={getLinks().master.shipperMenu}
				element={<ShipperMenu />}
			/>
			{/* 荷主 */}
			<Route
				path={getLinks().master.shipperList}
				element={<ShipperList />}
			/>
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				<Route
					path={getLinks().master.shipperEntry}
					element={<ShipperEntry />}
				>
					<Route
						path=":id"
						element={<ShipperEntry />}
					/>
				</Route>
				{/* 荷主契約 */}
				<Route
					path={getLinks().master.shipperContractList}
					element={<ShipperContractList />}
				/>
				<Route
					path={getLinks().master.shipperContractEntry}
					element={<ShipperContractEntry />}
				>
					<Route
						path=":id"
						element={<ShipperContractEntry />}
					/>
				</Route>
			</Route>
			{
				// #endregion --- 荷主
			}
			{
				// #endregion --- マスタメニュー
			}
			{
				// #region --- 帳票出力
			}
			{/* 出荷明細 */}
			<Route
				path={getLinks().print.shippingDetail}
				element={<ShippingDetail />}
			/>
			{/* 入荷予定リスト */}
			<Route
				path={getLinks().print.printArrivalList}
				element={<PrintArrivalList />}
			/>
			{/* シングルピッキングリスト */}
			<Route
				path={getLinks().print.singlePicking}
				element={<SinglePicking />}
			/>
			{/* トータルピッキングリスト */}
			<Route
				path={getLinks().print.totalPicking}
				element={<TotalPicking />}
			/>
			{/* 未引当一覧 */}
			<Route
				path={getLinks().print.unAllocated}
				element={<UnAllocated />}
			/>
			{/* 入出庫明細 */}
			<Route
				path={getLinks().print.receiveIssueDetail}
				element={<ReceiveIssueDetail />}
			/>
			{/* 入出庫行き先ラベル */}
			<Route
				path={getLinks().print.receiveIssueLabel}
				element={<ReceiveIssueLabel />}
			/>
			{/* 在庫移動ラベル */}
			<Route
				path={getLinks().print.stockLabel}
				element={<StockLabel />}
			/>
			{/* 佐川送状 */}
			<Route
				path={getLinks().print.sagawaShippingLabel}
				element={<SagawaShippingLabel />}
			>
				<Route
					path={':id'}
					element={<SagawaShippingLabel />}
				/>
			</Route>
			{/* 佐川荷物受渡書 */}
			<Route
				path={getLinks().print.sagawaShippingSummaryLabel}
				element={<SagawaShippingSummaryLabel />}
			/>
			{/* 在庫移動明細 */}
			<Route
				path={getLinks().print.stockMovementDetail}
				element={<StockMovementDetail />}
			/>
			{
				// #endregion --- 帳票出力
			}
			{
				// #region --- システム設定
			}
			<Route
				path={'/'}
				element={MobileRoute(isDesktopStyle)}
			>
				{/* 画面操作ログ */}
				<Route
					path={getLinks().systemMaintenance.operationLogList}
					element={<OperationLogList />}
				/>
				{/* システム設定 */}
				<Route
					path={getLinks().master.systemSettingList}
					element={<SystemsettingList />}
				/>
			</Route>
			{
				// #endregion --- システム設定
			}
			{
				// #region --- システム保守
			}
			<Route
				path={'/'}
				element={AuthorizeRoute(1)}
			>
				<Route
					path={'/'}
					element={MobileRoute(isDesktopStyle)}
				>
					{/* システム保守メニュー  */}
					<Route
						path={getLinks().systemMaintenance.menu}
						element={<SystemMaintenanceMenu />}
					/>
					{/* ユーザーメニュー  */}
					<Route
						path={getLinks().master.userMenu}
						element={<UserMenu />}
					/>
					{/* システム利用ユーザー 一覧 */}
					<Route
						path={getLinks().master.systemUserList}
						element={<SystemUserList />}
					/>
					<Route
						path={getLinks().master.systemUserEntry}
						element={<SystemUserEntry />}
					>
						<Route
							path=":id"
							element={<SystemUserEntry />}
						/>
					</Route>
					{/* 権限一覧 */}
					<Route
						path={getLinks().master.userAuthorityList}
						element={<UserAuthorityList />}
					/>
					<Route
						path={getLinks().master.userAuthorityEntry}
						element={<UserAuthorityEntry />}
					>
						<Route
							path=":id"
							element={<UserAuthorityEntry />}
						/>
					</Route>
					{/* 会社 */}
					<Route
						path={getLinks().systemMaintenance.companyMenu}
						element={<CompanyMenu />}
					/>
					<Route
						path={getLinks().systemMaintenance.companyList}
						element={<CompanyList />}
					/>
					<Route
						path={getLinks().systemMaintenance.companyEntry}
						element={<CompanyEntry />}
					>
						<Route
							path=":id"
							element={<CompanyEntry />}
						/>
					</Route>

					{/* 地域メニュー */}
					<Route
						path={getLinks().systemMaintenance.regionMenu}
						element={<RegionMenu />}
					/>
					{/* 国コード */}
					<Route
						path={getLinks().systemMaintenance.countryCodeList}
						element={<CountryCodeList />}
					/>
					{/* 都道府県コード */}
					<Route
						path={getLinks().systemMaintenance.prefecturesCodeList}
						element={<PrefecturesCodeList />}
					/>
					{/* 機能メニュー */}
					<Route
						path={getLinks().systemMaintenance.featureMenu}
						element={<FeaturesMenu />}
					/>
					{/* 機能一覧 */}
					<Route
						path={getLinks().systemMaintenance.featureList}
						element={<FeaturesList />}
					/>
					<Route
						path={getLinks().systemMaintenance.featureEntry}
						element={<FeaturesEntry />}
					>
						<Route
							path=":id"
							element={<FeaturesEntry />}
						/>
					</Route>
					{/* 機能区分一覧 */}
					<Route
						path={getLinks().systemMaintenance.featureCategoryList}
						element={<FeaturesCategoryList />}
					/>
					<Route
						path={getLinks().systemMaintenance.featureCategoryEntry}
						element={<FeaturesCategoryEntry />}
					>
						<Route
							path=":id"
							element={<FeaturesCategoryEntry />}
						/>
					</Route>
				</Route>
			</Route>

			{
				// #endregion --- システム保守
			}
			{
				// #region --- アカウント
			}
			{/** ログイン */}
			<Route
				path={getLinks().account.login}
				element={<Login />}
			/>
			{
				// #endregion --- アカウント
			}
			{/** CSV */}
			<Route
				path={getLinks().csv.order}
				element={<OrderCsvUploader />}
			/>
			<Route
				path={getLinks().csv.arrival}
				element={<ArrivalCsvUploader />}
			/>
			<Route
				path={getLinks().csv.packaging}
				element={<PackagingCsvUploader />}
			/>
		</Routes>
	);
});

function Routing() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/account/login"
					element={<Login />}
				/>
				<Route
					path="*"
					element={<AuthChecker />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default Routing;
