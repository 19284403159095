import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type ShippingOrderDetailListRaw = BasicResponse & {
	shippingId: number;
	shippingCode: string;

	companyId: number;

	shipperId: number;
	shipperCode: string;
	shipperName: string;

	orderId: number;
	orderCode: string;
	orderDate: string;

	deliveryDate: string | undefined; // p: 0815 - undefined追加
	deliveryTime: string;

	customerPostalCode: string | undefined; // p: 0815 - undefined追加
	customerAddress: string;
	customerName: string;
	customerTel: string;

	companyContractCode: string;
	companyContractPostalCode: string;
	companyContractAddress: string;
	companyContractName: string;
	companyContractTel: string;

	pointUsageFee: number;
	paymentFee: number; // 決済手数料
	paymentTax: number; // 決済手数料税額
	packagingFee: number; // 梱包手数料
	packagingTax: number; // 梱包手数料税額
	shippingFee: number; // 送料
	shippingTax: number; // 送料税額
	deliveryFee: number; // 配送手数料
	deliveryTax: number; // 配送手数料税額
	otherFee: number; // その他手数料

	paymentCategoryId: number;
	paymentCategoryCode: string;
	paymentCategoryName: string;
};

export type ShippingDetail = {
	orderDetailId: number; // 受注明細id
	productCode: string; // 商品コード
	productName: string; // 商品名
	pcs: number; // 数量
	price: number; // 単価
	tax: number; // 消費税

	// ピックリスト用
	// TODO: ここに必要か、別の定義にするか要検討
	locationDetailId?: number; // ロケーションid
	locationDetailName?: string; // ロケーション名
};

export type ShippingOrderDetailListResponse = ShippingOrderDetailListRaw & {
	shippingDetail: ShippingDetail[];
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: ShippingOrderDetailListResponse[];
	}>();

export const shippingDetailListApi = createApiInterfaceMap({
	['/v1/print/shipping/detail-list']: listApi,
});
