// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema } from "./../../master";
import { InferType } from "yup";
import { typeStockTakingScheduledDateSchema } from "./../../stock";

export const formStockStockTakingResultEntryLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema;
export type FormStockStockTakingResultEntryLocationDetailIdSchemaType = InferType<typeof formStockStockTakingResultEntryLocationDetailIdSchema>;
export const formStockStockTakingResultEntryLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema;
export type FormStockStockTakingResultEntryLocationDetailCodeSchemaType = InferType<typeof formStockStockTakingResultEntryLocationDetailCodeSchema>;
export const formStockStockTakingResultEntryLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema;
export type FormStockStockTakingResultEntryLocationDetailNameSchemaType = InferType<typeof formStockStockTakingResultEntryLocationDetailNameSchema>;
export const formStockStockTakingResultEntryStockTakingScheduleDateSchema = typeStockTakingScheduledDateSchema;
export type FormStockStockTakingResultEntryStockTakingScheduleDateSchemaType = InferType<typeof formStockStockTakingResultEntryStockTakingScheduleDateSchema>;
export const formStockStockTakingResultEntrySchema = yup.object({
	locationDetailId: formStockStockTakingResultEntryLocationDetailIdSchema,
	locationDetailCode: formStockStockTakingResultEntryLocationDetailCodeSchema,
	locationDetailName: formStockStockTakingResultEntryLocationDetailNameSchema,
	stockTakingScheduleDate: formStockStockTakingResultEntryStockTakingScheduleDateSchema,
}).required();
export type FormStockStockTakingResultEntrySchemaType = InferType<typeof formStockStockTakingResultEntrySchema>;
