// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formArrivalArrivalInspectionArrivalIdSchema = typeCommonInspectionNumberSchema;
export type FormArrivalArrivalInspectionArrivalIdSchemaType = InferType<typeof formArrivalArrivalInspectionArrivalIdSchema>;
export const formArrivalArrivalInspectionSchema = yup.object({
	arrivalId: formArrivalArrivalInspectionArrivalIdSchema,
}).required();
export type FormArrivalArrivalInspectionSchemaType = InferType<typeof formArrivalArrivalInspectionSchema>;
