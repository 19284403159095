// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";
import { typeMasterWarehouseLocationIdSchema, typeMasterWarehouseLocationCodeSchema, typeMasterWarehouseLocationNameSchema, typeMasterProductPartNumberCodeSchema, typeMasterProductPartNumberNameSchema, typeMasterProductIdSchema, typeMasterProductCodeSchema, typeMasterProductNameSchema } from "./../../master";

export const formStockStockListInputSearchCategorySchema = yup.string().optional().label("schema.common.search.label.target");
export type FormStockStockListInputSearchCategorySchemaType = InferType<typeof formStockStockListInputSearchCategorySchema>;
export const formStockStockListInputLocationIdSchema = typeMasterWarehouseLocationIdSchema.optional();
export type FormStockStockListInputLocationIdSchemaType = InferType<typeof formStockStockListInputLocationIdSchema>;
export const formStockStockListInputLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormStockStockListInputLocationCodeSchemaType = InferType<typeof formStockStockListInputLocationCodeSchema>;
export const formStockStockListInputLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormStockStockListInputLocationNameSchemaType = InferType<typeof formStockStockListInputLocationNameSchema>;
export const formStockStockListInputPartNumberCodeSchema = typeMasterProductPartNumberCodeSchema.optional();
export type FormStockStockListInputPartNumberCodeSchemaType = InferType<typeof formStockStockListInputPartNumberCodeSchema>;
export const formStockStockListInputPartNumberNameSchema = typeMasterProductPartNumberNameSchema.optional();
export type FormStockStockListInputPartNumberNameSchemaType = InferType<typeof formStockStockListInputPartNumberNameSchema>;
export const formStockStockListInputProductIdSchema = typeMasterProductIdSchema.optional();
export type FormStockStockListInputProductIdSchemaType = InferType<typeof formStockStockListInputProductIdSchema>;
export const formStockStockListInputProductCodeSchema = typeMasterProductCodeSchema.optional();
export type FormStockStockListInputProductCodeSchemaType = InferType<typeof formStockStockListInputProductCodeSchema>;
export const formStockStockListInputProductNameSchema = typeMasterProductNameSchema.optional();
export type FormStockStockListInputProductNameSchemaType = InferType<typeof formStockStockListInputProductNameSchema>;
export const formStockStockListInputSchema = yup.object({
	searchCategory: formStockStockListInputSearchCategorySchema,
	locationId: formStockStockListInputLocationIdSchema,
	locationCode: formStockStockListInputLocationCodeSchema,
	locationName: formStockStockListInputLocationNameSchema,
	partNumberCode: formStockStockListInputPartNumberCodeSchema,
	partNumberName: formStockStockListInputPartNumberNameSchema,
	productId: formStockStockListInputProductIdSchema,
	productCode: formStockStockListInputProductCodeSchema,
	productName: formStockStockListInputProductNameSchema,
}).required();
export type FormStockStockListInputSchemaType = InferType<typeof formStockStockListInputSchema>;
