import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { array, number, object } from 'yup';
import { BasicResponse } from '../../response';

export type SagawaShippingLabelResponse = BasicResponse & {
	shippingId: number
	shippingCode: string
	shippingDate: string
	deliveryDate: string
	codAmount: number
	tax: number
	packagingAmount: number
	deliveryInvoiceNumber: string
	partNumberCategoryName: string
	customerDeliveryDestinationPostalCode: string
	customerDeliveryDestinationTel: string
	customerDeliveryDestinationAddress: string
	customerDeliveryDestinationName: string
	deliveryCompanyBaseName: string
	deliveryCompanyBaseTel: string
	deliveryCompanyContractAddress: string
	deliveryCompanyContractName: string
	deliveryCompanyContractTel: string
	deliveryCompanyContractDisplayCode: string
	deliverySalesOfficeCode: string
	deliverySalesOfficeLocalCode: string
	sagawaDeliveryCategoryName: string
	deliveryTimeName: string
	sagawaReceiptCategoryDisplayCode: string
	senderSalesOfficeName: string
	senderSalesOfficeAskPhoneNumber: string
	senderSalesOfficeFaxNumber: string
	deliverySalesOfficeName: string
	deliverySalesOfficeAskPhoneNumber: string
	deliverySalesOfficeFaxNumber: string
	packagingMaterialName: string
	printingSystemName: string
	weight1Code: string
	weight1Kg: string
	weight1Size: string
	weight2Code: string
	weight2Kg: string
	weight2Size: string
	weight3Code: string
	weight3Kg: string
	weight3Size: string
};

const listApi = createApiInterface()
	.request(object({ id: array().of(number().integer().required()) }))
	.response<{
		list: SagawaShippingLabelResponse[];
	}>();

export const sagawaShippingLabelApi = createApiInterfaceMap({
	['/v1/print/sagawa-shipping-label/list']: listApi,
});
