// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterWarehouseLocationIdSchema, typeMasterWarehouseLocationCodeSchema, typeMasterWarehouseLocationNameSchema, typeMasterWarehouseLocationDetailIdSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema, typeMasterWarehouseLocationDetailAllocationPrioritySchema, typeMasterWarehouseLocationDetailMixedLocationFlagSchema, typeMasterWarehouseStoringConditionIdSchema } from "./../../master";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";

export const formIssuingFreeLocationListInputShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormIssuingFreeLocationListInputShipperIdSchemaType = InferType<typeof formIssuingFreeLocationListInputShipperIdSchema>;
export const formIssuingFreeLocationListInputShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormIssuingFreeLocationListInputShipperCodeSchemaType = InferType<typeof formIssuingFreeLocationListInputShipperCodeSchema>;
export const formIssuingFreeLocationListInputShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormIssuingFreeLocationListInputShipperNameSchemaType = InferType<typeof formIssuingFreeLocationListInputShipperNameSchema>;
export const formIssuingFreeLocationListInputLocationIdSchema = typeMasterWarehouseLocationIdSchema.optional();
export type FormIssuingFreeLocationListInputLocationIdSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationIdSchema>;
export const formIssuingFreeLocationListInputLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormIssuingFreeLocationListInputLocationCodeSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationCodeSchema>;
export const formIssuingFreeLocationListInputLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormIssuingFreeLocationListInputLocationNameSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationNameSchema>;
export const formIssuingFreeLocationListInputLocationDetailIdSchema = typeMasterWarehouseLocationDetailIdSchema.optional();
export type FormIssuingFreeLocationListInputLocationDetailIdSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationDetailIdSchema>;
export const formIssuingFreeLocationListInputLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormIssuingFreeLocationListInputLocationDetailCodeSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationDetailCodeSchema>;
export const formIssuingFreeLocationListInputLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormIssuingFreeLocationListInputLocationDetailNameSchemaType = InferType<typeof formIssuingFreeLocationListInputLocationDetailNameSchema>;
export const formIssuingFreeLocationListInputAssigningPrioritySchema = typeMasterWarehouseLocationDetailAllocationPrioritySchema.optional();
export type FormIssuingFreeLocationListInputAssigningPrioritySchemaType = InferType<typeof formIssuingFreeLocationListInputAssigningPrioritySchema>;
export const formIssuingFreeLocationListInputMixedLocationDetailFlagSchema = typeMasterWarehouseLocationDetailMixedLocationFlagSchema.optional();
export type FormIssuingFreeLocationListInputMixedLocationDetailFlagSchemaType = InferType<typeof formIssuingFreeLocationListInputMixedLocationDetailFlagSchema>;
export const formIssuingFreeLocationListInputStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormIssuingFreeLocationListInputStoringConditionIdSchemaType = InferType<typeof formIssuingFreeLocationListInputStoringConditionIdSchema>;
export const formIssuingFreeLocationListInputSearchItemsSchema = typeSearchItemsSchema;
export type FormIssuingFreeLocationListInputSearchItemsSchemaType = InferType<typeof formIssuingFreeLocationListInputSearchItemsSchema>;
export const formIssuingFreeLocationListInputSchema = yup.object({
	shipperId: formIssuingFreeLocationListInputShipperIdSchema,
	shipperCode: formIssuingFreeLocationListInputShipperCodeSchema,
	shipperName: formIssuingFreeLocationListInputShipperNameSchema,
	locationId: formIssuingFreeLocationListInputLocationIdSchema,
	locationCode: formIssuingFreeLocationListInputLocationCodeSchema,
	locationName: formIssuingFreeLocationListInputLocationNameSchema,
	locationDetailId: formIssuingFreeLocationListInputLocationDetailIdSchema,
	locationDetailCode: formIssuingFreeLocationListInputLocationDetailCodeSchema,
	locationDetailName: formIssuingFreeLocationListInputLocationDetailNameSchema,
	assigningPriority: formIssuingFreeLocationListInputAssigningPrioritySchema,
	mixedLocationDetailFlag: formIssuingFreeLocationListInputMixedLocationDetailFlagSchema,
	storingConditionId: formIssuingFreeLocationListInputStoringConditionIdSchema,
	searchItems: formIssuingFreeLocationListInputSearchItemsSchema,
}).required();
export type FormIssuingFreeLocationListInputSchemaType = InferType<typeof formIssuingFreeLocationListInputSchema>;
