// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductJanCodeSchema } from "./../../master";
import { InferType } from "yup";
import { typeShippingPickingQuantitySchema, typeShippingQuantitySchema, typeShippingInspectionDateSchema } from "./../../shipping";

export const formShippingShippingInspectionEntryJanCodeSchema = typeMasterProductJanCodeSchema.optional();
export type FormShippingShippingInspectionEntryJanCodeSchemaType = InferType<typeof formShippingShippingInspectionEntryJanCodeSchema>;
export const formShippingShippingInspectionEntryPickingQuantitySchema = typeShippingPickingQuantitySchema.optional();
export type FormShippingShippingInspectionEntryPickingQuantitySchemaType = InferType<typeof formShippingShippingInspectionEntryPickingQuantitySchema>;
export const formShippingShippingInspectionEntryQuantitySchema = typeShippingQuantitySchema.optional();
export type FormShippingShippingInspectionEntryQuantitySchemaType = InferType<typeof formShippingShippingInspectionEntryQuantitySchema>;
export const formShippingShippingInspectionEntryInspectionDateSchema = typeShippingInspectionDateSchema;
export type FormShippingShippingInspectionEntryInspectionDateSchemaType = InferType<typeof formShippingShippingInspectionEntryInspectionDateSchema>;
export const formShippingShippingInspectionEntrySchema = yup.object({
	janCode: formShippingShippingInspectionEntryJanCodeSchema,
	pickingQuantity: formShippingShippingInspectionEntryPickingQuantitySchema,
	quantity: formShippingShippingInspectionEntryQuantitySchema,
	inspectionDate: formShippingShippingInspectionEntryInspectionDateSchema,
}).required();
export type FormShippingShippingInspectionEntrySchemaType = InferType<typeof formShippingShippingInspectionEntrySchema>;
