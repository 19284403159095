import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { BasicResponse } from '../../response';
import { number, object } from 'yup';

export type SagawaShippingLabelResponse = BasicResponse & {
	// shippings
	shippingId: number;
	shippingCode: string;
	shippingDate: string | undefined;
	deliveryDate: string | undefined;
	codAmount: number;
	codTax: number;
	// packagings
	packagingDate: string;
	packagingAmount: number | undefined;
	deliveryInvoiceNumber: number;
	// part_number_categories
	partNumberCategoryName: string;
	// customer_delivery_destinations
	customerDeliveryDestinationPostalCode: string | undefined;
	customerDeliveryDestinationTel: string;
	customerDeliveryDestinationAddress: string;
	customerDeliveryDestinationName: string;
	// custoemrs
	customerPostalCode: string | undefined;
	customerTel: string | undefined;
	customerAddress: string;
	customerName: string;
	// delivery_company_bases
	deliveryCompanyBaseName: string;
	deliveryCompanyBaseTel: string | undefined;
	// delivery_company_contracts
	deliveryCompanyContractAddress: string;
	deliveryCompanyContractName: string;
	deliveryCompanyContractTel: string;
	deliveryCompanyContractDisplayCode: string;

	// sagawa_shippings
	deliverySalesOfficeCode: string | undefined;
	deliverySalesOfficeLocalCode: string | undefined;
	sagawaDeliveryCategoryName: string;
	deliveryTimeName: string;
	sagawaReceiptCategoryDisplayCode: string;
	senderSalesOfficeName: string | undefined;
	senderSalesOfficeAskPhoneNumber: string | undefined;
	senderSalesOfficeFaxNumber: string | undefined;
	deliverySalesOfficeName: string | undefined;
	deliverySalesOfficeAskPhoneNumber: string | undefined;
	deliverySalesOfficeFaxNumber: string | undefined;
	packagingMaterialName: string;
	printingSystemName: string;
	weight1Code: string;
	weight1Kg: string;
	weight1Size: string;
	weight2Code: string;
	weight2Kg: string;
	weight2Size: string;
	weight3Code: string;
	weight3Kg: string;
	weight3Size: string;
};

const listApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: SagawaShippingLabelResponse[];
	}>();

export const sagawaShippingLabelApi = createApiInterfaceMap({
	['/v1/print/sagawa-shipping-label/list']: listApi,
});
