// fv-generatorで生成されました
import * as yup from "yup";
import { typeSystemMaintenanceCompanyCodeSchema, typeSystemMaintenanceCompanyNameSchema } from "./../../systemMaintenance";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonPostalCodeSchema, typeCommonAddressSchema, typeCommonTelSchema, typeCommonFaxSchema, typeCommonEmailSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formSystemMaintenanceCompanyListCompanyCodeSchema = typeSystemMaintenanceCompanyCodeSchema.optional();
export type FormSystemMaintenanceCompanyListCompanyCodeSchemaType = InferType<typeof formSystemMaintenanceCompanyListCompanyCodeSchema>;
export const formSystemMaintenanceCompanyListCompanyNameSchema = typeSystemMaintenanceCompanyNameSchema.optional();
export type FormSystemMaintenanceCompanyListCompanyNameSchemaType = InferType<typeof formSystemMaintenanceCompanyListCompanyNameSchema>;
export const formSystemMaintenanceCompanyListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormSystemMaintenanceCompanyListIsAvailableSchemaType = InferType<typeof formSystemMaintenanceCompanyListIsAvailableSchema>;
export const formSystemMaintenanceCompanyListSchema = yup.object({
	companyCode: formSystemMaintenanceCompanyListCompanyCodeSchema,
	companyName: formSystemMaintenanceCompanyListCompanyNameSchema,
	isAvailable: formSystemMaintenanceCompanyListIsAvailableSchema,
}).required();
export type FormSystemMaintenanceCompanyListSchemaType = InferType<typeof formSystemMaintenanceCompanyListSchema>;
export const formSystemMaintenanceCompanyEntryCompanyCodeSchema = typeSystemMaintenanceCompanyCodeSchema;
export type FormSystemMaintenanceCompanyEntryCompanyCodeSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyCodeSchema>;
export const formSystemMaintenanceCompanyEntryCompanyNameSchema = typeSystemMaintenanceCompanyNameSchema;
export type FormSystemMaintenanceCompanyEntryCompanyNameSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyNameSchema>;
export const formSystemMaintenanceCompanyEntryCompanyPostalCodeSchema = typeCommonPostalCodeSchema;
export type FormSystemMaintenanceCompanyEntryCompanyPostalCodeSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyPostalCodeSchema>;
export const formSystemMaintenanceCompanyEntryCompanyAddressSchema = typeCommonAddressSchema;
export type FormSystemMaintenanceCompanyEntryCompanyAddressSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyAddressSchema>;
export const formSystemMaintenanceCompanyEntryCompanyTelSchema = typeCommonTelSchema;
export type FormSystemMaintenanceCompanyEntryCompanyTelSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyTelSchema>;
export const formSystemMaintenanceCompanyEntryCompanyFaxSchema = typeCommonFaxSchema;
export type FormSystemMaintenanceCompanyEntryCompanyFaxSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyFaxSchema>;
export const formSystemMaintenanceCompanyEntryCompanyEmailSchema = typeCommonEmailSchema;
export type FormSystemMaintenanceCompanyEntryCompanyEmailSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyEmailSchema>;
export const formSystemMaintenanceCompanyEntryCompanyNoteSchema = typeCommonNoteSchema;
export type FormSystemMaintenanceCompanyEntryCompanyNoteSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryCompanyNoteSchema>;
export const formSystemMaintenanceCompanyEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormSystemMaintenanceCompanyEntryAvailableFromSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryAvailableFromSchema>;
export const formSystemMaintenanceCompanyEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormSystemMaintenanceCompanyEntryAvailableToSchemaType = InferType<typeof formSystemMaintenanceCompanyEntryAvailableToSchema>;
export const formSystemMaintenanceCompanyEntrySchema = yup.object({
	companyCode: formSystemMaintenanceCompanyEntryCompanyCodeSchema,
	companyName: formSystemMaintenanceCompanyEntryCompanyNameSchema,
	companyPostalCode: formSystemMaintenanceCompanyEntryCompanyPostalCodeSchema,
	companyAddress: formSystemMaintenanceCompanyEntryCompanyAddressSchema,
	companyTel: formSystemMaintenanceCompanyEntryCompanyTelSchema,
	companyFax: formSystemMaintenanceCompanyEntryCompanyFaxSchema,
	companyEmail: formSystemMaintenanceCompanyEntryCompanyEmailSchema,
	companyNote: formSystemMaintenanceCompanyEntryCompanyNoteSchema,
	availableFrom: formSystemMaintenanceCompanyEntryAvailableFromSchema,
	availableTo: formSystemMaintenanceCompanyEntryAvailableToSchema,
}).required();
export type FormSystemMaintenanceCompanyEntrySchemaType = InferType<typeof formSystemMaintenanceCompanyEntrySchema>;
