// fv-generatorで生成されました
import * as yup from "yup";
import { typeReceivingCodeSchema, typeReceivingNameSchema, typeReceivingScheduledDateSchema, typeReceivingCategoryIdSchema, typeReceivingMovingSourceLocationDetailIdSchema, typeReceivingMovingSourceLocationDetailCodeSchema, typeReceivingMovingSourceLocationDetailNameSchema, typeReceivingDestinationLocationDetailIdSchema, typeReceivingDestinationLocationDetailCodeSchema, typeReceivingDestinationLocationDetailNameSchema, typeReceivingDetailIdSchema, typeReceivingScheduledQuantitySchema } from "./../../receiving";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterProductSkuIdSchema, typeMasterProductIdSchema } from "./../../master";

export const formReceivingReceivingScheduleEntryReceiveIssueCodeSchema = typeReceivingCodeSchema;
export type FormReceivingReceivingScheduleEntryReceiveIssueCodeSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceiveIssueCodeSchema>;
export const formReceivingReceivingScheduleEntryReceiveIssueNameSchema = typeReceivingNameSchema;
export type FormReceivingReceivingScheduleEntryReceiveIssueNameSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceiveIssueNameSchema>;
export const formReceivingReceivingScheduleEntryReceivingScheduledDateSchema = typeReceivingScheduledDateSchema;
export type FormReceivingReceivingScheduleEntryReceivingScheduledDateSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingScheduledDateSchema>;
export const formReceivingReceivingScheduleEntryReceiveIssueCategoryIdSchema = typeReceivingCategoryIdSchema;
export type FormReceivingReceivingScheduleEntryReceiveIssueCategoryIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceiveIssueCategoryIdSchema>;
export const formReceivingReceivingScheduleEntryMovingSourceLocationDetailIdSchema = typeReceivingMovingSourceLocationDetailIdSchema.optional();
export type FormReceivingReceivingScheduleEntryMovingSourceLocationDetailIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryMovingSourceLocationDetailIdSchema>;
export const formReceivingReceivingScheduleEntryMovingSourceLocationDetailCodeSchema = typeReceivingMovingSourceLocationDetailCodeSchema.optional();
export type FormReceivingReceivingScheduleEntryMovingSourceLocationDetailCodeSchemaType = InferType<typeof formReceivingReceivingScheduleEntryMovingSourceLocationDetailCodeSchema>;
export const formReceivingReceivingScheduleEntryMovingSourceLocationDetailNameSchema = typeReceivingMovingSourceLocationDetailNameSchema.optional();
export type FormReceivingReceivingScheduleEntryMovingSourceLocationDetailNameSchemaType = InferType<typeof formReceivingReceivingScheduleEntryMovingSourceLocationDetailNameSchema>;
export const formReceivingReceivingScheduleEntryDestinationLocationDetailIdSchema = typeReceivingDestinationLocationDetailIdSchema.optional();
export type FormReceivingReceivingScheduleEntryDestinationLocationDetailIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryDestinationLocationDetailIdSchema>;
export const formReceivingReceivingScheduleEntryDestinationLocationDetailCodeSchema = typeReceivingDestinationLocationDetailCodeSchema.optional();
export type FormReceivingReceivingScheduleEntryDestinationLocationDetailCodeSchemaType = InferType<typeof formReceivingReceivingScheduleEntryDestinationLocationDetailCodeSchema>;
export const formReceivingReceivingScheduleEntryDestinationLocationDetailNameSchema = typeReceivingDestinationLocationDetailNameSchema.optional();
export type FormReceivingReceivingScheduleEntryDestinationLocationDetailNameSchemaType = InferType<typeof formReceivingReceivingScheduleEntryDestinationLocationDetailNameSchema>;
export const formReceivingReceivingScheduleEntryReceiveIssueNoteSchema = typeCommonNoteSchema;
export type FormReceivingReceivingScheduleEntryReceiveIssueNoteSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceiveIssueNoteSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailIdSchema = typeReceivingDetailIdSchema.optional();
export type FormReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailIdSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormReceivingReceivingScheduleEntryReceivingDetailValueSkuIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueSkuIdSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueReceivingScheduledQuantitySchema = typeReceivingScheduledQuantitySchema.optional();
export type FormReceivingReceivingScheduleEntryReceivingDetailValueReceivingScheduledQuantitySchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueReceivingScheduledQuantitySchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailNoteSchema = typeCommonNoteSchema;
export type FormReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailNoteSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailNoteSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueProductIdSchema = typeMasterProductIdSchema.optional();
export type FormReceivingReceivingScheduleEntryReceivingDetailValueProductIdSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueProductIdSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailValueSchema = yup.object({
	receiveIssueDetailId: formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailIdSchema,
	skuId: formReceivingReceivingScheduleEntryReceivingDetailValueSkuIdSchema,
	receivingScheduledQuantity: formReceivingReceivingScheduleEntryReceivingDetailValueReceivingScheduledQuantitySchema,
	receiveIssueDetailNote: formReceivingReceivingScheduleEntryReceivingDetailValueReceiveIssueDetailNoteSchema,
	productId: formReceivingReceivingScheduleEntryReceivingDetailValueProductIdSchema,
}).optional();
export type FormReceivingReceivingScheduleEntryReceivingDetailValueSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailValueSchema>;
export const formReceivingReceivingScheduleEntryReceivingDetailSchema = yup.array(
	formReceivingReceivingScheduleEntryReceivingDetailValueSchema
).required();
export type FormReceivingReceivingScheduleEntryReceivingDetailSchemaType = InferType<typeof formReceivingReceivingScheduleEntryReceivingDetailSchema>;
export const formReceivingReceivingScheduleEntrySchema = yup.object({
	receiveIssueCode: formReceivingReceivingScheduleEntryReceiveIssueCodeSchema,
	receiveIssueName: formReceivingReceivingScheduleEntryReceiveIssueNameSchema,
	receivingScheduledDate: formReceivingReceivingScheduleEntryReceivingScheduledDateSchema,
	receiveIssueCategoryId: formReceivingReceivingScheduleEntryReceiveIssueCategoryIdSchema,
	movingSourceLocationDetailId: formReceivingReceivingScheduleEntryMovingSourceLocationDetailIdSchema,
	movingSourceLocationDetailCode: formReceivingReceivingScheduleEntryMovingSourceLocationDetailCodeSchema,
	movingSourceLocationDetailName: formReceivingReceivingScheduleEntryMovingSourceLocationDetailNameSchema,
	destinationLocationDetailId: formReceivingReceivingScheduleEntryDestinationLocationDetailIdSchema,
	destinationLocationDetailCode: formReceivingReceivingScheduleEntryDestinationLocationDetailCodeSchema,
	destinationLocationDetailName: formReceivingReceivingScheduleEntryDestinationLocationDetailNameSchema,
	receiveIssueNote: formReceivingReceivingScheduleEntryReceiveIssueNoteSchema,
	receivingDetail: formReceivingReceivingScheduleEntryReceivingDetailSchema,
}).required();
export type FormReceivingReceivingScheduleEntrySchemaType = InferType<typeof formReceivingReceivingScheduleEntrySchema>;
