// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionNumberSchema } from "./../../common";
import { InferType } from "yup";

export const formStockBreakSetProductInspectionSkuGroupIdSchema = typeCommonInspectionNumberSchema;
export type FormStockBreakSetProductInspectionSkuGroupIdSchemaType = InferType<typeof formStockBreakSetProductInspectionSkuGroupIdSchema>;
export const formStockBreakSetProductInspectionSchema = yup.object({
	skuGroupId: formStockBreakSetProductInspectionSkuGroupIdSchema,
}).required();
export type FormStockBreakSetProductInspectionSchemaType = InferType<typeof formStockBreakSetProductInspectionSchema>;
