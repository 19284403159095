// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formStockBreakSetProductInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormStockBreakSetProductInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formStockBreakSetProductInspectionMobileEntryInspectionQuantitySchema>;
export const formStockBreakSetProductInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormStockBreakSetProductInspectionMobileEntryLotIdSchemaType = InferType<typeof formStockBreakSetProductInspectionMobileEntryLotIdSchema>;
export const formStockBreakSetProductInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formStockBreakSetProductInspectionMobileEntryInspectionQuantitySchema,
	lotId: formStockBreakSetProductInspectionMobileEntryLotIdSchema,
}).required();
export type FormStockBreakSetProductInspectionMobileEntrySchemaType = InferType<typeof formStockBreakSetProductInspectionMobileEntrySchema>;
