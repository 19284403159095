// fv-generatorで生成されました
import * as yup from "yup";
import { typeSystemMaintenanceRegionCountryNameSchema, typeSystemMaintenanceRegionPrefectureNameSchema } from "./../../systemMaintenance";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema } from "./../../common";

export const formSystemMaintenanceRegionCountryCodeListCountryNameSchema = typeSystemMaintenanceRegionCountryNameSchema.optional();
export type FormSystemMaintenanceRegionCountryCodeListCountryNameSchemaType = InferType<typeof formSystemMaintenanceRegionCountryCodeListCountryNameSchema>;
export const formSystemMaintenanceRegionCountryCodeListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormSystemMaintenanceRegionCountryCodeListIsAvailableSchemaType = InferType<typeof formSystemMaintenanceRegionCountryCodeListIsAvailableSchema>;
export const formSystemMaintenanceRegionCountryCodeListSchema = yup.object({
	countryName: formSystemMaintenanceRegionCountryCodeListCountryNameSchema,
	isAvailable: formSystemMaintenanceRegionCountryCodeListIsAvailableSchema,
}).required();
export type FormSystemMaintenanceRegionCountryCodeListSchemaType = InferType<typeof formSystemMaintenanceRegionCountryCodeListSchema>;
export const formSystemMaintenanceRegionPrefectureCodeListPrefectureNameSchema = typeSystemMaintenanceRegionPrefectureNameSchema.optional();
export type FormSystemMaintenanceRegionPrefectureCodeListPrefectureNameSchemaType = InferType<typeof formSystemMaintenanceRegionPrefectureCodeListPrefectureNameSchema>;
export const formSystemMaintenanceRegionPrefectureCodeListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormSystemMaintenanceRegionPrefectureCodeListIsAvailableSchemaType = InferType<typeof formSystemMaintenanceRegionPrefectureCodeListIsAvailableSchema>;
export const formSystemMaintenanceRegionPrefectureCodeListSchema = yup.object({
	prefectureName: formSystemMaintenanceRegionPrefectureCodeListPrefectureNameSchema,
	isAvailable: formSystemMaintenanceRegionPrefectureCodeListIsAvailableSchema,
}).required();
export type FormSystemMaintenanceRegionPrefectureCodeListSchemaType = InferType<typeof formSystemMaintenanceRegionPrefectureCodeListSchema>;
