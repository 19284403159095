// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterCustomerCategoryCodeSchema, typeMasterCustomerCategoryNameSchema, typeMasterCustomerCodeSchema, typeMasterCustomerNameSchema, typeMasterCustomerDeliveryDestinationCodeSchema, typeMasterCustomerDeliveryDestinationNameSchema, typeMasterCustomerIdSchema, typeMasterCustomerDeliveryDestinationNameRubySchema, typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterCustomerContactNameSchema, typeMasterCustomerCategoryIdSchema, typeMasterCustomerNameRubySchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema, typeCommonPostalCodeSchema, typeCommonAddressSchema, typeCommonTelSchema, typeCommonFaxSchema } from "./../../common";
import { typeSearchItemsSchema } from "./../../search";
import { typeSystemMaintenanceRegionCountryIdSchema, typeSystemMaintenanceRegionPrefectureIdSchema } from "./../../systemMaintenance";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";

export const formMasterCustomerCategoryListCustomerCategoryCodeSchema = typeMasterCustomerCategoryCodeSchema.optional();
export type FormMasterCustomerCategoryListCustomerCategoryCodeSchemaType = InferType<typeof formMasterCustomerCategoryListCustomerCategoryCodeSchema>;
export const formMasterCustomerCategoryListCustomerCategoryNameSchema = typeMasterCustomerCategoryNameSchema.optional();
export type FormMasterCustomerCategoryListCustomerCategoryNameSchemaType = InferType<typeof formMasterCustomerCategoryListCustomerCategoryNameSchema>;
export const formMasterCustomerCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterCustomerCategoryListIsAvailableSchemaType = InferType<typeof formMasterCustomerCategoryListIsAvailableSchema>;
export const formMasterCustomerCategoryListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterCustomerCategoryListSearchItemsSchemaType = InferType<typeof formMasterCustomerCategoryListSearchItemsSchema>;
export const formMasterCustomerCategoryListSchema = yup.object({
	customerCategoryCode: formMasterCustomerCategoryListCustomerCategoryCodeSchema,
	customerCategoryName: formMasterCustomerCategoryListCustomerCategoryNameSchema,
	isAvailable: formMasterCustomerCategoryListIsAvailableSchema,
	searchItems: formMasterCustomerCategoryListSearchItemsSchema,
}).required();
export type FormMasterCustomerCategoryListSchemaType = InferType<typeof formMasterCustomerCategoryListSchema>;
export const formMasterCustomerCategoryEntryCustomerCategoryCodeSchema = typeMasterCustomerCodeSchema;
export type FormMasterCustomerCategoryEntryCustomerCategoryCodeSchemaType = InferType<typeof formMasterCustomerCategoryEntryCustomerCategoryCodeSchema>;
export const formMasterCustomerCategoryEntryCustomerCategoryNameSchema = typeMasterCustomerNameSchema;
export type FormMasterCustomerCategoryEntryCustomerCategoryNameSchemaType = InferType<typeof formMasterCustomerCategoryEntryCustomerCategoryNameSchema>;
export const formMasterCustomerCategoryEntryCustomerCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterCustomerCategoryEntryCustomerCategoryNoteSchemaType = InferType<typeof formMasterCustomerCategoryEntryCustomerCategoryNoteSchema>;
export const formMasterCustomerCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterCustomerCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterCustomerCategoryEntryAvailableFromSchema>;
export const formMasterCustomerCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterCustomerCategoryEntryAvailableToSchemaType = InferType<typeof formMasterCustomerCategoryEntryAvailableToSchema>;
export const formMasterCustomerCategoryEntrySchema = yup.object({
	customerCategoryCode: formMasterCustomerCategoryEntryCustomerCategoryCodeSchema,
	customerCategoryName: formMasterCustomerCategoryEntryCustomerCategoryNameSchema,
	customerCategoryNote: formMasterCustomerCategoryEntryCustomerCategoryNoteSchema,
	availableFrom: formMasterCustomerCategoryEntryAvailableFromSchema,
	availableTo: formMasterCustomerCategoryEntryAvailableToSchema,
}).required();
export type FormMasterCustomerCategoryEntrySchemaType = InferType<typeof formMasterCustomerCategoryEntrySchema>;
export const formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationCodeSchema = typeMasterCustomerDeliveryDestinationCodeSchema.optional();
export type FormMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationCodeSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationCodeSchema>;
export const formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationNameSchema = typeMasterCustomerDeliveryDestinationNameSchema.optional();
export type FormMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationNameSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationNameSchema>;
export const formMasterCustomerDeliveryDestinationListCustomerIdSchema = typeMasterCustomerIdSchema.optional();
export type FormMasterCustomerDeliveryDestinationListCustomerIdSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationListCustomerIdSchema>;
export const formMasterCustomerDeliveryDestinationListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterCustomerDeliveryDestinationListIsAvailableSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationListIsAvailableSchema>;
export const formMasterCustomerDeliveryDestinationListSchema = yup.object({
	customerDeliveryDestinationCode: formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationCodeSchema,
	customerDeliveryDestinationName: formMasterCustomerDeliveryDestinationListCustomerDeliveryDestinationNameSchema,
	customerId: formMasterCustomerDeliveryDestinationListCustomerIdSchema,
	isAvailable: formMasterCustomerDeliveryDestinationListIsAvailableSchema,
}).required();
export type FormMasterCustomerDeliveryDestinationListSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationListSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationCodeSchema = typeMasterCustomerDeliveryDestinationCodeSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationCodeSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationCodeSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameSchema = typeMasterCustomerDeliveryDestinationNameSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameRubySchema = typeMasterCustomerDeliveryDestinationNameRubySchema.optional();
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameRubySchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameRubySchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerIdSchema = typeMasterCustomerIdSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerIdSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerIdSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationPostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationPostalCodeSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationPostalCodeSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationAddressSchema = typeCommonAddressSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationAddressSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationAddressSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationTelSchema = typeCommonTelSchema.required();
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationTelSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationTelSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationFaxSchema = typeCommonFaxSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationFaxSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationFaxSchema>;
export const formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNoteSchema = typeCommonNoteSchema;
export type FormMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNoteSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNoteSchema>;
export const formMasterCustomerDeliveryDestinationEntryCountryIdSchema = typeSystemMaintenanceRegionCountryIdSchema;
export type FormMasterCustomerDeliveryDestinationEntryCountryIdSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryCountryIdSchema>;
export const formMasterCustomerDeliveryDestinationEntryPrefectureIdSchema = typeSystemMaintenanceRegionPrefectureIdSchema.optional();
export type FormMasterCustomerDeliveryDestinationEntryPrefectureIdSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryPrefectureIdSchema>;
export const formMasterCustomerDeliveryDestinationEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterCustomerDeliveryDestinationEntryAvailableFromSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryAvailableFromSchema>;
export const formMasterCustomerDeliveryDestinationEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterCustomerDeliveryDestinationEntryAvailableToSchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntryAvailableToSchema>;
export const formMasterCustomerDeliveryDestinationEntrySchema = yup.object({
	customerDeliveryDestinationCode: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationCodeSchema,
	customerDeliveryDestinationName: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameSchema,
	customerDeliveryDestinationNameRuby: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNameRubySchema,
	customerId: formMasterCustomerDeliveryDestinationEntryCustomerIdSchema,
	customerDeliveryDestinationPostalCode: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationPostalCodeSchema,
	customerDeliveryDestinationAddress: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationAddressSchema,
	customerDeliveryDestinationTel: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationTelSchema,
	customerDeliveryDestinationFax: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationFaxSchema,
	customerDeliveryDestinationNote: formMasterCustomerDeliveryDestinationEntryCustomerDeliveryDestinationNoteSchema,
	countryId: formMasterCustomerDeliveryDestinationEntryCountryIdSchema,
	prefectureId: formMasterCustomerDeliveryDestinationEntryPrefectureIdSchema,
	availableFrom: formMasterCustomerDeliveryDestinationEntryAvailableFromSchema,
	availableTo: formMasterCustomerDeliveryDestinationEntryAvailableToSchema,
}).required();
export type FormMasterCustomerDeliveryDestinationEntrySchemaType = InferType<typeof formMasterCustomerDeliveryDestinationEntrySchema>;
export const formMasterCustomerListCustomerCodeSchema = typeMasterCustomerCodeSchema.optional();
export type FormMasterCustomerListCustomerCodeSchemaType = InferType<typeof formMasterCustomerListCustomerCodeSchema>;
export const formMasterCustomerListCustomerNameSchema = typeMasterCustomerNameSchema.optional();
export type FormMasterCustomerListCustomerNameSchemaType = InferType<typeof formMasterCustomerListCustomerNameSchema>;
export const formMasterCustomerListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterCustomerListShipperIdSchemaType = InferType<typeof formMasterCustomerListShipperIdSchema>;
export const formMasterCustomerListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterCustomerListShipperCodeSchemaType = InferType<typeof formMasterCustomerListShipperCodeSchema>;
export const formMasterCustomerListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterCustomerListShipperNameSchemaType = InferType<typeof formMasterCustomerListShipperNameSchema>;
export const formMasterCustomerListCustomerContactNameSchema = typeMasterCustomerContactNameSchema;
export type FormMasterCustomerListCustomerContactNameSchemaType = InferType<typeof formMasterCustomerListCustomerContactNameSchema>;
export const formMasterCustomerListCustomerCategoryIdSchema = typeMasterCustomerCategoryIdSchema.optional();
export type FormMasterCustomerListCustomerCategoryIdSchemaType = InferType<typeof formMasterCustomerListCustomerCategoryIdSchema>;
export const formMasterCustomerListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterCustomerListIsAvailableSchemaType = InferType<typeof formMasterCustomerListIsAvailableSchema>;
export const formMasterCustomerListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterCustomerListSearchItemsSchemaType = InferType<typeof formMasterCustomerListSearchItemsSchema>;
export const formMasterCustomerListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterCustomerListDetailedConditionsSchemaType = InferType<typeof formMasterCustomerListDetailedConditionsSchema>;
export const formMasterCustomerListSchema = yup.object({
	customerCode: formMasterCustomerListCustomerCodeSchema,
	customerName: formMasterCustomerListCustomerNameSchema,
	shipperId: formMasterCustomerListShipperIdSchema,
	shipperCode: formMasterCustomerListShipperCodeSchema,
	shipperName: formMasterCustomerListShipperNameSchema,
	customerContactName: formMasterCustomerListCustomerContactNameSchema,
	customerCategoryId: formMasterCustomerListCustomerCategoryIdSchema,
	isAvailable: formMasterCustomerListIsAvailableSchema,
	searchItems: formMasterCustomerListSearchItemsSchema,
	detailedConditions: formMasterCustomerListDetailedConditionsSchema,
}).required();
export type FormMasterCustomerListSchemaType = InferType<typeof formMasterCustomerListSchema>;
export const formMasterCustomerEntryCustomerCodeSchema = typeMasterCustomerCodeSchema;
export type FormMasterCustomerEntryCustomerCodeSchemaType = InferType<typeof formMasterCustomerEntryCustomerCodeSchema>;
export const formMasterCustomerEntryCustomerNameSchema = typeMasterCustomerNameSchema;
export type FormMasterCustomerEntryCustomerNameSchemaType = InferType<typeof formMasterCustomerEntryCustomerNameSchema>;
export const formMasterCustomerEntryCustomerNameRubySchema = typeMasterCustomerNameRubySchema.optional();
export type FormMasterCustomerEntryCustomerNameRubySchemaType = InferType<typeof formMasterCustomerEntryCustomerNameRubySchema>;
export const formMasterCustomerEntryCustomerCategoryIdSchema = typeMasterCustomerCategoryIdSchema;
export type FormMasterCustomerEntryCustomerCategoryIdSchemaType = InferType<typeof formMasterCustomerEntryCustomerCategoryIdSchema>;
export const formMasterCustomerEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterCustomerEntryShipperIdSchemaType = InferType<typeof formMasterCustomerEntryShipperIdSchema>;
export const formMasterCustomerEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterCustomerEntryShipperCodeSchemaType = InferType<typeof formMasterCustomerEntryShipperCodeSchema>;
export const formMasterCustomerEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterCustomerEntryShipperNameSchemaType = InferType<typeof formMasterCustomerEntryShipperNameSchema>;
export const formMasterCustomerEntryCustomerPostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterCustomerEntryCustomerPostalCodeSchemaType = InferType<typeof formMasterCustomerEntryCustomerPostalCodeSchema>;
export const formMasterCustomerEntryCustomerAddressSchema = typeCommonAddressSchema;
export type FormMasterCustomerEntryCustomerAddressSchemaType = InferType<typeof formMasterCustomerEntryCustomerAddressSchema>;
export const formMasterCustomerEntryCustomerTelSchema = typeCommonTelSchema;
export type FormMasterCustomerEntryCustomerTelSchemaType = InferType<typeof formMasterCustomerEntryCustomerTelSchema>;
export const formMasterCustomerEntryCustomerFaxSchema = typeCommonFaxSchema;
export type FormMasterCustomerEntryCustomerFaxSchemaType = InferType<typeof formMasterCustomerEntryCustomerFaxSchema>;
export const formMasterCustomerEntryCustomerContactNameSchema = typeMasterCustomerContactNameSchema.optional();
export type FormMasterCustomerEntryCustomerContactNameSchemaType = InferType<typeof formMasterCustomerEntryCustomerContactNameSchema>;
export const formMasterCustomerEntryCustomerNoteSchema = typeCommonNoteSchema;
export type FormMasterCustomerEntryCustomerNoteSchemaType = InferType<typeof formMasterCustomerEntryCustomerNoteSchema>;
export const formMasterCustomerEntryCountryIdSchema = typeSystemMaintenanceRegionCountryIdSchema;
export type FormMasterCustomerEntryCountryIdSchemaType = InferType<typeof formMasterCustomerEntryCountryIdSchema>;
export const formMasterCustomerEntryPrefectureIdSchema = typeSystemMaintenanceRegionPrefectureIdSchema.optional();
export type FormMasterCustomerEntryPrefectureIdSchemaType = InferType<typeof formMasterCustomerEntryPrefectureIdSchema>;
export const formMasterCustomerEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterCustomerEntryAvailableFromSchemaType = InferType<typeof formMasterCustomerEntryAvailableFromSchema>;
export const formMasterCustomerEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterCustomerEntryAvailableToSchemaType = InferType<typeof formMasterCustomerEntryAvailableToSchema>;
export const formMasterCustomerEntrySchema = yup.object({
	customerCode: formMasterCustomerEntryCustomerCodeSchema,
	customerName: formMasterCustomerEntryCustomerNameSchema,
	customerNameRuby: formMasterCustomerEntryCustomerNameRubySchema,
	customerCategoryId: formMasterCustomerEntryCustomerCategoryIdSchema,
	shipperId: formMasterCustomerEntryShipperIdSchema,
	shipperCode: formMasterCustomerEntryShipperCodeSchema,
	shipperName: formMasterCustomerEntryShipperNameSchema,
	customerPostalCode: formMasterCustomerEntryCustomerPostalCodeSchema,
	customerAddress: formMasterCustomerEntryCustomerAddressSchema,
	customerTel: formMasterCustomerEntryCustomerTelSchema,
	customerFax: formMasterCustomerEntryCustomerFaxSchema,
	customerContactName: formMasterCustomerEntryCustomerContactNameSchema,
	customerNote: formMasterCustomerEntryCustomerNoteSchema,
	countryId: formMasterCustomerEntryCountryIdSchema,
	prefectureId: formMasterCustomerEntryPrefectureIdSchema,
	availableFrom: formMasterCustomerEntryAvailableFromSchema,
	availableTo: formMasterCustomerEntryAvailableToSchema,
}).required();
export type FormMasterCustomerEntrySchemaType = InferType<typeof formMasterCustomerEntrySchema>;
