import { createApiInterfaceMap, GetRequestTypeFromApi, GetResponseTypeFromApi } from './api-interface';
import { accountApi } from './account';
import { batchRunnerApi } from './batches/runner';
import { arrivalApi } from './arrival/arrival';
import { arrivalInspectionApi } from './arrival/arrivalInspection';
import { shippingApi } from './shipping/shipping';
import { shippingInspectionApi } from './shipping/inspection';
import { shippingPackagingInspectionApi } from './shipping/packaging-inspection';
import { singlePickingApi } from './shipping/singlePicking';
import { totalPickingApi } from './shipping/totalPicking';
import { allocationApi } from './shipping/allocation';
import { receivingApi } from './receiving/receiving';
import { receivingInspectionApi } from './receiving/receivingInspection';
import { receivingAndIssuingGroupApi } from './receiving/receivingAndIssuingGroup';
import { issuingApi } from './issuing/issuing';
import { issuingInspectionApi } from './issuing/issuingInspection';
import { freeLocationApi } from './issuing/freeLocation';
import { stockApi } from './stock/list/stock';
import { stockOutApi } from './stock/stock-out/stockOut';
import { setProductApi } from './stock/set-product/setProduct';
import { setProductInspectionApi } from './stock/set-product/setProductInspection';
import { breakSetProductApi } from './stock/break-set-product/breakSetProduct';
import { breakSetProductInspectionApi } from './stock/break-set-product/breakSetProductInspection';
import { stockTakingApi } from './stock/stock-taking/stockTaking';
import { warehouseApi } from './master/warehouse/warehouse';
import { warehouseCategoryApi } from './master/warehouse/warehouseCategory';
import { locationApi } from './master/warehouse/location';
import { locationCategoryApi } from './master/warehouse/locationCategory';
import { locationDetailsApi } from './master/warehouse/locationDetails';
import { aisleApi } from './master/warehouse/aisle';
import { storingConditionApi } from './master/warehouse/storingCondition';
import { clientApi } from './master/client/client';
import { clientCategoryApi } from './master/client/category';
import { clientSenderApi } from './master/client/clientSender';
import { clientTransportMethodApi } from './master/client/tranportMethod';
import { productApi } from './master/product/product';
import { partNumbersApi } from './master/product/partNumbers';
import { partNumberCategoryApi } from './master/product/partNumberCategory';
import { largeCategoryApi } from './master/product/largeCategory';
import { smallCategoryApi } from './master/product/smallCategory';
import { productStatusApi } from './master/product/status';
import { skuApi } from './master/product/sku';
import { skuGroupApi } from './master/product/skuGroup';
import { customerApi } from './master/customer-manage/customer';
import { customerCategoryApi } from './master/customer-manage/customer-category';
import { deliveryDestinationApi } from './master/customer-manage/customer-delivery-destination';
import { assetApi } from './master/asset-manage/asset';
import { assetCategoryApi } from './master/asset-manage/asset-category';
import { packagingCategoryApi } from './master/packaging/category';
import { deliveryCompanyApi } from './master/delivery/company';
import { deliveryCompanyBaseApi } from './master/delivery/companyBase';
import { deliveryCompanyTimeSpanApi } from './master/delivery/deliveryCompanyTimeSpan';
import { shipperApi } from './master/shipper/shipper';
import { shipperContractApi } from './master/shipper/shipperContract';
import { systemUserApi } from './master/user/user';
import { userAuthorityApi } from './master/user/authority';
import { arrivalCategoryApi } from './master/arrival/category/arrivalCategory';
import { shippingCategoryApi } from './master/shipping/category/shippingCategory';
import { receivingIssuingCategoryApi } from './master/receiving-issuing/category/receivingIssuingCategory';
import { searchConditionApi } from './master/search-condition/searchCondition';
import { companyApi } from './system-maintenance/company/company';
import { featuresApi } from './system-maintenance/feature/feature';
import { featuresCategoryApi } from './system-maintenance/feature/featuresCategory';
import { prefectureApi } from './system-maintenance/region/prefectureCode';
import { countryCodeApi } from './system-maintenance/region/countryCode';
import { restockApi } from './stock/restock/restock';

import { receiveIssueLabelApi } from './print/receiveIssueLabel';
import { stockLabelApi } from './print/stockLabel';
import { receiveIssueDetailListApi } from './print/receiveIssueDetail';
import { shippingDetailListApi } from './print/shippingDetail';
import { sagawaShippingLabelApi } from './print/sagawaShippingLabel';
import { sagawaShippingSummaryLabelApi } from './print/sagawaShippingSummaryLabel';
import { stockMovementDetailListApi } from './print/stockMovementDetail';
import { pickingListApi } from './print/pickingList';
import { shippingCsvUploaderApi } from './csv/shipping';
import { arrivalCsvUploaderApi } from './csv/arrival';
import { packagingCsvUploaderApi } from './csv/packaging';

export const rootApi = createApiInterfaceMap({
	...accountApi,
	/** バッチ */
	...batchRunnerApi,
	/** 入荷 */
	...arrivalApi,
	...arrivalInspectionApi,
	/** 出荷 */
	...shippingApi,
	...shippingInspectionApi,
	...shippingPackagingInspectionApi,
	...singlePickingApi,
	...totalPickingApi,
	...allocationApi,

	/** 入庫 */
	...receivingApi,
	...receivingInspectionApi,
	...receivingAndIssuingGroupApi,
	/** 出庫 */
	...issuingApi,
	...issuingInspectionApi,
	...freeLocationApi,
	/** 在庫 */
	...stockApi,
	...stockOutApi,
	...setProductApi,
	...setProductInspectionApi,
	...breakSetProductApi,
	...breakSetProductInspectionApi,
	...stockTakingApi,
	...restockApi,
	/** マスタ */
	// 倉庫
	...warehouseApi,
	...warehouseCategoryApi,
	...locationApi,
	...locationCategoryApi,
	...locationDetailsApi,
	...aisleApi,
	...storingConditionApi,
	// 仕入先
	...clientApi,
	...clientCategoryApi,
	...clientSenderApi,
	...clientTransportMethodApi,
	// 商品
	...productApi,
	...partNumbersApi,
	...partNumberCategoryApi,
	...largeCategoryApi,
	...smallCategoryApi,
	...productStatusApi,
	...skuApi,
	...skuGroupApi,
	// 顧客
	...customerApi,
	...customerCategoryApi,
	...deliveryDestinationApi,
	// 資産
	...assetApi,
	...assetCategoryApi,
	// 梱包
	...packagingCategoryApi,
	// 配送業者
	...deliveryCompanyApi,
	...deliveryCompanyBaseApi,
	...deliveryCompanyTimeSpanApi,
	// 荷主
	...shipperApi,
	...shipperContractApi,
	// ユーザー
	...systemUserApi,
	...userAuthorityApi,
	// 区分
	...arrivalCategoryApi,
	...shippingCategoryApi,
	...receivingIssuingCategoryApi,
	// 検索条件
	...searchConditionApi,
	/** システム保守 */
	// 会社
	...companyApi,
	// 機能
	...featuresApi,
	...featuresCategoryApi,
	// 地域
	...prefectureApi,
	...countryCodeApi,
	/** 帳票印刷 */
	...receiveIssueLabelApi,
	...stockLabelApi,
	...receiveIssueDetailListApi,
	...shippingDetailListApi,
	...sagawaShippingLabelApi,
	...sagawaShippingSummaryLabelApi,
	...stockMovementDetailListApi,
	...pickingListApi,

	/** CSV */
	...shippingCsvUploaderApi,
	...arrivalCsvUploaderApi,
	...packagingCsvUploaderApi,
});

export type RootApi = typeof rootApi;

export type GetApi<Path extends keyof RootApi> = RootApi[Path];
export type ApiPaths = keyof RootApi;
export type GetApiOrNever<Path extends string> = Path extends keyof RootApi ? RootApi[Path] : never;
export type GetResponseType<Path extends keyof RootApi> = GetResponseTypeFromApi<GetApi<Path>>;
export type GetRequestType<Path extends keyof RootApi> = GetRequestTypeFromApi<GetApi<Path>>;
