// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formIssuingIssuingInspectionResultInspectionIdValueSchema = yup.number().optional().integer();
export type FormIssuingIssuingInspectionResultInspectionIdValueSchemaType = InferType<typeof formIssuingIssuingInspectionResultInspectionIdValueSchema>;
export const formIssuingIssuingInspectionResultInspectionIdSchema = yup.array(
	formIssuingIssuingInspectionResultInspectionIdValueSchema
).optional();
export type FormIssuingIssuingInspectionResultInspectionIdSchemaType = InferType<typeof formIssuingIssuingInspectionResultInspectionIdSchema>;
export const formIssuingIssuingInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormIssuingIssuingInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formIssuingIssuingInspectionResultInspectionQuantityValueSchema>;
export const formIssuingIssuingInspectionResultInspectionQuantitySchema = yup.array(
	formIssuingIssuingInspectionResultInspectionQuantityValueSchema
).optional();
export type FormIssuingIssuingInspectionResultInspectionQuantitySchemaType = InferType<typeof formIssuingIssuingInspectionResultInspectionQuantitySchema>;
export const formIssuingIssuingInspectionResultSchema = yup.object({
	inspectionId: formIssuingIssuingInspectionResultInspectionIdSchema,
	inspectionQuantity: formIssuingIssuingInspectionResultInspectionQuantitySchema,
}).required();
export type FormIssuingIssuingInspectionResultSchemaType = InferType<typeof formIssuingIssuingInspectionResultSchema>;
