// fv-generatorで生成されました
import * as yup from "yup";
import { typeSystemMaintenanceFeatureCategoryCodeSchema, typeSystemMaintenanceFeatureCategoryNameSchema, typeSystemMaintenanceFeatureCategoryTerminalTypeSchema, typeSystemMaintenanceFeatureCategoryForDeveloperSchema, typeSystemMaintenanceFeatureCodeSchema, typeSystemMaintenanceFeatureNameSchema, typeSystemMaintenanceFeatureCategoryId1Schema, typeSystemMaintenanceFeatureCategoryId2Schema, typeSystemMaintenanceFeatureCategoryId3Schema } from "./../../systemMaintenance";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formSystemMaintenanceFeatureCategoryListFeatureCategoryCodeSchema = typeSystemMaintenanceFeatureCategoryCodeSchema.optional();
export type FormSystemMaintenanceFeatureCategoryListFeatureCategoryCodeSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListFeatureCategoryCodeSchema>;
export const formSystemMaintenanceFeatureCategoryListFeatureCategoryNameSchema = typeSystemMaintenanceFeatureCategoryNameSchema.optional();
export type FormSystemMaintenanceFeatureCategoryListFeatureCategoryNameSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListFeatureCategoryNameSchema>;
export const formSystemMaintenanceFeatureCategoryListTerminalTypeSchema = typeSystemMaintenanceFeatureCategoryTerminalTypeSchema.optional();
export type FormSystemMaintenanceFeatureCategoryListTerminalTypeSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListTerminalTypeSchema>;
export const formSystemMaintenanceFeatureCategoryListForDeveloperSchema = typeSystemMaintenanceFeatureCategoryForDeveloperSchema;
export type FormSystemMaintenanceFeatureCategoryListForDeveloperSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListForDeveloperSchema>;
export const formSystemMaintenanceFeatureCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormSystemMaintenanceFeatureCategoryListIsAvailableSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListIsAvailableSchema>;
export const formSystemMaintenanceFeatureCategoryListSchema = yup.object({
	featureCategoryCode: formSystemMaintenanceFeatureCategoryListFeatureCategoryCodeSchema,
	featureCategoryName: formSystemMaintenanceFeatureCategoryListFeatureCategoryNameSchema,
	terminalType: formSystemMaintenanceFeatureCategoryListTerminalTypeSchema,
	forDeveloper: formSystemMaintenanceFeatureCategoryListForDeveloperSchema,
	isAvailable: formSystemMaintenanceFeatureCategoryListIsAvailableSchema,
}).required();
export type FormSystemMaintenanceFeatureCategoryListSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryListSchema>;
export const formSystemMaintenanceFeatureCategoryEntryFeatureCategoryCodeSchema = typeSystemMaintenanceFeatureCategoryCodeSchema;
export type FormSystemMaintenanceFeatureCategoryEntryFeatureCategoryCodeSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryFeatureCategoryCodeSchema>;
export const formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNameSchema = typeSystemMaintenanceFeatureCategoryNameSchema;
export type FormSystemMaintenanceFeatureCategoryEntryFeatureCategoryNameSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNameSchema>;
export const formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNoteSchema = typeCommonNoteSchema;
export type FormSystemMaintenanceFeatureCategoryEntryFeatureCategoryNoteSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNoteSchema>;
export const formSystemMaintenanceFeatureCategoryEntryTerminalTypeSchema = typeSystemMaintenanceFeatureCategoryTerminalTypeSchema;
export type FormSystemMaintenanceFeatureCategoryEntryTerminalTypeSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryTerminalTypeSchema>;
export const formSystemMaintenanceFeatureCategoryEntryForDeveloperSchema = typeSystemMaintenanceFeatureCategoryForDeveloperSchema;
export type FormSystemMaintenanceFeatureCategoryEntryForDeveloperSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryForDeveloperSchema>;
export const formSystemMaintenanceFeatureCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormSystemMaintenanceFeatureCategoryEntryAvailableFromSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryAvailableFromSchema>;
export const formSystemMaintenanceFeatureCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormSystemMaintenanceFeatureCategoryEntryAvailableToSchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntryAvailableToSchema>;
export const formSystemMaintenanceFeatureCategoryEntrySchema = yup.object({
	featureCategoryCode: formSystemMaintenanceFeatureCategoryEntryFeatureCategoryCodeSchema,
	featureCategoryName: formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNameSchema,
	featureCategoryNote: formSystemMaintenanceFeatureCategoryEntryFeatureCategoryNoteSchema,
	terminalType: formSystemMaintenanceFeatureCategoryEntryTerminalTypeSchema,
	forDeveloper: formSystemMaintenanceFeatureCategoryEntryForDeveloperSchema,
	availableFrom: formSystemMaintenanceFeatureCategoryEntryAvailableFromSchema,
	availableTo: formSystemMaintenanceFeatureCategoryEntryAvailableToSchema,
}).required();
export type FormSystemMaintenanceFeatureCategoryEntrySchemaType = InferType<typeof formSystemMaintenanceFeatureCategoryEntrySchema>;
export const formSystemMaintenanceFeatureListFeatureCodeSchema = typeSystemMaintenanceFeatureCodeSchema.optional();
export type FormSystemMaintenanceFeatureListFeatureCodeSchemaType = InferType<typeof formSystemMaintenanceFeatureListFeatureCodeSchema>;
export const formSystemMaintenanceFeatureListFeatureNameSchema = typeSystemMaintenanceFeatureNameSchema.optional();
export type FormSystemMaintenanceFeatureListFeatureNameSchemaType = InferType<typeof formSystemMaintenanceFeatureListFeatureNameSchema>;
export const formSystemMaintenanceFeatureListFeatureCategoryId1Schema = typeSystemMaintenanceFeatureCategoryId1Schema.optional();
export type FormSystemMaintenanceFeatureListFeatureCategoryId1SchemaType = InferType<typeof formSystemMaintenanceFeatureListFeatureCategoryId1Schema>;
export const formSystemMaintenanceFeatureListFeatureCategoryId2Schema = typeSystemMaintenanceFeatureCategoryId2Schema.optional();
export type FormSystemMaintenanceFeatureListFeatureCategoryId2SchemaType = InferType<typeof formSystemMaintenanceFeatureListFeatureCategoryId2Schema>;
export const formSystemMaintenanceFeatureListFeatureCategoryId3Schema = typeSystemMaintenanceFeatureCategoryId3Schema.optional();
export type FormSystemMaintenanceFeatureListFeatureCategoryId3SchemaType = InferType<typeof formSystemMaintenanceFeatureListFeatureCategoryId3Schema>;
export const formSystemMaintenanceFeatureListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormSystemMaintenanceFeatureListIsAvailableSchemaType = InferType<typeof formSystemMaintenanceFeatureListIsAvailableSchema>;
export const formSystemMaintenanceFeatureListSchema = yup.object({
	featureCode: formSystemMaintenanceFeatureListFeatureCodeSchema,
	featureName: formSystemMaintenanceFeatureListFeatureNameSchema,
	featureCategoryId1: formSystemMaintenanceFeatureListFeatureCategoryId1Schema,
	featureCategoryId2: formSystemMaintenanceFeatureListFeatureCategoryId2Schema,
	featureCategoryId3: formSystemMaintenanceFeatureListFeatureCategoryId3Schema,
	isAvailable: formSystemMaintenanceFeatureListIsAvailableSchema,
}).required();
export type FormSystemMaintenanceFeatureListSchemaType = InferType<typeof formSystemMaintenanceFeatureListSchema>;
export const formSystemMaintenanceFeatureEntryFeatureCodeSchema = typeSystemMaintenanceFeatureCodeSchema;
export type FormSystemMaintenanceFeatureEntryFeatureCodeSchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureCodeSchema>;
export const formSystemMaintenanceFeatureEntryFeatureNameSchema = typeSystemMaintenanceFeatureNameSchema;
export type FormSystemMaintenanceFeatureEntryFeatureNameSchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureNameSchema>;
export const formSystemMaintenanceFeatureEntryFeatureNoteSchema = typeCommonNoteSchema;
export type FormSystemMaintenanceFeatureEntryFeatureNoteSchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureNoteSchema>;
export const formSystemMaintenanceFeatureEntryFeatureCategoryId1Schema = typeSystemMaintenanceFeatureCategoryId1Schema;
export type FormSystemMaintenanceFeatureEntryFeatureCategoryId1SchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureCategoryId1Schema>;
export const formSystemMaintenanceFeatureEntryFeatureCategoryId2Schema = typeSystemMaintenanceFeatureCategoryId2Schema.optional();
export type FormSystemMaintenanceFeatureEntryFeatureCategoryId2SchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureCategoryId2Schema>;
export const formSystemMaintenanceFeatureEntryFeatureCategoryId3Schema = typeSystemMaintenanceFeatureCategoryId3Schema.optional();
export type FormSystemMaintenanceFeatureEntryFeatureCategoryId3SchemaType = InferType<typeof formSystemMaintenanceFeatureEntryFeatureCategoryId3Schema>;
export const formSystemMaintenanceFeatureEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormSystemMaintenanceFeatureEntryAvailableFromSchemaType = InferType<typeof formSystemMaintenanceFeatureEntryAvailableFromSchema>;
export const formSystemMaintenanceFeatureEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormSystemMaintenanceFeatureEntryAvailableToSchemaType = InferType<typeof formSystemMaintenanceFeatureEntryAvailableToSchema>;
export const formSystemMaintenanceFeatureEntrySchema = yup.object({
	featureCode: formSystemMaintenanceFeatureEntryFeatureCodeSchema,
	featureName: formSystemMaintenanceFeatureEntryFeatureNameSchema,
	featureNote: formSystemMaintenanceFeatureEntryFeatureNoteSchema,
	featureCategoryId1: formSystemMaintenanceFeatureEntryFeatureCategoryId1Schema,
	featureCategoryId2: formSystemMaintenanceFeatureEntryFeatureCategoryId2Schema,
	featureCategoryId3: formSystemMaintenanceFeatureEntryFeatureCategoryId3Schema,
	availableFrom: formSystemMaintenanceFeatureEntryAvailableFromSchema,
	availableTo: formSystemMaintenanceFeatureEntryAvailableToSchema,
}).required();
export type FormSystemMaintenanceFeatureEntrySchemaType = InferType<typeof formSystemMaintenanceFeatureEntrySchema>;
