// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingScheduledDateSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingPickingCreateShippingScheduledDateSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingPickingCreateShippingScheduledDateSchemaType = InferType<typeof formShippingShippingPickingCreateShippingScheduledDateSchema>;
export const formShippingShippingPickingCreateSchema = yup.object({
	shippingScheduledDate: formShippingShippingPickingCreateShippingScheduledDateSchema,
}).required();
export type FormShippingShippingPickingCreateSchemaType = InferType<typeof formShippingShippingPickingCreateSchema>;
