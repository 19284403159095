import { object, string } from "yup";
import { createApiInterface, createApiInterfaceMap } from "../api-interface";
import { ProcessLog } from "./process";

const uploadApi = createApiInterface()
	.request(
		object({
			file: string().required(),
		})
	)
	.response<{
		status: number;
		// ※暫定
		// 1 => 成功
		// 2 => 失敗
	}>();

const statusApi = createApiInterface()
	.request(
		object()
	)
	.response<{
		list: ProcessLog[];
	}>();

export const arrivalCsvUploaderApi = createApiInterfaceMap({
	['/v1/csv/arrival/upload']: uploadApi,
	['/v1/csv/arrival/status']: statusApi,
});