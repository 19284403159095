// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterProductSkuGroupCodeSchema, typeMasterProductSkuGroupNameSchema, typeMasterProductSkuIdSchema, typeMasterWarehouseStoringConditionIdSchema } from "./../../master";
import { InferType } from "yup";

export const formStockSetProductListInputSkuGroupCodeSchema = typeMasterProductSkuGroupCodeSchema.optional();
export type FormStockSetProductListInputSkuGroupCodeSchemaType = InferType<typeof formStockSetProductListInputSkuGroupCodeSchema>;
export const formStockSetProductListInputSkuGroupNameSchema = typeMasterProductSkuGroupNameSchema.optional();
export type FormStockSetProductListInputSkuGroupNameSchemaType = InferType<typeof formStockSetProductListInputSkuGroupNameSchema>;
export const formStockSetProductListInputSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormStockSetProductListInputSkuIdSchemaType = InferType<typeof formStockSetProductListInputSkuIdSchema>;
export const formStockSetProductListInputStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormStockSetProductListInputStoringConditionIdSchemaType = InferType<typeof formStockSetProductListInputStoringConditionIdSchema>;
export const formStockSetProductListInputSchema = yup.object({
	skuGroupCode: formStockSetProductListInputSkuGroupCodeSchema,
	skuGroupName: formStockSetProductListInputSkuGroupNameSchema,
	skuId: formStockSetProductListInputSkuIdSchema,
	storingConditionId: formStockSetProductListInputStoringConditionIdSchema,
}).required();
export type FormStockSetProductListInputSchemaType = InferType<typeof formStockSetProductListInputSchema>;
