// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterUserAuthorityCodeSchema, typeMasterUserAuthorityNameSchema, typeMasterUserCodeSchema, typeMasterUserNameSchema, typeMasterUserPasswordSchema, typeMasterUserAuthorityIdSchema } from "./../../master";
import { InferType } from "yup";
import { typeSystemMaintenanceFeatureIdSchema, typeSystemMaintenanceCompanyCodeSchema, typeSystemMaintenanceCompanyNameSchema } from "./../../systemMaintenance";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";

export const formMasterUserAuthorityListAuthorityCodeSchema = typeMasterUserAuthorityCodeSchema.optional();
export type FormMasterUserAuthorityListAuthorityCodeSchemaType = InferType<typeof formMasterUserAuthorityListAuthorityCodeSchema>;
export const formMasterUserAuthorityListAuthorityNameSchema = typeMasterUserAuthorityNameSchema.optional();
export type FormMasterUserAuthorityListAuthorityNameSchemaType = InferType<typeof formMasterUserAuthorityListAuthorityNameSchema>;
export const formMasterUserAuthorityListFeatureIdSchema = typeSystemMaintenanceFeatureIdSchema.optional();
export type FormMasterUserAuthorityListFeatureIdSchemaType = InferType<typeof formMasterUserAuthorityListFeatureIdSchema>;
export const formMasterUserAuthorityListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterUserAuthorityListIsAvailableSchemaType = InferType<typeof formMasterUserAuthorityListIsAvailableSchema>;
export const formMasterUserAuthorityListSchema = yup.object({
	authorityCode: formMasterUserAuthorityListAuthorityCodeSchema,
	authorityName: formMasterUserAuthorityListAuthorityNameSchema,
	featureId: formMasterUserAuthorityListFeatureIdSchema,
	isAvailable: formMasterUserAuthorityListIsAvailableSchema,
}).required();
export type FormMasterUserAuthorityListSchemaType = InferType<typeof formMasterUserAuthorityListSchema>;
export const formMasterUserAuthorityEntryAuthorityCodeSchema = typeMasterUserAuthorityCodeSchema;
export type FormMasterUserAuthorityEntryAuthorityCodeSchemaType = InferType<typeof formMasterUserAuthorityEntryAuthorityCodeSchema>;
export const formMasterUserAuthorityEntryAuthorityNameSchema = typeMasterUserAuthorityNameSchema;
export type FormMasterUserAuthorityEntryAuthorityNameSchemaType = InferType<typeof formMasterUserAuthorityEntryAuthorityNameSchema>;
export const formMasterUserAuthorityEntryFeatureIdValueSchema = typeSystemMaintenanceFeatureIdSchema;
export type FormMasterUserAuthorityEntryFeatureIdValueSchemaType = InferType<typeof formMasterUserAuthorityEntryFeatureIdValueSchema>;
export const formMasterUserAuthorityEntryFeatureIdSchema = yup.array(
	formMasterUserAuthorityEntryFeatureIdValueSchema
).required();
export type FormMasterUserAuthorityEntryFeatureIdSchemaType = InferType<typeof formMasterUserAuthorityEntryFeatureIdSchema>;
export const formMasterUserAuthorityEntryAuthorityNoteSchema = typeCommonNoteSchema.optional();
export type FormMasterUserAuthorityEntryAuthorityNoteSchemaType = InferType<typeof formMasterUserAuthorityEntryAuthorityNoteSchema>;
export const formMasterUserAuthorityEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterUserAuthorityEntryAvailableFromSchemaType = InferType<typeof formMasterUserAuthorityEntryAvailableFromSchema>;
export const formMasterUserAuthorityEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterUserAuthorityEntryAvailableToSchemaType = InferType<typeof formMasterUserAuthorityEntryAvailableToSchema>;
export const formMasterUserAuthorityEntrySchema = yup.object({
	authorityCode: formMasterUserAuthorityEntryAuthorityCodeSchema,
	authorityName: formMasterUserAuthorityEntryAuthorityNameSchema,
	featureId: formMasterUserAuthorityEntryFeatureIdSchema,
	authorityNote: formMasterUserAuthorityEntryAuthorityNoteSchema,
	availableFrom: formMasterUserAuthorityEntryAvailableFromSchema,
	availableTo: formMasterUserAuthorityEntryAvailableToSchema,
}).required();
export type FormMasterUserAuthorityEntrySchemaType = InferType<typeof formMasterUserAuthorityEntrySchema>;
export const formMasterUserListCompanyCodeSchema = typeSystemMaintenanceCompanyCodeSchema.optional();
export type FormMasterUserListCompanyCodeSchemaType = InferType<typeof formMasterUserListCompanyCodeSchema>;
export const formMasterUserListCompanyNameSchema = typeSystemMaintenanceCompanyNameSchema.optional();
export type FormMasterUserListCompanyNameSchemaType = InferType<typeof formMasterUserListCompanyNameSchema>;
export const formMasterUserListUserCodeSchema = typeMasterUserCodeSchema.optional();
export type FormMasterUserListUserCodeSchemaType = InferType<typeof formMasterUserListUserCodeSchema>;
export const formMasterUserListUserNameSchema = typeMasterUserNameSchema.optional();
export type FormMasterUserListUserNameSchemaType = InferType<typeof formMasterUserListUserNameSchema>;
export const formMasterUserListAuthorityCodeSchema = typeMasterUserAuthorityCodeSchema.optional();
export type FormMasterUserListAuthorityCodeSchemaType = InferType<typeof formMasterUserListAuthorityCodeSchema>;
export const formMasterUserListAuthorityNameSchema = typeMasterUserAuthorityNameSchema.optional();
export type FormMasterUserListAuthorityNameSchemaType = InferType<typeof formMasterUserListAuthorityNameSchema>;
export const formMasterUserListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterUserListIsAvailableSchemaType = InferType<typeof formMasterUserListIsAvailableSchema>;
export const formMasterUserListSchema = yup.object({
	companyCode: formMasterUserListCompanyCodeSchema,
	companyName: formMasterUserListCompanyNameSchema,
	userCode: formMasterUserListUserCodeSchema,
	userName: formMasterUserListUserNameSchema,
	authorityCode: formMasterUserListAuthorityCodeSchema,
	authorityName: formMasterUserListAuthorityNameSchema,
	isAvailable: formMasterUserListIsAvailableSchema,
}).required();
export type FormMasterUserListSchemaType = InferType<typeof formMasterUserListSchema>;
export const formMasterUserEntryUserCodeSchema = typeMasterUserCodeSchema;
export type FormMasterUserEntryUserCodeSchemaType = InferType<typeof formMasterUserEntryUserCodeSchema>;
export const formMasterUserEntryUserNameSchema = typeMasterUserNameSchema;
export type FormMasterUserEntryUserNameSchemaType = InferType<typeof formMasterUserEntryUserNameSchema>;
export const formMasterUserEntryUserPasswordSchema = typeMasterUserPasswordSchema;
export type FormMasterUserEntryUserPasswordSchemaType = InferType<typeof formMasterUserEntryUserPasswordSchema>;
export const formMasterUserEntryAuthorityIdSchema = typeMasterUserAuthorityIdSchema;
export type FormMasterUserEntryAuthorityIdSchemaType = InferType<typeof formMasterUserEntryAuthorityIdSchema>;
export const formMasterUserEntryUserNoteSchema = typeCommonNoteSchema;
export type FormMasterUserEntryUserNoteSchemaType = InferType<typeof formMasterUserEntryUserNoteSchema>;
export const formMasterUserEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterUserEntryAvailableFromSchemaType = InferType<typeof formMasterUserEntryAvailableFromSchema>;
export const formMasterUserEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterUserEntryAvailableToSchemaType = InferType<typeof formMasterUserEntryAvailableToSchema>;
export const formMasterUserEntrySchema = yup.object({
	userCode: formMasterUserEntryUserCodeSchema,
	userName: formMasterUserEntryUserNameSchema,
	userPassword: formMasterUserEntryUserPasswordSchema,
	authorityId: formMasterUserEntryAuthorityIdSchema,
	userNote: formMasterUserEntryUserNoteSchema,
	availableFrom: formMasterUserEntryAvailableFromSchema,
	availableTo: formMasterUserEntryAvailableToSchema,
}).required();
export type FormMasterUserEntrySchemaType = InferType<typeof formMasterUserEntrySchema>;
export const formMasterUserEntryEditUserCodeSchema = typeMasterUserCodeSchema;
export type FormMasterUserEntryEditUserCodeSchemaType = InferType<typeof formMasterUserEntryEditUserCodeSchema>;
export const formMasterUserEntryEditUserNameSchema = typeMasterUserNameSchema;
export type FormMasterUserEntryEditUserNameSchemaType = InferType<typeof formMasterUserEntryEditUserNameSchema>;
export const formMasterUserEntryEditAuthorityIdSchema = typeMasterUserAuthorityIdSchema;
export type FormMasterUserEntryEditAuthorityIdSchemaType = InferType<typeof formMasterUserEntryEditAuthorityIdSchema>;
export const formMasterUserEntryEditUserNoteSchema = typeCommonNoteSchema;
export type FormMasterUserEntryEditUserNoteSchemaType = InferType<typeof formMasterUserEntryEditUserNoteSchema>;
export const formMasterUserEntryEditAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterUserEntryEditAvailableFromSchemaType = InferType<typeof formMasterUserEntryEditAvailableFromSchema>;
export const formMasterUserEntryEditAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterUserEntryEditAvailableToSchemaType = InferType<typeof formMasterUserEntryEditAvailableToSchema>;
export const formMasterUserEntryEditSchema = yup.object({
	userCode: formMasterUserEntryEditUserCodeSchema,
	userName: formMasterUserEntryEditUserNameSchema,
	authorityId: formMasterUserEntryEditAuthorityIdSchema,
	userNote: formMasterUserEntryEditUserNoteSchema,
	availableFrom: formMasterUserEntryEditAvailableFromSchema,
	availableTo: formMasterUserEntryEditAvailableToSchema,
}).required();
export type FormMasterUserEntryEditSchemaType = InferType<typeof formMasterUserEntryEditSchema>;
