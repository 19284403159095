// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formStockStockListInputSearchCategorySchema = yup.string().required().label("schema.common.search.label.target");
export type FormStockStockListInputSearchCategorySchemaType = InferType<typeof formStockStockListInputSearchCategorySchema>;
export const formStockStockListInputSchema = yup.object({
	searchCategory: formStockStockListInputSearchCategorySchema,
}).required();
export type FormStockStockListInputSchemaType = InferType<typeof formStockStockListInputSchema>;
