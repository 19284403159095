// fv-generatorで生成されました
import * as yup from "yup";
import { typeArrivalDateSchema } from "./../../arrival";
import { InferType } from "yup";

export const formArrivalArrivalInspectionEntryInspectionDateSchema = typeArrivalDateSchema;
export type FormArrivalArrivalInspectionEntryInspectionDateSchemaType = InferType<typeof formArrivalArrivalInspectionEntryInspectionDateSchema>;
export const formArrivalArrivalInspectionEntrySchema = yup.object({
	inspectionDate: formArrivalArrivalInspectionEntryInspectionDateSchema,
}).required();
export type FormArrivalArrivalInspectionEntrySchemaType = InferType<typeof formArrivalArrivalInspectionEntrySchema>;
