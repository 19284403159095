// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const typeCommonPostalCodeSchema = yup.string().optional().label("schema.common.label.postal.code");
export type TypeCommonPostalCodeSchemaType = InferType<typeof typeCommonPostalCodeSchema>;
export const typeCommonAddressSchema = yup.string().required().label("schema.common.label.address");
export type TypeCommonAddressSchemaType = InferType<typeof typeCommonAddressSchema>;
export const typeCommonTelSchema = yup.string().optional().label("schema.common.label.tel");
export type TypeCommonTelSchemaType = InferType<typeof typeCommonTelSchema>;
export const typeCommonFaxSchema = yup.string().optional().label("schema.common.label.fax");
export type TypeCommonFaxSchemaType = InferType<typeof typeCommonFaxSchema>;
export const typeCommonEmailSchema = yup.string().optional().label("schema.common.label.email").email();
export type TypeCommonEmailSchemaType = InferType<typeof typeCommonEmailSchema>;
export const typeCommonAvailableFromSchema = yup.date().required().label("schema.common.label.available.from");
export type TypeCommonAvailableFromSchemaType = InferType<typeof typeCommonAvailableFromSchema>;
export const typeCommonAvailableToSchema = yup.date().required().label("schema.common.label.available.to");
export type TypeCommonAvailableToSchemaType = InferType<typeof typeCommonAvailableToSchema>;
export const typeCommonIsAvailableSchema = yup.boolean().required().label("schema.common.label.isAvailable");
export type TypeCommonIsAvailableSchemaType = InferType<typeof typeCommonIsAvailableSchema>;
export const typeCommonNoteSchema = yup.string().optional().label("schema.common.label.note");
export type TypeCommonNoteSchemaType = InferType<typeof typeCommonNoteSchema>;
export const typeCommonDisplayOrderSchema = yup.number().optional().label("schema.common.label.display.order").integer();
export type TypeCommonDisplayOrderSchemaType = InferType<typeof typeCommonDisplayOrderSchema>;
export const typeCommonQuantitySchema = yup.number().required().label("schema.common.label.quantity").integer();
export type TypeCommonQuantitySchemaType = InferType<typeof typeCommonQuantitySchema>;
export const typeCommonInspectionNumberSchema = yup.string().required().label("schema.common.inspection.label.number");
export type TypeCommonInspectionNumberSchemaType = InferType<typeof typeCommonInspectionNumberSchema>;
export const typeCommonInspectionQuantitySchema = yup.number().required().label("schema.common.inspection.label.quantity").integer();
export type TypeCommonInspectionQuantitySchemaType = InferType<typeof typeCommonInspectionQuantitySchema>;
