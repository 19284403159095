// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterWarehouseAisleCodeSchema, typeMasterWarehouseAisleNameSchema, typeMasterWarehouseIdSchema, typeMasterWarehouseCodeSchema, typeMasterWarehouseNameSchema, typeMasterWarehouseCategoryCodeSchema, typeMasterWarehouseCategoryNameSchema, typeMasterWarehouseLocationCategoryCodeSchema, typeMasterWarehouseLocationCategoryNameSchema, typeMasterWarehouseLocationCategoryAllocationPriorityFlagSchema, typeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchema, typeMasterWarehouseLocationCategoryMovementProhibitedFlagSchema, typeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchema, typeMasterWarehouseLocationCategoryTotalPickingFlagSchema, typeMasterWarehouseLocationDetailCodeSchema, typeMasterWarehouseLocationDetailNameSchema, typeMasterWarehouseLocationIdSchema, typeMasterWarehouseLocationNameSchema, typeMasterWarehouseLocationCodeSchema, typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterWarehouseAisleIdSchema, typeMasterWarehouseLocationDetailAllocationPrioritySchema, typeMasterWarehouseLocationDetailMixedLocationFlagSchema, typeMasterWarehouseLocationDetailDepthMSchema, typeMasterWarehouseLocationDetailWidthMSchema, typeMasterWarehouseLocationDetailHeightMSchema, typeMasterWarehouseLocationCategoryIdSchema, typeMasterWarehouseStoringConditionIdSchema, typeMasterWarehouseStoringConditionCodeSchema, typeMasterWarehouseStoringConditionNameSchema, typeMasterWarehouseStoringConditionDifferenceFlagSchema, typeMasterWarehouseCategoryIdSchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema, typeCommonPostalCodeSchema, typeCommonAddressSchema, typeCommonTelSchema, typeCommonFaxSchema } from "./../../common";
import { typeSearchItemsSchema } from "./../../search";
import { typeDetailedConditionItemsSchema } from "./../../detailedCondition";

export const formMasterWarehouseAisleListAisleCodeSchema = typeMasterWarehouseAisleCodeSchema.optional();
export type FormMasterWarehouseAisleListAisleCodeSchemaType = InferType<typeof formMasterWarehouseAisleListAisleCodeSchema>;
export const formMasterWarehouseAisleListAisleNameSchema = typeMasterWarehouseAisleNameSchema.optional();
export type FormMasterWarehouseAisleListAisleNameSchemaType = InferType<typeof formMasterWarehouseAisleListAisleNameSchema>;
export const formMasterWarehouseAisleListWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormMasterWarehouseAisleListWarehouseIdSchemaType = InferType<typeof formMasterWarehouseAisleListWarehouseIdSchema>;
export const formMasterWarehouseAisleListWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormMasterWarehouseAisleListWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseAisleListWarehouseCodeSchema>;
export const formMasterWarehouseAisleListWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormMasterWarehouseAisleListWarehouseNameSchemaType = InferType<typeof formMasterWarehouseAisleListWarehouseNameSchema>;
export const formMasterWarehouseAisleListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseAisleListIsAvailableSchemaType = InferType<typeof formMasterWarehouseAisleListIsAvailableSchema>;
export const formMasterWarehouseAisleListSchema = yup.object({
	aisleCode: formMasterWarehouseAisleListAisleCodeSchema,
	aisleName: formMasterWarehouseAisleListAisleNameSchema,
	warehouseId: formMasterWarehouseAisleListWarehouseIdSchema,
	warehouseCode: formMasterWarehouseAisleListWarehouseCodeSchema,
	warehouseName: formMasterWarehouseAisleListWarehouseNameSchema,
	isAvailable: formMasterWarehouseAisleListIsAvailableSchema,
}).required();
export type FormMasterWarehouseAisleListSchemaType = InferType<typeof formMasterWarehouseAisleListSchema>;
export const formMasterWarehouseAisleEntryAisleCodeSchema = typeMasterWarehouseAisleCodeSchema;
export type FormMasterWarehouseAisleEntryAisleCodeSchemaType = InferType<typeof formMasterWarehouseAisleEntryAisleCodeSchema>;
export const formMasterWarehouseAisleEntryAisleNameSchema = typeMasterWarehouseAisleNameSchema;
export type FormMasterWarehouseAisleEntryAisleNameSchemaType = InferType<typeof formMasterWarehouseAisleEntryAisleNameSchema>;
export const formMasterWarehouseAisleEntryAisleNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseAisleEntryAisleNoteSchemaType = InferType<typeof formMasterWarehouseAisleEntryAisleNoteSchema>;
export const formMasterWarehouseAisleEntryWarehouseIdSchema = typeMasterWarehouseIdSchema;
export type FormMasterWarehouseAisleEntryWarehouseIdSchemaType = InferType<typeof formMasterWarehouseAisleEntryWarehouseIdSchema>;
export const formMasterWarehouseAisleEntryWarehouseCodeSchema = typeMasterWarehouseCodeSchema;
export type FormMasterWarehouseAisleEntryWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseAisleEntryWarehouseCodeSchema>;
export const formMasterWarehouseAisleEntryWarehouseNameSchema = typeMasterWarehouseNameSchema;
export type FormMasterWarehouseAisleEntryWarehouseNameSchemaType = InferType<typeof formMasterWarehouseAisleEntryWarehouseNameSchema>;
export const formMasterWarehouseAisleEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseAisleEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseAisleEntryAvailableFromSchema>;
export const formMasterWarehouseAisleEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseAisleEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseAisleEntryAvailableToSchema>;
export const formMasterWarehouseAisleEntrySchema = yup.object({
	aisleCode: formMasterWarehouseAisleEntryAisleCodeSchema,
	aisleName: formMasterWarehouseAisleEntryAisleNameSchema,
	aisleNote: formMasterWarehouseAisleEntryAisleNoteSchema,
	warehouseId: formMasterWarehouseAisleEntryWarehouseIdSchema,
	warehouseCode: formMasterWarehouseAisleEntryWarehouseCodeSchema,
	warehouseName: formMasterWarehouseAisleEntryWarehouseNameSchema,
	availableFrom: formMasterWarehouseAisleEntryAvailableFromSchema,
	availableTo: formMasterWarehouseAisleEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseAisleEntrySchemaType = InferType<typeof formMasterWarehouseAisleEntrySchema>;
export const formMasterWarehouseCategoryListWarehouseCategoryCodeSchema = typeMasterWarehouseCategoryCodeSchema.optional();
export type FormMasterWarehouseCategoryListWarehouseCategoryCodeSchemaType = InferType<typeof formMasterWarehouseCategoryListWarehouseCategoryCodeSchema>;
export const formMasterWarehouseCategoryListWarehouseCategoryNameSchema = typeMasterWarehouseCategoryNameSchema.optional();
export type FormMasterWarehouseCategoryListWarehouseCategoryNameSchemaType = InferType<typeof formMasterWarehouseCategoryListWarehouseCategoryNameSchema>;
export const formMasterWarehouseCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseCategoryListIsAvailableSchemaType = InferType<typeof formMasterWarehouseCategoryListIsAvailableSchema>;
export const formMasterWarehouseCategoryListSchema = yup.object({
	warehouseCategoryCode: formMasterWarehouseCategoryListWarehouseCategoryCodeSchema,
	warehouseCategoryName: formMasterWarehouseCategoryListWarehouseCategoryNameSchema,
	isAvailable: formMasterWarehouseCategoryListIsAvailableSchema,
}).required();
export type FormMasterWarehouseCategoryListSchemaType = InferType<typeof formMasterWarehouseCategoryListSchema>;
export const formMasterWarehouseCategoryEntryWarehouseCategoryCodeSchema = typeMasterWarehouseCategoryCodeSchema;
export type FormMasterWarehouseCategoryEntryWarehouseCategoryCodeSchemaType = InferType<typeof formMasterWarehouseCategoryEntryWarehouseCategoryCodeSchema>;
export const formMasterWarehouseCategoryEntryWarehouseCategoryNameSchema = typeMasterWarehouseCategoryNameSchema;
export type FormMasterWarehouseCategoryEntryWarehouseCategoryNameSchemaType = InferType<typeof formMasterWarehouseCategoryEntryWarehouseCategoryNameSchema>;
export const formMasterWarehouseCategoryEntryWarehouseCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseCategoryEntryWarehouseCategoryNoteSchemaType = InferType<typeof formMasterWarehouseCategoryEntryWarehouseCategoryNoteSchema>;
export const formMasterWarehouseCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseCategoryEntryAvailableFromSchema>;
export const formMasterWarehouseCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseCategoryEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseCategoryEntryAvailableToSchema>;
export const formMasterWarehouseCategoryEntrySchema = yup.object({
	warehouseCategoryCode: formMasterWarehouseCategoryEntryWarehouseCategoryCodeSchema,
	warehouseCategoryName: formMasterWarehouseCategoryEntryWarehouseCategoryNameSchema,
	warehouseCategoryNote: formMasterWarehouseCategoryEntryWarehouseCategoryNoteSchema,
	availableFrom: formMasterWarehouseCategoryEntryAvailableFromSchema,
	availableTo: formMasterWarehouseCategoryEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseCategoryEntrySchemaType = InferType<typeof formMasterWarehouseCategoryEntrySchema>;
export const formMasterWarehouseLocationCategoryListLocationCategoryCodeSchema = typeMasterWarehouseLocationCategoryCodeSchema.optional();
export type FormMasterWarehouseLocationCategoryListLocationCategoryCodeSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListLocationCategoryCodeSchema>;
export const formMasterWarehouseLocationCategoryListLocationCategoryNameSchema = typeMasterWarehouseLocationCategoryNameSchema.optional();
export type FormMasterWarehouseLocationCategoryListLocationCategoryNameSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListLocationCategoryNameSchema>;
export const formMasterWarehouseLocationCategoryListAllocationPriorityFlagSchema = typeMasterWarehouseLocationCategoryAllocationPriorityFlagSchema.optional();
export type FormMasterWarehouseLocationCategoryListAllocationPriorityFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListAllocationPriorityFlagSchema>;
export const formMasterWarehouseLocationCategoryListAllocationImpossibleFlagSchema = typeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchema.optional();
export type FormMasterWarehouseLocationCategoryListAllocationImpossibleFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListAllocationImpossibleFlagSchema>;
export const formMasterWarehouseLocationCategoryListMovementProhibitedFlagSchema = typeMasterWarehouseLocationCategoryMovementProhibitedFlagSchema.optional();
export type FormMasterWarehouseLocationCategoryListMovementProhibitedFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListMovementProhibitedFlagSchema>;
export const formMasterWarehouseLocationCategoryListAutomaticAllocationDisabledFlagSchema = typeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchema.optional();
export type FormMasterWarehouseLocationCategoryListAutomaticAllocationDisabledFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListAutomaticAllocationDisabledFlagSchema>;
export const formMasterWarehouseLocationCategoryListTotalPickingFlagSchema = typeMasterWarehouseLocationCategoryTotalPickingFlagSchema.optional();
export type FormMasterWarehouseLocationCategoryListTotalPickingFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListTotalPickingFlagSchema>;
export const formMasterWarehouseLocationCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseLocationCategoryListIsAvailableSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListIsAvailableSchema>;
export const formMasterWarehouseLocationCategoryListSchema = yup.object({
	locationCategoryCode: formMasterWarehouseLocationCategoryListLocationCategoryCodeSchema,
	locationCategoryName: formMasterWarehouseLocationCategoryListLocationCategoryNameSchema,
	allocationPriorityFlag: formMasterWarehouseLocationCategoryListAllocationPriorityFlagSchema,
	allocationImpossibleFlag: formMasterWarehouseLocationCategoryListAllocationImpossibleFlagSchema,
	movementProhibitedFlag: formMasterWarehouseLocationCategoryListMovementProhibitedFlagSchema,
	automaticAllocationDisabledFlag: formMasterWarehouseLocationCategoryListAutomaticAllocationDisabledFlagSchema,
	totalPickingFlag: formMasterWarehouseLocationCategoryListTotalPickingFlagSchema,
	isAvailable: formMasterWarehouseLocationCategoryListIsAvailableSchema,
}).required();
export type FormMasterWarehouseLocationCategoryListSchemaType = InferType<typeof formMasterWarehouseLocationCategoryListSchema>;
export const formMasterWarehouseLocationCategoryEntryLocationCategoryCodeSchema = typeMasterWarehouseLocationCategoryCodeSchema;
export type FormMasterWarehouseLocationCategoryEntryLocationCategoryCodeSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryLocationCategoryCodeSchema>;
export const formMasterWarehouseLocationCategoryEntryLocationCategoryNameSchema = typeMasterWarehouseLocationCategoryNameSchema;
export type FormMasterWarehouseLocationCategoryEntryLocationCategoryNameSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryLocationCategoryNameSchema>;
export const formMasterWarehouseLocationCategoryEntryAllocationPriorityFlagSchema = typeMasterWarehouseLocationCategoryAllocationPriorityFlagSchema;
export type FormMasterWarehouseLocationCategoryEntryAllocationPriorityFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryAllocationPriorityFlagSchema>;
export const formMasterWarehouseLocationCategoryEntryAllocationImpossibleFlagSchema = typeMasterWarehouseLocationCategoryAllocationImpossibleFlagSchema;
export type FormMasterWarehouseLocationCategoryEntryAllocationImpossibleFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryAllocationImpossibleFlagSchema>;
export const formMasterWarehouseLocationCategoryEntryMovementProhibitedFlagSchema = typeMasterWarehouseLocationCategoryMovementProhibitedFlagSchema;
export type FormMasterWarehouseLocationCategoryEntryMovementProhibitedFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryMovementProhibitedFlagSchema>;
export const formMasterWarehouseLocationCategoryEntryAutomaticAllocationDisabledFlagSchema = typeMasterWarehouseLocationCategoryAutomaticAllocationDisabledFlagSchema;
export type FormMasterWarehouseLocationCategoryEntryAutomaticAllocationDisabledFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryAutomaticAllocationDisabledFlagSchema>;
export const formMasterWarehouseLocationCategoryEntryTotalPickingFlagSchema = typeMasterWarehouseLocationCategoryTotalPickingFlagSchema;
export type FormMasterWarehouseLocationCategoryEntryTotalPickingFlagSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryTotalPickingFlagSchema>;
export const formMasterWarehouseLocationCategoryEntryLocationCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseLocationCategoryEntryLocationCategoryNoteSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryLocationCategoryNoteSchema>;
export const formMasterWarehouseLocationCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseLocationCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryAvailableFromSchema>;
export const formMasterWarehouseLocationCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseLocationCategoryEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntryAvailableToSchema>;
export const formMasterWarehouseLocationCategoryEntrySchema = yup.object({
	locationCategoryCode: formMasterWarehouseLocationCategoryEntryLocationCategoryCodeSchema,
	locationCategoryName: formMasterWarehouseLocationCategoryEntryLocationCategoryNameSchema,
	allocationPriorityFlag: formMasterWarehouseLocationCategoryEntryAllocationPriorityFlagSchema,
	allocationImpossibleFlag: formMasterWarehouseLocationCategoryEntryAllocationImpossibleFlagSchema,
	movementProhibitedFlag: formMasterWarehouseLocationCategoryEntryMovementProhibitedFlagSchema,
	automaticAllocationDisabledFlag: formMasterWarehouseLocationCategoryEntryAutomaticAllocationDisabledFlagSchema,
	totalPickingFlag: formMasterWarehouseLocationCategoryEntryTotalPickingFlagSchema,
	locationCategoryNote: formMasterWarehouseLocationCategoryEntryLocationCategoryNoteSchema,
	availableFrom: formMasterWarehouseLocationCategoryEntryAvailableFromSchema,
	availableTo: formMasterWarehouseLocationCategoryEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseLocationCategoryEntrySchemaType = InferType<typeof formMasterWarehouseLocationCategoryEntrySchema>;
export const formMasterWarehouseLocationDetailListLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema.optional();
export type FormMasterWarehouseLocationDetailListLocationDetailCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailListLocationDetailCodeSchema>;
export const formMasterWarehouseLocationDetailListLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema.optional();
export type FormMasterWarehouseLocationDetailListLocationDetailNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailListLocationDetailNameSchema>;
export const formMasterWarehouseLocationDetailListLocationIdSchema = typeMasterWarehouseLocationIdSchema.optional();
export type FormMasterWarehouseLocationDetailListLocationIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailListLocationIdSchema>;
export const formMasterWarehouseLocationDetailListLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormMasterWarehouseLocationDetailListLocationNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailListLocationNameSchema>;
export const formMasterWarehouseLocationDetailListLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormMasterWarehouseLocationDetailListLocationCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailListLocationCodeSchema>;
export const formMasterWarehouseLocationDetailListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterWarehouseLocationDetailListShipperIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailListShipperIdSchema>;
export const formMasterWarehouseLocationDetailListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterWarehouseLocationDetailListShipperCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailListShipperCodeSchema>;
export const formMasterWarehouseLocationDetailListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterWarehouseLocationDetailListShipperNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailListShipperNameSchema>;
export const formMasterWarehouseLocationDetailListAisleIdSchema = typeMasterWarehouseAisleIdSchema.optional();
export type FormMasterWarehouseLocationDetailListAisleIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailListAisleIdSchema>;
export const formMasterWarehouseLocationDetailListAllocationPrioritySchema = typeMasterWarehouseLocationDetailAllocationPrioritySchema.optional();
export type FormMasterWarehouseLocationDetailListAllocationPrioritySchemaType = InferType<typeof formMasterWarehouseLocationDetailListAllocationPrioritySchema>;
export const formMasterWarehouseLocationDetailListMixedLocationFlagSchema = typeMasterWarehouseLocationDetailMixedLocationFlagSchema.optional();
export type FormMasterWarehouseLocationDetailListMixedLocationFlagSchemaType = InferType<typeof formMasterWarehouseLocationDetailListMixedLocationFlagSchema>;
export const formMasterWarehouseLocationDetailListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseLocationDetailListIsAvailableSchemaType = InferType<typeof formMasterWarehouseLocationDetailListIsAvailableSchema>;
export const formMasterWarehouseLocationDetailListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterWarehouseLocationDetailListSearchItemsSchemaType = InferType<typeof formMasterWarehouseLocationDetailListSearchItemsSchema>;
export const formMasterWarehouseLocationDetailListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterWarehouseLocationDetailListDetailedConditionsSchemaType = InferType<typeof formMasterWarehouseLocationDetailListDetailedConditionsSchema>;
export const formMasterWarehouseLocationDetailListSchema = yup.object({
	locationDetailCode: formMasterWarehouseLocationDetailListLocationDetailCodeSchema,
	locationDetailName: formMasterWarehouseLocationDetailListLocationDetailNameSchema,
	locationId: formMasterWarehouseLocationDetailListLocationIdSchema,
	locationName: formMasterWarehouseLocationDetailListLocationNameSchema,
	locationCode: formMasterWarehouseLocationDetailListLocationCodeSchema,
	shipperId: formMasterWarehouseLocationDetailListShipperIdSchema,
	shipperCode: formMasterWarehouseLocationDetailListShipperCodeSchema,
	shipperName: formMasterWarehouseLocationDetailListShipperNameSchema,
	aisleId: formMasterWarehouseLocationDetailListAisleIdSchema,
	allocationPriority: formMasterWarehouseLocationDetailListAllocationPrioritySchema,
	mixedLocationFlag: formMasterWarehouseLocationDetailListMixedLocationFlagSchema,
	isAvailable: formMasterWarehouseLocationDetailListIsAvailableSchema,
	searchItems: formMasterWarehouseLocationDetailListSearchItemsSchema,
	detailedConditions: formMasterWarehouseLocationDetailListDetailedConditionsSchema,
}).required();
export type FormMasterWarehouseLocationDetailListSchemaType = InferType<typeof formMasterWarehouseLocationDetailListSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema;
export type FormMasterWarehouseLocationDetailEntryLocationDetailCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailCodeSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema;
export type FormMasterWarehouseLocationDetailEntryLocationDetailNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailNameSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseLocationDetailEntryLocationDetailNoteSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailNoteSchema>;
export const formMasterWarehouseLocationDetailEntryLocationIdSchema = typeMasterWarehouseLocationIdSchema;
export type FormMasterWarehouseLocationDetailEntryLocationIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationIdSchema>;
export const formMasterWarehouseLocationDetailEntryLocationNameSchema = typeMasterWarehouseLocationNameSchema;
export type FormMasterWarehouseLocationDetailEntryLocationNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationNameSchema>;
export const formMasterWarehouseLocationDetailEntryLocationCodeSchema = typeMasterWarehouseLocationCodeSchema;
export type FormMasterWarehouseLocationDetailEntryLocationCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationCodeSchema>;
export const formMasterWarehouseLocationDetailEntryShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterWarehouseLocationDetailEntryShipperIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryShipperIdSchema>;
export const formMasterWarehouseLocationDetailEntryShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterWarehouseLocationDetailEntryShipperCodeSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryShipperCodeSchema>;
export const formMasterWarehouseLocationDetailEntryShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterWarehouseLocationDetailEntryShipperNameSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryShipperNameSchema>;
export const formMasterWarehouseLocationDetailEntryAisleIdSchema = typeMasterWarehouseAisleIdSchema.optional();
export type FormMasterWarehouseLocationDetailEntryAisleIdSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryAisleIdSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailDepthMSchema = typeMasterWarehouseLocationDetailDepthMSchema.optional();
export type FormMasterWarehouseLocationDetailEntryLocationDetailDepthMSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailDepthMSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailWidthMSchema = typeMasterWarehouseLocationDetailWidthMSchema.optional();
export type FormMasterWarehouseLocationDetailEntryLocationDetailWidthMSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailWidthMSchema>;
export const formMasterWarehouseLocationDetailEntryLocationDetailHeightMSchema = typeMasterWarehouseLocationDetailHeightMSchema.optional();
export type FormMasterWarehouseLocationDetailEntryLocationDetailHeightMSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryLocationDetailHeightMSchema>;
export const formMasterWarehouseLocationDetailEntryAllocationPrioritySchema = typeMasterWarehouseLocationDetailAllocationPrioritySchema.optional();
export type FormMasterWarehouseLocationDetailEntryAllocationPrioritySchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryAllocationPrioritySchema>;
export const formMasterWarehouseLocationDetailEntryMixedLocationFlagSchema = typeMasterWarehouseLocationDetailMixedLocationFlagSchema;
export type FormMasterWarehouseLocationDetailEntryMixedLocationFlagSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryMixedLocationFlagSchema>;
export const formMasterWarehouseLocationDetailEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseLocationDetailEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryAvailableFromSchema>;
export const formMasterWarehouseLocationDetailEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseLocationDetailEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseLocationDetailEntryAvailableToSchema>;
export const formMasterWarehouseLocationDetailEntrySchema = yup.object({
	locationDetailCode: formMasterWarehouseLocationDetailEntryLocationDetailCodeSchema,
	locationDetailName: formMasterWarehouseLocationDetailEntryLocationDetailNameSchema,
	locationDetailNote: formMasterWarehouseLocationDetailEntryLocationDetailNoteSchema,
	locationId: formMasterWarehouseLocationDetailEntryLocationIdSchema,
	locationName: formMasterWarehouseLocationDetailEntryLocationNameSchema,
	locationCode: formMasterWarehouseLocationDetailEntryLocationCodeSchema,
	shipperId: formMasterWarehouseLocationDetailEntryShipperIdSchema,
	shipperCode: formMasterWarehouseLocationDetailEntryShipperCodeSchema,
	shipperName: formMasterWarehouseLocationDetailEntryShipperNameSchema,
	aisleId: formMasterWarehouseLocationDetailEntryAisleIdSchema,
	locationDetailDepthM: formMasterWarehouseLocationDetailEntryLocationDetailDepthMSchema,
	locationDetailWidthM: formMasterWarehouseLocationDetailEntryLocationDetailWidthMSchema,
	locationDetailHeightM: formMasterWarehouseLocationDetailEntryLocationDetailHeightMSchema,
	allocationPriority: formMasterWarehouseLocationDetailEntryAllocationPrioritySchema,
	mixedLocationFlag: formMasterWarehouseLocationDetailEntryMixedLocationFlagSchema,
	availableFrom: formMasterWarehouseLocationDetailEntryAvailableFromSchema,
	availableTo: formMasterWarehouseLocationDetailEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseLocationDetailEntrySchemaType = InferType<typeof formMasterWarehouseLocationDetailEntrySchema>;
export const formMasterWarehouseLocationListLocationCodeSchema = typeMasterWarehouseLocationCodeSchema.optional();
export type FormMasterWarehouseLocationListLocationCodeSchemaType = InferType<typeof formMasterWarehouseLocationListLocationCodeSchema>;
export const formMasterWarehouseLocationListLocationNameSchema = typeMasterWarehouseLocationNameSchema.optional();
export type FormMasterWarehouseLocationListLocationNameSchemaType = InferType<typeof formMasterWarehouseLocationListLocationNameSchema>;
export const formMasterWarehouseLocationListLocationNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseLocationListLocationNoteSchemaType = InferType<typeof formMasterWarehouseLocationListLocationNoteSchema>;
export const formMasterWarehouseLocationListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterWarehouseLocationListShipperIdSchemaType = InferType<typeof formMasterWarehouseLocationListShipperIdSchema>;
export const formMasterWarehouseLocationListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterWarehouseLocationListShipperCodeSchemaType = InferType<typeof formMasterWarehouseLocationListShipperCodeSchema>;
export const formMasterWarehouseLocationListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterWarehouseLocationListShipperNameSchemaType = InferType<typeof formMasterWarehouseLocationListShipperNameSchema>;
export const formMasterWarehouseLocationListWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormMasterWarehouseLocationListWarehouseIdSchemaType = InferType<typeof formMasterWarehouseLocationListWarehouseIdSchema>;
export const formMasterWarehouseLocationListWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormMasterWarehouseLocationListWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseLocationListWarehouseCodeSchema>;
export const formMasterWarehouseLocationListWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormMasterWarehouseLocationListWarehouseNameSchemaType = InferType<typeof formMasterWarehouseLocationListWarehouseNameSchema>;
export const formMasterWarehouseLocationListLocationCategoryIdSchema = typeMasterWarehouseLocationCategoryIdSchema.optional();
export type FormMasterWarehouseLocationListLocationCategoryIdSchemaType = InferType<typeof formMasterWarehouseLocationListLocationCategoryIdSchema>;
export const formMasterWarehouseLocationListStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema.optional();
export type FormMasterWarehouseLocationListStoringConditionIdSchemaType = InferType<typeof formMasterWarehouseLocationListStoringConditionIdSchema>;
export const formMasterWarehouseLocationListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseLocationListIsAvailableSchemaType = InferType<typeof formMasterWarehouseLocationListIsAvailableSchema>;
export const formMasterWarehouseLocationListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterWarehouseLocationListSearchItemsSchemaType = InferType<typeof formMasterWarehouseLocationListSearchItemsSchema>;
export const formMasterWarehouseLocationListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterWarehouseLocationListDetailedConditionsSchemaType = InferType<typeof formMasterWarehouseLocationListDetailedConditionsSchema>;
export const formMasterWarehouseLocationListSchema = yup.object({
	locationCode: formMasterWarehouseLocationListLocationCodeSchema,
	locationName: formMasterWarehouseLocationListLocationNameSchema,
	locationNote: formMasterWarehouseLocationListLocationNoteSchema,
	shipperId: formMasterWarehouseLocationListShipperIdSchema,
	shipperCode: formMasterWarehouseLocationListShipperCodeSchema,
	shipperName: formMasterWarehouseLocationListShipperNameSchema,
	warehouseId: formMasterWarehouseLocationListWarehouseIdSchema,
	warehouseCode: formMasterWarehouseLocationListWarehouseCodeSchema,
	warehouseName: formMasterWarehouseLocationListWarehouseNameSchema,
	locationCategoryId: formMasterWarehouseLocationListLocationCategoryIdSchema,
	storingConditionId: formMasterWarehouseLocationListStoringConditionIdSchema,
	isAvailable: formMasterWarehouseLocationListIsAvailableSchema,
	searchItems: formMasterWarehouseLocationListSearchItemsSchema,
	detailedConditions: formMasterWarehouseLocationListDetailedConditionsSchema,
}).required();
export type FormMasterWarehouseLocationListSchemaType = InferType<typeof formMasterWarehouseLocationListSchema>;
export const formMasterWarehouseLocationEntryLocationCodeSchema = typeMasterWarehouseLocationCodeSchema;
export type FormMasterWarehouseLocationEntryLocationCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationCodeSchema>;
export const formMasterWarehouseLocationEntryLocationNameSchema = typeMasterWarehouseLocationNameSchema;
export type FormMasterWarehouseLocationEntryLocationNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationNameSchema>;
export const formMasterWarehouseLocationEntryLocationNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseLocationEntryLocationNoteSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationNoteSchema>;
export const formMasterWarehouseLocationEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterWarehouseLocationEntryShipperIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryShipperIdSchema>;
export const formMasterWarehouseLocationEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterWarehouseLocationEntryShipperCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryShipperCodeSchema>;
export const formMasterWarehouseLocationEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterWarehouseLocationEntryShipperNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryShipperNameSchema>;
export const formMasterWarehouseLocationEntryWarehouseIdSchema = typeMasterWarehouseIdSchema;
export type FormMasterWarehouseLocationEntryWarehouseIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryWarehouseIdSchema>;
export const formMasterWarehouseLocationEntryWarehouseCodeSchema = typeMasterWarehouseCodeSchema;
export type FormMasterWarehouseLocationEntryWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryWarehouseCodeSchema>;
export const formMasterWarehouseLocationEntryWarehouseNameSchema = typeMasterWarehouseNameSchema;
export type FormMasterWarehouseLocationEntryWarehouseNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryWarehouseNameSchema>;
export const formMasterWarehouseLocationEntryLocationCategoryIdSchema = typeMasterWarehouseLocationCategoryIdSchema;
export type FormMasterWarehouseLocationEntryLocationCategoryIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationCategoryIdSchema>;
export const formMasterWarehouseLocationEntryStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema;
export type FormMasterWarehouseLocationEntryStoringConditionIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryStoringConditionIdSchema>;
export const formMasterWarehouseLocationEntryLocationDetailCodeSchema = typeMasterWarehouseLocationDetailCodeSchema;
export type FormMasterWarehouseLocationEntryLocationDetailCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationDetailCodeSchema>;
export const formMasterWarehouseLocationEntryLocationDetailNameSchema = typeMasterWarehouseLocationDetailNameSchema;
export type FormMasterWarehouseLocationEntryLocationDetailNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryLocationDetailNameSchema>;
export const formMasterWarehouseLocationEntryAisleCodeSchema = typeMasterWarehouseAisleCodeSchema.optional();
export type FormMasterWarehouseLocationEntryAisleCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryAisleCodeSchema>;
export const formMasterWarehouseLocationEntryAisleNameSchema = typeMasterWarehouseAisleNameSchema.optional();
export type FormMasterWarehouseLocationEntryAisleNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryAisleNameSchema>;
export const formMasterWarehouseLocationEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseLocationEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseLocationEntryAvailableFromSchema>;
export const formMasterWarehouseLocationEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseLocationEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseLocationEntryAvailableToSchema>;
export const formMasterWarehouseLocationEntrySchema = yup.object({
	locationCode: formMasterWarehouseLocationEntryLocationCodeSchema,
	locationName: formMasterWarehouseLocationEntryLocationNameSchema,
	locationNote: formMasterWarehouseLocationEntryLocationNoteSchema,
	shipperId: formMasterWarehouseLocationEntryShipperIdSchema,
	shipperCode: formMasterWarehouseLocationEntryShipperCodeSchema,
	shipperName: formMasterWarehouseLocationEntryShipperNameSchema,
	warehouseId: formMasterWarehouseLocationEntryWarehouseIdSchema,
	warehouseCode: formMasterWarehouseLocationEntryWarehouseCodeSchema,
	warehouseName: formMasterWarehouseLocationEntryWarehouseNameSchema,
	locationCategoryId: formMasterWarehouseLocationEntryLocationCategoryIdSchema,
	storingConditionId: formMasterWarehouseLocationEntryStoringConditionIdSchema,
	locationDetailCode: formMasterWarehouseLocationEntryLocationDetailCodeSchema,
	locationDetailName: formMasterWarehouseLocationEntryLocationDetailNameSchema,
	aisleCode: formMasterWarehouseLocationEntryAisleCodeSchema,
	aisleName: formMasterWarehouseLocationEntryAisleNameSchema,
	availableFrom: formMasterWarehouseLocationEntryAvailableFromSchema,
	availableTo: formMasterWarehouseLocationEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseLocationEntrySchemaType = InferType<typeof formMasterWarehouseLocationEntrySchema>;
export const formMasterWarehouseLocationEntryUpdateLocationCodeSchema = typeMasterWarehouseLocationCodeSchema;
export type FormMasterWarehouseLocationEntryUpdateLocationCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateLocationCodeSchema>;
export const formMasterWarehouseLocationEntryUpdateLocationNameSchema = typeMasterWarehouseLocationNameSchema;
export type FormMasterWarehouseLocationEntryUpdateLocationNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateLocationNameSchema>;
export const formMasterWarehouseLocationEntryUpdateLocationNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseLocationEntryUpdateLocationNoteSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateLocationNoteSchema>;
export const formMasterWarehouseLocationEntryUpdateShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterWarehouseLocationEntryUpdateShipperIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateShipperIdSchema>;
export const formMasterWarehouseLocationEntryUpdateShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterWarehouseLocationEntryUpdateShipperCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateShipperCodeSchema>;
export const formMasterWarehouseLocationEntryUpdateShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterWarehouseLocationEntryUpdateShipperNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateShipperNameSchema>;
export const formMasterWarehouseLocationEntryUpdateWarehouseIdSchema = typeMasterWarehouseIdSchema;
export type FormMasterWarehouseLocationEntryUpdateWarehouseIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateWarehouseIdSchema>;
export const formMasterWarehouseLocationEntryUpdateWarehouseCodeSchema = typeMasterWarehouseCodeSchema;
export type FormMasterWarehouseLocationEntryUpdateWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateWarehouseCodeSchema>;
export const formMasterWarehouseLocationEntryUpdateWarehouseNameSchema = typeMasterWarehouseNameSchema;
export type FormMasterWarehouseLocationEntryUpdateWarehouseNameSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateWarehouseNameSchema>;
export const formMasterWarehouseLocationEntryUpdateLocationCategoryIdSchema = typeMasterWarehouseLocationCategoryIdSchema;
export type FormMasterWarehouseLocationEntryUpdateLocationCategoryIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateLocationCategoryIdSchema>;
export const formMasterWarehouseLocationEntryUpdateStoringConditionIdSchema = typeMasterWarehouseStoringConditionIdSchema;
export type FormMasterWarehouseLocationEntryUpdateStoringConditionIdSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateStoringConditionIdSchema>;
export const formMasterWarehouseLocationEntryUpdateAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseLocationEntryUpdateAvailableFromSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateAvailableFromSchema>;
export const formMasterWarehouseLocationEntryUpdateAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseLocationEntryUpdateAvailableToSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateAvailableToSchema>;
export const formMasterWarehouseLocationEntryUpdateSchema = yup.object({
	locationCode: formMasterWarehouseLocationEntryUpdateLocationCodeSchema,
	locationName: formMasterWarehouseLocationEntryUpdateLocationNameSchema,
	locationNote: formMasterWarehouseLocationEntryUpdateLocationNoteSchema,
	shipperId: formMasterWarehouseLocationEntryUpdateShipperIdSchema,
	shipperCode: formMasterWarehouseLocationEntryUpdateShipperCodeSchema,
	shipperName: formMasterWarehouseLocationEntryUpdateShipperNameSchema,
	warehouseId: formMasterWarehouseLocationEntryUpdateWarehouseIdSchema,
	warehouseCode: formMasterWarehouseLocationEntryUpdateWarehouseCodeSchema,
	warehouseName: formMasterWarehouseLocationEntryUpdateWarehouseNameSchema,
	locationCategoryId: formMasterWarehouseLocationEntryUpdateLocationCategoryIdSchema,
	storingConditionId: formMasterWarehouseLocationEntryUpdateStoringConditionIdSchema,
	availableFrom: formMasterWarehouseLocationEntryUpdateAvailableFromSchema,
	availableTo: formMasterWarehouseLocationEntryUpdateAvailableToSchema,
}).required();
export type FormMasterWarehouseLocationEntryUpdateSchemaType = InferType<typeof formMasterWarehouseLocationEntryUpdateSchema>;
export const formMasterWarehouseStoringConditionListStoringConditionCodeSchema = typeMasterWarehouseStoringConditionCodeSchema.optional();
export type FormMasterWarehouseStoringConditionListStoringConditionCodeSchemaType = InferType<typeof formMasterWarehouseStoringConditionListStoringConditionCodeSchema>;
export const formMasterWarehouseStoringConditionListStoringConditionNameSchema = typeMasterWarehouseStoringConditionNameSchema.optional();
export type FormMasterWarehouseStoringConditionListStoringConditionNameSchemaType = InferType<typeof formMasterWarehouseStoringConditionListStoringConditionNameSchema>;
export const formMasterWarehouseStoringConditionListStoringConditionDifferenceFlagSchema = typeMasterWarehouseStoringConditionDifferenceFlagSchema.optional();
export type FormMasterWarehouseStoringConditionListStoringConditionDifferenceFlagSchemaType = InferType<typeof formMasterWarehouseStoringConditionListStoringConditionDifferenceFlagSchema>;
export const formMasterWarehouseStoringConditionListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseStoringConditionListIsAvailableSchemaType = InferType<typeof formMasterWarehouseStoringConditionListIsAvailableSchema>;
export const formMasterWarehouseStoringConditionListSchema = yup.object({
	storingConditionCode: formMasterWarehouseStoringConditionListStoringConditionCodeSchema,
	storingConditionName: formMasterWarehouseStoringConditionListStoringConditionNameSchema,
	storingConditionDifferenceFlag: formMasterWarehouseStoringConditionListStoringConditionDifferenceFlagSchema,
	isAvailable: formMasterWarehouseStoringConditionListIsAvailableSchema,
}).required();
export type FormMasterWarehouseStoringConditionListSchemaType = InferType<typeof formMasterWarehouseStoringConditionListSchema>;
export const formMasterWarehouseStoringConditionEntryStoringConditionCodeSchema = typeMasterWarehouseStoringConditionCodeSchema;
export type FormMasterWarehouseStoringConditionEntryStoringConditionCodeSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryStoringConditionCodeSchema>;
export const formMasterWarehouseStoringConditionEntryStoringConditionNameSchema = typeMasterWarehouseStoringConditionNameSchema;
export type FormMasterWarehouseStoringConditionEntryStoringConditionNameSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryStoringConditionNameSchema>;
export const formMasterWarehouseStoringConditionEntryStoringConditionDifferenceFlagSchema = typeMasterWarehouseStoringConditionDifferenceFlagSchema;
export type FormMasterWarehouseStoringConditionEntryStoringConditionDifferenceFlagSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryStoringConditionDifferenceFlagSchema>;
export const formMasterWarehouseStoringConditionEntryStoringConditionNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseStoringConditionEntryStoringConditionNoteSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryStoringConditionNoteSchema>;
export const formMasterWarehouseStoringConditionEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseStoringConditionEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryAvailableFromSchema>;
export const formMasterWarehouseStoringConditionEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseStoringConditionEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseStoringConditionEntryAvailableToSchema>;
export const formMasterWarehouseStoringConditionEntrySchema = yup.object({
	storingConditionCode: formMasterWarehouseStoringConditionEntryStoringConditionCodeSchema,
	storingConditionName: formMasterWarehouseStoringConditionEntryStoringConditionNameSchema,
	storingConditionDifferenceFlag: formMasterWarehouseStoringConditionEntryStoringConditionDifferenceFlagSchema,
	storingConditionNote: formMasterWarehouseStoringConditionEntryStoringConditionNoteSchema,
	availableFrom: formMasterWarehouseStoringConditionEntryAvailableFromSchema,
	availableTo: formMasterWarehouseStoringConditionEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseStoringConditionEntrySchemaType = InferType<typeof formMasterWarehouseStoringConditionEntrySchema>;
export const formMasterWarehouseListWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormMasterWarehouseListWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseListWarehouseCodeSchema>;
export const formMasterWarehouseListWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormMasterWarehouseListWarehouseNameSchemaType = InferType<typeof formMasterWarehouseListWarehouseNameSchema>;
export const formMasterWarehouseListShipperIdSchema = typeMasterShipperIdSchema.optional();
export type FormMasterWarehouseListShipperIdSchemaType = InferType<typeof formMasterWarehouseListShipperIdSchema>;
export const formMasterWarehouseListShipperCodeSchema = typeMasterShipperCodeSchema.optional();
export type FormMasterWarehouseListShipperCodeSchemaType = InferType<typeof formMasterWarehouseListShipperCodeSchema>;
export const formMasterWarehouseListShipperNameSchema = typeMasterShipperNameSchema.optional();
export type FormMasterWarehouseListShipperNameSchemaType = InferType<typeof formMasterWarehouseListShipperNameSchema>;
export const formMasterWarehouseListWarehouseCategoryIdSchema = typeMasterWarehouseCategoryIdSchema.optional();
export type FormMasterWarehouseListWarehouseCategoryIdSchemaType = InferType<typeof formMasterWarehouseListWarehouseCategoryIdSchema>;
export const formMasterWarehouseListWarehouseCategoryCodeSchema = typeMasterWarehouseCategoryCodeSchema.optional();
export type FormMasterWarehouseListWarehouseCategoryCodeSchemaType = InferType<typeof formMasterWarehouseListWarehouseCategoryCodeSchema>;
export const formMasterWarehouseListWarehouseCategoryNameSchema = typeMasterWarehouseCategoryNameSchema.optional();
export type FormMasterWarehouseListWarehouseCategoryNameSchemaType = InferType<typeof formMasterWarehouseListWarehouseCategoryNameSchema>;
export const formMasterWarehouseListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterWarehouseListIsAvailableSchemaType = InferType<typeof formMasterWarehouseListIsAvailableSchema>;
export const formMasterWarehouseListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterWarehouseListSearchItemsSchemaType = InferType<typeof formMasterWarehouseListSearchItemsSchema>;
export const formMasterWarehouseListDetailedConditionsSchema = typeDetailedConditionItemsSchema;
export type FormMasterWarehouseListDetailedConditionsSchemaType = InferType<typeof formMasterWarehouseListDetailedConditionsSchema>;
export const formMasterWarehouseListSchema = yup.object({
	warehouseCode: formMasterWarehouseListWarehouseCodeSchema,
	warehouseName: formMasterWarehouseListWarehouseNameSchema,
	shipperId: formMasterWarehouseListShipperIdSchema,
	shipperCode: formMasterWarehouseListShipperCodeSchema,
	shipperName: formMasterWarehouseListShipperNameSchema,
	warehouseCategoryId: formMasterWarehouseListWarehouseCategoryIdSchema,
	warehouseCategoryCode: formMasterWarehouseListWarehouseCategoryCodeSchema,
	warehouseCategoryName: formMasterWarehouseListWarehouseCategoryNameSchema,
	isAvailable: formMasterWarehouseListIsAvailableSchema,
	searchItems: formMasterWarehouseListSearchItemsSchema,
	detailedConditions: formMasterWarehouseListDetailedConditionsSchema,
}).required();
export type FormMasterWarehouseListSchemaType = InferType<typeof formMasterWarehouseListSchema>;
export const formMasterWarehouseEntryWarehouseCodeSchema = typeMasterWarehouseCodeSchema;
export type FormMasterWarehouseEntryWarehouseCodeSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseCodeSchema>;
export const formMasterWarehouseEntryWarehouseNameSchema = typeMasterWarehouseNameSchema;
export type FormMasterWarehouseEntryWarehouseNameSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseNameSchema>;
export const formMasterWarehouseEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormMasterWarehouseEntryShipperIdSchemaType = InferType<typeof formMasterWarehouseEntryShipperIdSchema>;
export const formMasterWarehouseEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormMasterWarehouseEntryShipperCodeSchemaType = InferType<typeof formMasterWarehouseEntryShipperCodeSchema>;
export const formMasterWarehouseEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormMasterWarehouseEntryShipperNameSchemaType = InferType<typeof formMasterWarehouseEntryShipperNameSchema>;
export const formMasterWarehouseEntryWarehouseCategoryIdValueSchema = typeMasterWarehouseCategoryIdSchema;
export type FormMasterWarehouseEntryWarehouseCategoryIdValueSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseCategoryIdValueSchema>;
export const formMasterWarehouseEntryWarehouseCategoryIdSchema = yup.array(
	formMasterWarehouseEntryWarehouseCategoryIdValueSchema
).optional();
export type FormMasterWarehouseEntryWarehouseCategoryIdSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseCategoryIdSchema>;
export const formMasterWarehouseEntryWarehousePostalCodeSchema = typeCommonPostalCodeSchema;
export type FormMasterWarehouseEntryWarehousePostalCodeSchemaType = InferType<typeof formMasterWarehouseEntryWarehousePostalCodeSchema>;
export const formMasterWarehouseEntryWarehouseAddressSchema = typeCommonAddressSchema.optional();
export type FormMasterWarehouseEntryWarehouseAddressSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseAddressSchema>;
export const formMasterWarehouseEntryWarehouseTelSchema = typeCommonTelSchema;
export type FormMasterWarehouseEntryWarehouseTelSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseTelSchema>;
export const formMasterWarehouseEntryWarehouseFaxSchema = typeCommonFaxSchema;
export type FormMasterWarehouseEntryWarehouseFaxSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseFaxSchema>;
export const formMasterWarehouseEntryWarehouseNoteSchema = typeCommonNoteSchema;
export type FormMasterWarehouseEntryWarehouseNoteSchemaType = InferType<typeof formMasterWarehouseEntryWarehouseNoteSchema>;
export const formMasterWarehouseEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterWarehouseEntryAvailableFromSchemaType = InferType<typeof formMasterWarehouseEntryAvailableFromSchema>;
export const formMasterWarehouseEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterWarehouseEntryAvailableToSchemaType = InferType<typeof formMasterWarehouseEntryAvailableToSchema>;
export const formMasterWarehouseEntrySchema = yup.object({
	warehouseCode: formMasterWarehouseEntryWarehouseCodeSchema,
	warehouseName: formMasterWarehouseEntryWarehouseNameSchema,
	shipperId: formMasterWarehouseEntryShipperIdSchema,
	shipperCode: formMasterWarehouseEntryShipperCodeSchema,
	shipperName: formMasterWarehouseEntryShipperNameSchema,
	warehouseCategoryId: formMasterWarehouseEntryWarehouseCategoryIdSchema,
	warehousePostalCode: formMasterWarehouseEntryWarehousePostalCodeSchema,
	warehouseAddress: formMasterWarehouseEntryWarehouseAddressSchema,
	warehouseTel: formMasterWarehouseEntryWarehouseTelSchema,
	warehouseFax: formMasterWarehouseEntryWarehouseFaxSchema,
	warehouseNote: formMasterWarehouseEntryWarehouseNoteSchema,
	availableFrom: formMasterWarehouseEntryAvailableFromSchema,
	availableTo: formMasterWarehouseEntryAvailableToSchema,
}).required();
export type FormMasterWarehouseEntrySchemaType = InferType<typeof formMasterWarehouseEntrySchema>;
