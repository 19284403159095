// fv-generatorで生成されました
import * as yup from "yup";
import { typeMasterAssetCategoryCodeSchema, typeMasterAssetCategoryNameSchema, typeMasterAssetCodeSchema, typeMasterAssetNameSchema, typeMasterWarehouseIdSchema, typeMasterWarehouseCodeSchema, typeMasterWarehouseNameSchema, typeMasterAssetStatusSchema, typeMasterAssetCategoryIdSchema, typeMasterAssetReturnScheduledDateSchema, typeMasterAssetReturnDateSchema, typeMasterAssetCategoryId1Schema, typeMasterAssetCategoryId2Schema, typeMasterAssetCategoryId3Schema, typeMasterAssetQuantitySchema } from "./../../master";
import { InferType } from "yup";
import { typeCommonIsAvailableSchema, typeCommonNoteSchema, typeCommonAvailableFromSchema, typeCommonAvailableToSchema } from "./../../common";
import { typeSearchItemsSchema } from "./../../search";

export const formMasterAssetCategoryListAssetCategoryCodeSchema = typeMasterAssetCategoryCodeSchema.optional();
export type FormMasterAssetCategoryListAssetCategoryCodeSchemaType = InferType<typeof formMasterAssetCategoryListAssetCategoryCodeSchema>;
export const formMasterAssetCategoryListAssetCategoryNameSchema = typeMasterAssetCategoryNameSchema.optional();
export type FormMasterAssetCategoryListAssetCategoryNameSchemaType = InferType<typeof formMasterAssetCategoryListAssetCategoryNameSchema>;
export const formMasterAssetCategoryListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterAssetCategoryListIsAvailableSchemaType = InferType<typeof formMasterAssetCategoryListIsAvailableSchema>;
export const formMasterAssetCategoryListSchema = yup.object({
	assetCategoryCode: formMasterAssetCategoryListAssetCategoryCodeSchema,
	assetCategoryName: formMasterAssetCategoryListAssetCategoryNameSchema,
	isAvailable: formMasterAssetCategoryListIsAvailableSchema,
}).required();
export type FormMasterAssetCategoryListSchemaType = InferType<typeof formMasterAssetCategoryListSchema>;
export const formMasterAssetCategoryEntryAssetCategoryCodeSchema = typeMasterAssetCategoryCodeSchema;
export type FormMasterAssetCategoryEntryAssetCategoryCodeSchemaType = InferType<typeof formMasterAssetCategoryEntryAssetCategoryCodeSchema>;
export const formMasterAssetCategoryEntryAssetCategoryNameSchema = typeMasterAssetCategoryNameSchema;
export type FormMasterAssetCategoryEntryAssetCategoryNameSchemaType = InferType<typeof formMasterAssetCategoryEntryAssetCategoryNameSchema>;
export const formMasterAssetCategoryEntryAssetCategoryNoteSchema = typeCommonNoteSchema;
export type FormMasterAssetCategoryEntryAssetCategoryNoteSchemaType = InferType<typeof formMasterAssetCategoryEntryAssetCategoryNoteSchema>;
export const formMasterAssetCategoryEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterAssetCategoryEntryAvailableFromSchemaType = InferType<typeof formMasterAssetCategoryEntryAvailableFromSchema>;
export const formMasterAssetCategoryEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterAssetCategoryEntryAvailableToSchemaType = InferType<typeof formMasterAssetCategoryEntryAvailableToSchema>;
export const formMasterAssetCategoryEntrySchema = yup.object({
	assetCategoryCode: formMasterAssetCategoryEntryAssetCategoryCodeSchema,
	assetCategoryName: formMasterAssetCategoryEntryAssetCategoryNameSchema,
	assetCategoryNote: formMasterAssetCategoryEntryAssetCategoryNoteSchema,
	availableFrom: formMasterAssetCategoryEntryAvailableFromSchema,
	availableTo: formMasterAssetCategoryEntryAvailableToSchema,
}).required();
export type FormMasterAssetCategoryEntrySchemaType = InferType<typeof formMasterAssetCategoryEntrySchema>;
export const formMasterAssetListAssetCodeSchema = typeMasterAssetCodeSchema.optional();
export type FormMasterAssetListAssetCodeSchemaType = InferType<typeof formMasterAssetListAssetCodeSchema>;
export const formMasterAssetListAssetNameSchema = typeMasterAssetNameSchema.optional();
export type FormMasterAssetListAssetNameSchemaType = InferType<typeof formMasterAssetListAssetNameSchema>;
export const formMasterAssetListWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormMasterAssetListWarehouseIdSchemaType = InferType<typeof formMasterAssetListWarehouseIdSchema>;
export const formMasterAssetListWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormMasterAssetListWarehouseCodeSchemaType = InferType<typeof formMasterAssetListWarehouseCodeSchema>;
export const formMasterAssetListWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormMasterAssetListWarehouseNameSchemaType = InferType<typeof formMasterAssetListWarehouseNameSchema>;
export const formMasterAssetListAssetStatusSchema = typeMasterAssetStatusSchema.optional();
export type FormMasterAssetListAssetStatusSchemaType = InferType<typeof formMasterAssetListAssetStatusSchema>;
export const formMasterAssetListAssetCategoryIdSchema = typeMasterAssetCategoryIdSchema.optional();
export type FormMasterAssetListAssetCategoryIdSchemaType = InferType<typeof formMasterAssetListAssetCategoryIdSchema>;
export const formMasterAssetListAssetReturnScheduledDateSchema = typeMasterAssetReturnScheduledDateSchema.optional();
export type FormMasterAssetListAssetReturnScheduledDateSchemaType = InferType<typeof formMasterAssetListAssetReturnScheduledDateSchema>;
export const formMasterAssetListAssetReturnDateSchema = typeMasterAssetReturnDateSchema.optional();
export type FormMasterAssetListAssetReturnDateSchemaType = InferType<typeof formMasterAssetListAssetReturnDateSchema>;
export const formMasterAssetListIsAvailableSchema = typeCommonIsAvailableSchema;
export type FormMasterAssetListIsAvailableSchemaType = InferType<typeof formMasterAssetListIsAvailableSchema>;
export const formMasterAssetListSearchItemsSchema = typeSearchItemsSchema;
export type FormMasterAssetListSearchItemsSchemaType = InferType<typeof formMasterAssetListSearchItemsSchema>;
export const formMasterAssetListSchema = yup.object({
	assetCode: formMasterAssetListAssetCodeSchema,
	assetName: formMasterAssetListAssetNameSchema,
	warehouseId: formMasterAssetListWarehouseIdSchema,
	warehouseCode: formMasterAssetListWarehouseCodeSchema,
	warehouseName: formMasterAssetListWarehouseNameSchema,
	assetStatus: formMasterAssetListAssetStatusSchema,
	assetCategoryId: formMasterAssetListAssetCategoryIdSchema,
	assetReturnScheduledDate: formMasterAssetListAssetReturnScheduledDateSchema,
	assetReturnDate: formMasterAssetListAssetReturnDateSchema,
	isAvailable: formMasterAssetListIsAvailableSchema,
	searchItems: formMasterAssetListSearchItemsSchema,
}).required();
export type FormMasterAssetListSchemaType = InferType<typeof formMasterAssetListSchema>;
export const formMasterAssetEntryAssetCodeSchema = typeMasterAssetCodeSchema;
export type FormMasterAssetEntryAssetCodeSchemaType = InferType<typeof formMasterAssetEntryAssetCodeSchema>;
export const formMasterAssetEntryAssetNameSchema = typeMasterAssetNameSchema;
export type FormMasterAssetEntryAssetNameSchemaType = InferType<typeof formMasterAssetEntryAssetNameSchema>;
export const formMasterAssetEntryWarehouseIdSchema = typeMasterWarehouseIdSchema.optional();
export type FormMasterAssetEntryWarehouseIdSchemaType = InferType<typeof formMasterAssetEntryWarehouseIdSchema>;
export const formMasterAssetEntryWarehouseCodeSchema = typeMasterWarehouseCodeSchema.optional();
export type FormMasterAssetEntryWarehouseCodeSchemaType = InferType<typeof formMasterAssetEntryWarehouseCodeSchema>;
export const formMasterAssetEntryWarehouseNameSchema = typeMasterWarehouseNameSchema.optional();
export type FormMasterAssetEntryWarehouseNameSchemaType = InferType<typeof formMasterAssetEntryWarehouseNameSchema>;
export const formMasterAssetEntryAssetCategoryId1Schema = typeMasterAssetCategoryId1Schema;
export type FormMasterAssetEntryAssetCategoryId1SchemaType = InferType<typeof formMasterAssetEntryAssetCategoryId1Schema>;
export const formMasterAssetEntryAssetCategoryId2Schema = typeMasterAssetCategoryId2Schema.optional();
export type FormMasterAssetEntryAssetCategoryId2SchemaType = InferType<typeof formMasterAssetEntryAssetCategoryId2Schema>;
export const formMasterAssetEntryAssetCategoryId3Schema = typeMasterAssetCategoryId3Schema.optional();
export type FormMasterAssetEntryAssetCategoryId3SchemaType = InferType<typeof formMasterAssetEntryAssetCategoryId3Schema>;
export const formMasterAssetEntryAssetStatusSchema = typeMasterAssetStatusSchema.optional();
export type FormMasterAssetEntryAssetStatusSchemaType = InferType<typeof formMasterAssetEntryAssetStatusSchema>;
export const formMasterAssetEntryAssetQuantitySchema = typeMasterAssetQuantitySchema.optional();
export type FormMasterAssetEntryAssetQuantitySchemaType = InferType<typeof formMasterAssetEntryAssetQuantitySchema>;
export const formMasterAssetEntryAssetReturnScheduledDateSchema = typeMasterAssetReturnScheduledDateSchema.optional();
export type FormMasterAssetEntryAssetReturnScheduledDateSchemaType = InferType<typeof formMasterAssetEntryAssetReturnScheduledDateSchema>;
export const formMasterAssetEntryAssetReturnDateSchema = typeMasterAssetReturnDateSchema.optional();
export type FormMasterAssetEntryAssetReturnDateSchemaType = InferType<typeof formMasterAssetEntryAssetReturnDateSchema>;
export const formMasterAssetEntryAssetNoteSchema = typeCommonNoteSchema;
export type FormMasterAssetEntryAssetNoteSchemaType = InferType<typeof formMasterAssetEntryAssetNoteSchema>;
export const formMasterAssetEntryAvailableFromSchema = typeCommonAvailableFromSchema;
export type FormMasterAssetEntryAvailableFromSchemaType = InferType<typeof formMasterAssetEntryAvailableFromSchema>;
export const formMasterAssetEntryAvailableToSchema = typeCommonAvailableToSchema;
export type FormMasterAssetEntryAvailableToSchemaType = InferType<typeof formMasterAssetEntryAvailableToSchema>;
export const formMasterAssetEntrySchema = yup.object({
	assetCode: formMasterAssetEntryAssetCodeSchema,
	assetName: formMasterAssetEntryAssetNameSchema,
	warehouseId: formMasterAssetEntryWarehouseIdSchema,
	warehouseCode: formMasterAssetEntryWarehouseCodeSchema,
	warehouseName: formMasterAssetEntryWarehouseNameSchema,
	assetCategoryId1: formMasterAssetEntryAssetCategoryId1Schema,
	assetCategoryId2: formMasterAssetEntryAssetCategoryId2Schema,
	assetCategoryId3: formMasterAssetEntryAssetCategoryId3Schema,
	assetStatus: formMasterAssetEntryAssetStatusSchema,
	assetQuantity: formMasterAssetEntryAssetQuantitySchema,
	assetReturnScheduledDate: formMasterAssetEntryAssetReturnScheduledDateSchema,
	assetReturnDate: formMasterAssetEntryAssetReturnDateSchema,
	assetNote: formMasterAssetEntryAssetNoteSchema,
	availableFrom: formMasterAssetEntryAvailableFromSchema,
	availableTo: formMasterAssetEntryAvailableToSchema,
}).required();
export type FormMasterAssetEntrySchemaType = InferType<typeof formMasterAssetEntrySchema>;
