// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingCodeSchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingSagawaLabelShippingCodeSchema = typeShippingCodeSchema.optional();
export type FormShippingShippingSagawaLabelShippingCodeSchemaType = InferType<typeof formShippingShippingSagawaLabelShippingCodeSchema>;
export const formShippingShippingSagawaLabelSchema = yup.object({
	shippingCode: formShippingShippingSagawaLabelShippingCodeSchema,
}).required();
export type FormShippingShippingSagawaLabelSchemaType = InferType<typeof formShippingShippingSagawaLabelSchema>;
