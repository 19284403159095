// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingOrderCodeSchema, typeShippingCodeSchema, typeShippingScheduledDateSchema, typeShippingDetailIdSchema, typeShippingScheduledQuantitySchema, typeShippingQuantitySchema, typeShippingCategoryIdSchema } from "./../../shipping";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterCustomerIdSchema, typeMasterCustomerCodeSchema, typeMasterCustomerNameSchema, typeMasterCustomerDeliveryDestinationIdSchema, typeMasterCustomerDeliveryDestinationCodeSchema, typeMasterCustomerDeliveryDestinationNameSchema, typeMasterDeliveryCompanyIdSchema, typeMasterDeliveryCompanyCodeSchema, typeMasterDeliveryCompanyNameSchema, typeMasterDeliveryCompanyBaseIdSchema, typeMasterDeliveryCompanyBaseCodeSchema, typeMasterDeliveryCompanyBaseNameSchema, typeMasterDeliveryCompanyDeliveryDateSchema, typeMasterDeliveryCompanyTimeSpanIdSchema, typeMasterProductSkuIdSchema } from "./../../master";

export const formShippingShippingScheduleEntryOrderCodeSchema = typeShippingOrderCodeSchema;
export type FormShippingShippingScheduleEntryOrderCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryOrderCodeSchema>;
export const formShippingShippingScheduleEntryShippingCodeSchema = typeShippingCodeSchema;
export type FormShippingShippingScheduleEntryShippingCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingCodeSchema>;
export const formShippingShippingScheduleEntryShippingScheduledDateSchema = typeShippingScheduledDateSchema;
export type FormShippingShippingScheduleEntryShippingScheduledDateSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingScheduledDateSchema>;
export const formShippingShippingScheduleEntryShippingNoteSchema = typeCommonNoteSchema;
export type FormShippingShippingScheduleEntryShippingNoteSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingNoteSchema>;
export const formShippingShippingScheduleEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormShippingShippingScheduleEntryShipperIdSchemaType = InferType<typeof formShippingShippingScheduleEntryShipperIdSchema>;
export const formShippingShippingScheduleEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormShippingShippingScheduleEntryShipperCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryShipperCodeSchema>;
export const formShippingShippingScheduleEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormShippingShippingScheduleEntryShipperNameSchemaType = InferType<typeof formShippingShippingScheduleEntryShipperNameSchema>;
export const formShippingShippingScheduleEntryCustomerIdSchema = typeMasterCustomerIdSchema;
export type FormShippingShippingScheduleEntryCustomerIdSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerIdSchema>;
export const formShippingShippingScheduleEntryCustomerCodeSchema = typeMasterCustomerCodeSchema;
export type FormShippingShippingScheduleEntryCustomerCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerCodeSchema>;
export const formShippingShippingScheduleEntryCustomerNameSchema = typeMasterCustomerNameSchema;
export type FormShippingShippingScheduleEntryCustomerNameSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerNameSchema>;
export const formShippingShippingScheduleEntryCustomerDeliveryDestinationIdSchema = typeMasterCustomerDeliveryDestinationIdSchema;
export type FormShippingShippingScheduleEntryCustomerDeliveryDestinationIdSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerDeliveryDestinationIdSchema>;
export const formShippingShippingScheduleEntryCustomerDeliveryDestinationCodeSchema = typeMasterCustomerDeliveryDestinationCodeSchema;
export type FormShippingShippingScheduleEntryCustomerDeliveryDestinationCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerDeliveryDestinationCodeSchema>;
export const formShippingShippingScheduleEntryCustomerDeliveryDestinationNameSchema = typeMasterCustomerDeliveryDestinationNameSchema;
export type FormShippingShippingScheduleEntryCustomerDeliveryDestinationNameSchemaType = InferType<typeof formShippingShippingScheduleEntryCustomerDeliveryDestinationNameSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyIdSchema = typeMasterDeliveryCompanyIdSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyIdSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyIdSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyCodeSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyNameSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyNameSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyBaseIdSchema = typeMasterDeliveryCompanyBaseIdSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyBaseIdSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyBaseIdSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyBaseCodeSchema = typeMasterDeliveryCompanyBaseCodeSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyBaseCodeSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyBaseCodeSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyBaseNameSchema = typeMasterDeliveryCompanyBaseNameSchema;
export type FormShippingShippingScheduleEntryDeliveryCompanyBaseNameSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyBaseNameSchema>;
export const formShippingShippingScheduleEntryDeliveryDateSchema = typeMasterDeliveryCompanyDeliveryDateSchema.optional();
export type FormShippingShippingScheduleEntryDeliveryDateSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryDateSchema>;
export const formShippingShippingScheduleEntryDeliveryCompanyTimeSpanIdSchema = typeMasterDeliveryCompanyTimeSpanIdSchema.optional();
export type FormShippingShippingScheduleEntryDeliveryCompanyTimeSpanIdSchemaType = InferType<typeof formShippingShippingScheduleEntryDeliveryCompanyTimeSpanIdSchema>;
export const formShippingShippingScheduleEntryShippingDetailValueShippingDetailIdSchema = typeShippingDetailIdSchema.optional();
export type FormShippingShippingScheduleEntryShippingDetailValueShippingDetailIdSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueShippingDetailIdSchema>;
export const formShippingShippingScheduleEntryShippingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormShippingShippingScheduleEntryShippingDetailValueSkuIdSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueSkuIdSchema>;
export const formShippingShippingScheduleEntryShippingDetailValueShippingScheduledQuantitySchema = typeShippingScheduledQuantitySchema.optional();
export type FormShippingShippingScheduleEntryShippingDetailValueShippingScheduledQuantitySchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueShippingScheduledQuantitySchema>;
export const formShippingShippingScheduleEntryShippingDetailValueShippingQuantitySchema = typeShippingQuantitySchema.optional();
export type FormShippingShippingScheduleEntryShippingDetailValueShippingQuantitySchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueShippingQuantitySchema>;
export const formShippingShippingScheduleEntryShippingDetailValueShippingCategoryIdSchema = typeShippingCategoryIdSchema.optional();
export type FormShippingShippingScheduleEntryShippingDetailValueShippingCategoryIdSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueShippingCategoryIdSchema>;
export const formShippingShippingScheduleEntryShippingDetailValueShippingDetailNoteSchema = typeCommonNoteSchema;
export type FormShippingShippingScheduleEntryShippingDetailValueShippingDetailNoteSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueShippingDetailNoteSchema>;
export const formShippingShippingScheduleEntryShippingDetailValueSchema = yup.object({
	shippingDetailId: formShippingShippingScheduleEntryShippingDetailValueShippingDetailIdSchema,
	skuId: formShippingShippingScheduleEntryShippingDetailValueSkuIdSchema,
	shippingScheduledQuantity: formShippingShippingScheduleEntryShippingDetailValueShippingScheduledQuantitySchema,
	shippingQuantity: formShippingShippingScheduleEntryShippingDetailValueShippingQuantitySchema,
	shippingCategoryId: formShippingShippingScheduleEntryShippingDetailValueShippingCategoryIdSchema,
	shippingDetailNote: formShippingShippingScheduleEntryShippingDetailValueShippingDetailNoteSchema,
}).optional();
export type FormShippingShippingScheduleEntryShippingDetailValueSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailValueSchema>;
export const formShippingShippingScheduleEntryShippingDetailSchema = yup.array(
	formShippingShippingScheduleEntryShippingDetailValueSchema
).required();
export type FormShippingShippingScheduleEntryShippingDetailSchemaType = InferType<typeof formShippingShippingScheduleEntryShippingDetailSchema>;
export const formShippingShippingScheduleEntrySchema = yup.object({
	orderCode: formShippingShippingScheduleEntryOrderCodeSchema,
	shippingCode: formShippingShippingScheduleEntryShippingCodeSchema,
	shippingScheduledDate: formShippingShippingScheduleEntryShippingScheduledDateSchema,
	shippingNote: formShippingShippingScheduleEntryShippingNoteSchema,
	shipperId: formShippingShippingScheduleEntryShipperIdSchema,
	shipperCode: formShippingShippingScheduleEntryShipperCodeSchema,
	shipperName: formShippingShippingScheduleEntryShipperNameSchema,
	customerId: formShippingShippingScheduleEntryCustomerIdSchema,
	customerCode: formShippingShippingScheduleEntryCustomerCodeSchema,
	customerName: formShippingShippingScheduleEntryCustomerNameSchema,
	customerDeliveryDestinationId: formShippingShippingScheduleEntryCustomerDeliveryDestinationIdSchema,
	customerDeliveryDestinationCode: formShippingShippingScheduleEntryCustomerDeliveryDestinationCodeSchema,
	customerDeliveryDestinationName: formShippingShippingScheduleEntryCustomerDeliveryDestinationNameSchema,
	deliveryCompanyId: formShippingShippingScheduleEntryDeliveryCompanyIdSchema,
	deliveryCompanyCode: formShippingShippingScheduleEntryDeliveryCompanyCodeSchema,
	deliveryCompanyName: formShippingShippingScheduleEntryDeliveryCompanyNameSchema,
	deliveryCompanyBaseId: formShippingShippingScheduleEntryDeliveryCompanyBaseIdSchema,
	deliveryCompanyBaseCode: formShippingShippingScheduleEntryDeliveryCompanyBaseCodeSchema,
	deliveryCompanyBaseName: formShippingShippingScheduleEntryDeliveryCompanyBaseNameSchema,
	deliveryDate: formShippingShippingScheduleEntryDeliveryDateSchema,
	deliveryCompanyTimeSpanId: formShippingShippingScheduleEntryDeliveryCompanyTimeSpanIdSchema,
	shippingDetail: formShippingShippingScheduleEntryShippingDetailSchema,
}).required();
export type FormShippingShippingScheduleEntrySchemaType = InferType<typeof formShippingShippingScheduleEntrySchema>;
