// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formShippingShippingInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormShippingShippingInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formShippingShippingInspectionMobileEntryInspectionQuantitySchema>;
export const formShippingShippingInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormShippingShippingInspectionMobileEntryLotIdSchemaType = InferType<typeof formShippingShippingInspectionMobileEntryLotIdSchema>;
export const formShippingShippingInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formShippingShippingInspectionMobileEntryInspectionQuantitySchema,
	lotId: formShippingShippingInspectionMobileEntryLotIdSchema,
}).required();
export type FormShippingShippingInspectionMobileEntrySchemaType = InferType<typeof formShippingShippingInspectionMobileEntrySchema>;
