// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formShippingShippingInspectionResultShippingIdValueSchema = yup.number().optional().integer();
export type FormShippingShippingInspectionResultShippingIdValueSchemaType = InferType<typeof formShippingShippingInspectionResultShippingIdValueSchema>;
export const formShippingShippingInspectionResultShippingIdSchema = yup.array(
	formShippingShippingInspectionResultShippingIdValueSchema
).optional();
export type FormShippingShippingInspectionResultShippingIdSchemaType = InferType<typeof formShippingShippingInspectionResultShippingIdSchema>;
export const formShippingShippingInspectionResultInspectionQuantityValueSchema = yup.number().optional().integer();
export type FormShippingShippingInspectionResultInspectionQuantityValueSchemaType = InferType<typeof formShippingShippingInspectionResultInspectionQuantityValueSchema>;
export const formShippingShippingInspectionResultInspectionQuantitySchema = yup.array(
	formShippingShippingInspectionResultInspectionQuantityValueSchema
).optional().label("schema.shipping.inspection.list.label.fv.quantity");
export type FormShippingShippingInspectionResultInspectionQuantitySchemaType = InferType<typeof formShippingShippingInspectionResultInspectionQuantitySchema>;
export const formShippingShippingInspectionResultSchema = yup.object({
	shippingId: formShippingShippingInspectionResultShippingIdSchema,
	inspectionQuantity: formShippingShippingInspectionResultInspectionQuantitySchema,
}).required();
export type FormShippingShippingInspectionResultSchemaType = InferType<typeof formShippingShippingInspectionResultSchema>;
