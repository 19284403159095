// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingDateSchema, typeShippingScheduledDateSchema, typeShippingCodeSchema, typeShippingOrderCodeSchema, typeShippingStatusSchema } from "./../../shipping";
import { InferType } from "yup";
import { typeSearchItemsSchema } from "./../../search";

export const formShippingShippingListShippingDateToSchema = typeShippingDateSchema.optional();
export type FormShippingShippingListShippingDateToSchemaType = InferType<typeof formShippingShippingListShippingDateToSchema>;
export const formShippingShippingListShippingDateFromSchema = typeShippingDateSchema.optional();
export type FormShippingShippingListShippingDateFromSchemaType = InferType<typeof formShippingShippingListShippingDateFromSchema>;
export const formShippingShippingListShippingScheduledDateToSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingListShippingScheduledDateToSchemaType = InferType<typeof formShippingShippingListShippingScheduledDateToSchema>;
export const formShippingShippingListShippingScheduledDateFromSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingListShippingScheduledDateFromSchemaType = InferType<typeof formShippingShippingListShippingScheduledDateFromSchema>;
export const formShippingShippingListShippingCodeSchema = typeShippingCodeSchema.optional();
export type FormShippingShippingListShippingCodeSchemaType = InferType<typeof formShippingShippingListShippingCodeSchema>;
export const formShippingShippingListOrderCodeSchema = typeShippingOrderCodeSchema.optional();
export type FormShippingShippingListOrderCodeSchemaType = InferType<typeof formShippingShippingListOrderCodeSchema>;
export const formShippingShippingListShippingStatusSchema = typeShippingStatusSchema.optional();
export type FormShippingShippingListShippingStatusSchemaType = InferType<typeof formShippingShippingListShippingStatusSchema>;
export const formShippingShippingListSearchItemsSchema = typeSearchItemsSchema;
export type FormShippingShippingListSearchItemsSchemaType = InferType<typeof formShippingShippingListSearchItemsSchema>;
export const formShippingShippingListSchema = yup.object({
	shippingDateTo: formShippingShippingListShippingDateToSchema,
	shippingDateFrom: formShippingShippingListShippingDateFromSchema,
	shippingScheduledDateTo: formShippingShippingListShippingScheduledDateToSchema,
	shippingScheduledDateFrom: formShippingShippingListShippingScheduledDateFromSchema,
	shippingCode: formShippingShippingListShippingCodeSchema,
	orderCode: formShippingShippingListOrderCodeSchema,
	shippingStatus: formShippingShippingListShippingStatusSchema,
	searchItems: formShippingShippingListSearchItemsSchema,
}).required();
export type FormShippingShippingListSchemaType = InferType<typeof formShippingShippingListSchema>;
