// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formIssuingIssuingInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormIssuingIssuingInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formIssuingIssuingInspectionMobileEntryInspectionQuantitySchema>;
export const formIssuingIssuingInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormIssuingIssuingInspectionMobileEntryLotIdSchemaType = InferType<typeof formIssuingIssuingInspectionMobileEntryLotIdSchema>;
export const formIssuingIssuingInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formIssuingIssuingInspectionMobileEntryInspectionQuantitySchema,
	lotId: formIssuingIssuingInspectionMobileEntryLotIdSchema,
}).required();
export type FormIssuingIssuingInspectionMobileEntrySchemaType = InferType<typeof formIssuingIssuingInspectionMobileEntrySchema>;
