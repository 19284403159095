// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingScheduledDateSchema, typeShippingDesignateLotSchema, typeShippingPreventLotRotationSchema, typeShippingAllocationPrioritySchema } from "./../../shipping";
import { InferType } from "yup";

export const formShippingShippingAllocationEntryShippingScheduledDateSchema = typeShippingScheduledDateSchema;
export type FormShippingShippingAllocationEntryShippingScheduledDateSchemaType = InferType<typeof formShippingShippingAllocationEntryShippingScheduledDateSchema>;
export const formShippingShippingAllocationEntryDesignateLotSchema = typeShippingDesignateLotSchema.optional();
export type FormShippingShippingAllocationEntryDesignateLotSchemaType = InferType<typeof formShippingShippingAllocationEntryDesignateLotSchema>;
export const formShippingShippingAllocationEntryPreventLotRotationSchema = typeShippingPreventLotRotationSchema.optional();
export type FormShippingShippingAllocationEntryPreventLotRotationSchemaType = InferType<typeof formShippingShippingAllocationEntryPreventLotRotationSchema>;
export const formShippingShippingAllocationEntryAllocationPrioritySchema = typeShippingAllocationPrioritySchema.optional();
export type FormShippingShippingAllocationEntryAllocationPrioritySchemaType = InferType<typeof formShippingShippingAllocationEntryAllocationPrioritySchema>;
export const formShippingShippingAllocationEntrySchema = yup.object({
	shippingScheduledDate: formShippingShippingAllocationEntryShippingScheduledDateSchema,
	designateLot: formShippingShippingAllocationEntryDesignateLotSchema,
	preventLotRotation: formShippingShippingAllocationEntryPreventLotRotationSchema,
	allocationPriority: formShippingShippingAllocationEntryAllocationPrioritySchema,
}).required();
export type FormShippingShippingAllocationEntrySchemaType = InferType<typeof formShippingShippingAllocationEntrySchema>;
