import { number, object } from 'yup';
import { createApiInterface, createApiInterfaceMap } from '../api-interface';
import { formShippingShippingScheduleEntrySchema } from '../../schema/form/shipping/shippingScheduleEntry';
import { formShippingShippingResultEntrySchema } from '../../schema/form/shipping/shippingResultEntry';
import { ListResponse } from '../../response';
import { formShippingShippingListSchema } from '../../schema/form/shipping/shippingList';
import { formShippingShippingSagawaLabelSchema } from '../../schema/form/shipping/shippingSagawaLabel';
import { formShippingShippingSagawaSummaryLabelSchema } from '../../schema/form/shipping/shippingSagawaSummaryLabel';
import { formShippingShippingPickingCreateSchema } from '../../schema/form/shipping/shippingPickingCreate';
import { formShippingShippingPackagingInspectionSearchSchema } from '../../schema/form/shipping/shippingPackagingInspectionSearch';

export type ShippingListResponse = ListResponse & {
	shippingId: number; // 出荷ID
	shippingCode: string; // 出荷コード
	shipperId: number; // 荷主ID
	shipperCode: string; // 荷主コード
	shipperName: string; // 荷主名
	orderId: number; // 受注ID
	orderCode: string; // 受注コード
	customerId: number; // 顧客ID
	customerCode: string; // 顧客コード
	customerName: string; // 顧客名
	shippingStateCode: string;
	shippingStateName: string;
	shippingAllocateCategoryCode: string;
	shippingAllocateCategoryName: string;
	shippingCategoryCode: string;
	shippingCategoryName: string;
	customerDeliveryDestinationId: number; // 顧客配送先ID
	customerDeliveryDestinationCode: string; // 顧客配送先コード
	customerDeliveryDestinationName: string; // 顧客配送先名
	deliveryCompanyContractId: number; // 配送業者契約ID
	deliveryCompanyId: number; // 配送業者ID
	deliveryCompanyCode: string; // 配送業者コード
	deliveryCompanyName: string; // 配送業者名
	deliveryCompanyBaseId: number; // 配送拠点ID
	deliveryCompanyBaseCode: string; // 配送拠点コード
	deliveryCompanyBaseName: string; // 配送拠点名
	shippingScheduledDate: string; // 出荷予定日
	shippingDate: string | undefined; // 出荷日
	shippingNote: string | undefined; // 備考
	deliveryDate: string | undefined; // 配達指定日
	deliveryCompanyTimeSpanId: number | undefined; // 配送業者配達時間帯ID
	deliveryCompanyTimeSpanCode: string | undefined; // 配送業者配達時間帯コード
	deliveryCompanyTimeSpanName: string | undefined; // 配送業者配達時間帯名
};

export type ShippingDetailListResponse = ShippingListResponse & { shippingDetail: ShippingDetail[] };

type ShippingDetail = {
	shippingDetailId: number | undefined; // 出荷明細ID
	skuId: number | undefined; // SKUID
	skuCode: string | undefined; // SKUコード
	productId: number | undefined; // 商品ID
	productCode: string | undefined; // 商品コード
	shippingScheduledQuantity: number | undefined; // 出荷予定数量
	shippingQuantity: number | undefined; // 出荷数量
	shippingDetailNote: string | undefined; // 出荷明細備考
	shippingCategoryId: number | undefined; // 出荷区分ID
	shippingCatogoryCode: string | undefined; // 出荷区分コード
	shippingCategoryName: string | undefined; // 出荷区分名
};

/** 出荷一覧 */
const listApi = createApiInterface().request(formShippingShippingListSchema).response<{
	list: ShippingListResponse[];
}>();

const findSagawaApi = createApiInterface().request(formShippingShippingSagawaLabelSchema).response<{
	shippingId?: number;
	isSuccess: boolean;
	isReissue: boolean;
}>();
const createSagawaApi = createApiInterface().request(formShippingShippingSagawaLabelSchema).response<{
	isSuccess: boolean;
}>();

const findSagawaSummaryApi = createApiInterface().request(formShippingShippingSagawaSummaryLabelSchema).response<{
	list: ShippingListResponse[];
}>();

const deleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		isSuccess: boolean;
	}>();

/** 出荷予定入力 */
const scheduleCreateApi = createApiInterface().request(formShippingShippingScheduleEntrySchema).response<{
	isSuccess: boolean;
}>();

const scheduleUpdateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formShippingShippingScheduleEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const scheduleFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ShippingDetailListResponse[];
	}>();

const scheduleFindInvoiceApi = createApiInterface().request(formShippingShippingPackagingInspectionSearchSchema).response<{
	list: ShippingListResponse[];
}>();

const scheduleDeleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{ isSuccess: boolean }>();

/** 出荷実績入力 */
const resultCreateApi = createApiInterface().request(formShippingShippingResultEntrySchema).response<{
	isSuccess: boolean;
}>();

const resultUpdateApi = createApiInterface()
	.request(object({ id: number().required().integer(), form: formShippingShippingResultEntrySchema }))
	.response<{
		isSuccess: boolean;
	}>();

const resultFindApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{
		list: ShippingDetailListResponse[];
	}>();

const resultDeleteApi = createApiInterface()
	.request(object({ id: number().integer().required() }))
	.response<{ isSuccess: boolean }>();

const pickingCreateApi = createApiInterface().request(formShippingShippingPickingCreateSchema).response<{ isSuccess: boolean }>();

export const shippingApi = createApiInterfaceMap({
	['/v1/shipping/list']: listApi,
	['/v1/shipping/sagawa/find']: findSagawaApi,
	['/v1/shipping/sagawa/create']: createSagawaApi,
	['/v1/shipping/sagawa-summary/find']: findSagawaSummaryApi,
	['/v1/shipping/delete']: deleteApi,
	['/v1/shipping/entry-schedule/create']: scheduleCreateApi,
	['/v1/shipping/entry-schedule/update']: scheduleUpdateApi,
	['/v1/shipping/entry-schedule/find']: scheduleFindApi,
	['/v1/shipping/entry-schedule/find-invoice-number']: scheduleFindInvoiceApi,
	['/v1/shipping/entry-schedule/delete']: scheduleDeleteApi,
	['/v1/shipping/entry-result/create']: resultCreateApi,
	['/v1/shipping/entry-result/update']: resultUpdateApi,
	['/v1/shipping/entry-result/find']: resultFindApi,
	['/v1/shipping/entry-result/delete']: resultDeleteApi,
	['/v1/shipping/picking/create']: pickingCreateApi,
});
