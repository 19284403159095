// fv-generatorで生成されました
import * as yup from "yup";
import { InferType } from "yup";

export const formStockStockEntryChangeAfterStockQuantitySchema = yup.number().required().label("schema.label.stockList.column.modal.change.after.stock.quantity").integer();
export type FormStockStockEntryChangeAfterStockQuantitySchemaType = InferType<typeof formStockStockEntryChangeAfterStockQuantitySchema>;
export const formStockStockEntrySchema = yup.object({
	changeAfterStockQuantity: formStockStockEntryChangeAfterStockQuantitySchema,
}).required();
export type FormStockStockEntrySchemaType = InferType<typeof formStockStockEntrySchema>;
