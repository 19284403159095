// fv-generatorで生成されました
import * as yup from "yup";
import { typeCommonInspectionQuantitySchema } from "./../../common";
import { InferType } from "yup";
import { typeMasterProductLotIdSchema } from "./../../master";

export const formArrivalArrivalInspectionMobileEntryInspectionQuantitySchema = typeCommonInspectionQuantitySchema.optional();
export type FormArrivalArrivalInspectionMobileEntryInspectionQuantitySchemaType = InferType<typeof formArrivalArrivalInspectionMobileEntryInspectionQuantitySchema>;
export const formArrivalArrivalInspectionMobileEntryLotIdSchema = typeMasterProductLotIdSchema.optional();
export type FormArrivalArrivalInspectionMobileEntryLotIdSchemaType = InferType<typeof formArrivalArrivalInspectionMobileEntryLotIdSchema>;
export const formArrivalArrivalInspectionMobileEntryProductStatusValueSchema = yup.number().optional().integer();
export type FormArrivalArrivalInspectionMobileEntryProductStatusValueSchemaType = InferType<typeof formArrivalArrivalInspectionMobileEntryProductStatusValueSchema>;
export const formArrivalArrivalInspectionMobileEntryProductStatusSchema = yup.array(
	formArrivalArrivalInspectionMobileEntryProductStatusValueSchema
).optional();
export type FormArrivalArrivalInspectionMobileEntryProductStatusSchemaType = InferType<typeof formArrivalArrivalInspectionMobileEntryProductStatusSchema>;
export const formArrivalArrivalInspectionMobileEntrySchema = yup.object({
	inspectionQuantity: formArrivalArrivalInspectionMobileEntryInspectionQuantitySchema,
	lotId: formArrivalArrivalInspectionMobileEntryLotIdSchema,
	productStatus: formArrivalArrivalInspectionMobileEntryProductStatusSchema,
}).required();
export type FormArrivalArrivalInspectionMobileEntrySchemaType = InferType<typeof formArrivalArrivalInspectionMobileEntrySchema>;
