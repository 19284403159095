// fv-generatorで生成されました
import * as yup from "yup";
import { typeShippingOrderCodeSchema, typeShippingCodeSchema, typeShippingScheduledDateSchema, typeShippingDateSchema, typeShippingDetailIdSchema, typeShippingScheduledQuantitySchema, typeShippingQuantitySchema, typeShippingCategoryIdSchema } from "./../../shipping";
import { InferType } from "yup";
import { typeCommonNoteSchema } from "./../../common";
import { typeMasterShipperIdSchema, typeMasterShipperCodeSchema, typeMasterShipperNameSchema, typeMasterCustomerIdSchema, typeMasterCustomerCodeSchema, typeMasterCustomerNameSchema, typeMasterCustomerDeliveryDestinationIdSchema, typeMasterCustomerDeliveryDestinationCodeSchema, typeMasterCustomerDeliveryDestinationNameSchema, typeMasterDeliveryCompanyIdSchema, typeMasterDeliveryCompanyCodeSchema, typeMasterDeliveryCompanyNameSchema, typeMasterDeliveryCompanyBaseIdSchema, typeMasterDeliveryCompanyBaseCodeSchema, typeMasterDeliveryCompanyBaseNameSchema, typeMasterDeliveryCompanyDeliveryDateSchema, typeMasterDeliveryCompanyTimeSpanIdSchema, typeMasterProductSkuIdSchema } from "./../../master";

export const formShippingShippingResultEntryOrderCodeSchema = typeShippingOrderCodeSchema;
export type FormShippingShippingResultEntryOrderCodeSchemaType = InferType<typeof formShippingShippingResultEntryOrderCodeSchema>;
export const formShippingShippingResultEntryShippingCodeSchema = typeShippingCodeSchema;
export type FormShippingShippingResultEntryShippingCodeSchemaType = InferType<typeof formShippingShippingResultEntryShippingCodeSchema>;
export const formShippingShippingResultEntryShippingScheduledDateSchema = typeShippingScheduledDateSchema.optional();
export type FormShippingShippingResultEntryShippingScheduledDateSchemaType = InferType<typeof formShippingShippingResultEntryShippingScheduledDateSchema>;
export const formShippingShippingResultEntryShippingDateSchema = typeShippingDateSchema;
export type FormShippingShippingResultEntryShippingDateSchemaType = InferType<typeof formShippingShippingResultEntryShippingDateSchema>;
export const formShippingShippingResultEntryShippingNoteSchema = typeCommonNoteSchema;
export type FormShippingShippingResultEntryShippingNoteSchemaType = InferType<typeof formShippingShippingResultEntryShippingNoteSchema>;
export const formShippingShippingResultEntryShipperIdSchema = typeMasterShipperIdSchema;
export type FormShippingShippingResultEntryShipperIdSchemaType = InferType<typeof formShippingShippingResultEntryShipperIdSchema>;
export const formShippingShippingResultEntryShipperCodeSchema = typeMasterShipperCodeSchema;
export type FormShippingShippingResultEntryShipperCodeSchemaType = InferType<typeof formShippingShippingResultEntryShipperCodeSchema>;
export const formShippingShippingResultEntryShipperNameSchema = typeMasterShipperNameSchema;
export type FormShippingShippingResultEntryShipperNameSchemaType = InferType<typeof formShippingShippingResultEntryShipperNameSchema>;
export const formShippingShippingResultEntryCustomerIdSchema = typeMasterCustomerIdSchema;
export type FormShippingShippingResultEntryCustomerIdSchemaType = InferType<typeof formShippingShippingResultEntryCustomerIdSchema>;
export const formShippingShippingResultEntryCustomerCodeSchema = typeMasterCustomerCodeSchema;
export type FormShippingShippingResultEntryCustomerCodeSchemaType = InferType<typeof formShippingShippingResultEntryCustomerCodeSchema>;
export const formShippingShippingResultEntryCustomerNameSchema = typeMasterCustomerNameSchema;
export type FormShippingShippingResultEntryCustomerNameSchemaType = InferType<typeof formShippingShippingResultEntryCustomerNameSchema>;
export const formShippingShippingResultEntryCustomerDeliveryDestinationIdSchema = typeMasterCustomerDeliveryDestinationIdSchema;
export type FormShippingShippingResultEntryCustomerDeliveryDestinationIdSchemaType = InferType<typeof formShippingShippingResultEntryCustomerDeliveryDestinationIdSchema>;
export const formShippingShippingResultEntryCustomerDeliveryDestinationCodeSchema = typeMasterCustomerDeliveryDestinationCodeSchema;
export type FormShippingShippingResultEntryCustomerDeliveryDestinationCodeSchemaType = InferType<typeof formShippingShippingResultEntryCustomerDeliveryDestinationCodeSchema>;
export const formShippingShippingResultEntryCustomerDeliveryDestinationNameSchema = typeMasterCustomerDeliveryDestinationNameSchema;
export type FormShippingShippingResultEntryCustomerDeliveryDestinationNameSchemaType = InferType<typeof formShippingShippingResultEntryCustomerDeliveryDestinationNameSchema>;
export const formShippingShippingResultEntryDeliveryCompanyIdSchema = typeMasterDeliveryCompanyIdSchema;
export type FormShippingShippingResultEntryDeliveryCompanyIdSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyIdSchema>;
export const formShippingShippingResultEntryDeliveryCompanyCodeSchema = typeMasterDeliveryCompanyCodeSchema;
export type FormShippingShippingResultEntryDeliveryCompanyCodeSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyCodeSchema>;
export const formShippingShippingResultEntryDeliveryCompanyNameSchema = typeMasterDeliveryCompanyNameSchema;
export type FormShippingShippingResultEntryDeliveryCompanyNameSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyNameSchema>;
export const formShippingShippingResultEntryDeliveryCompanyBaseIdSchema = typeMasterDeliveryCompanyBaseIdSchema;
export type FormShippingShippingResultEntryDeliveryCompanyBaseIdSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyBaseIdSchema>;
export const formShippingShippingResultEntryDeliveryCompanyBaseCodeSchema = typeMasterDeliveryCompanyBaseCodeSchema;
export type FormShippingShippingResultEntryDeliveryCompanyBaseCodeSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyBaseCodeSchema>;
export const formShippingShippingResultEntryDeliveryCompanyBaseNameSchema = typeMasterDeliveryCompanyBaseNameSchema;
export type FormShippingShippingResultEntryDeliveryCompanyBaseNameSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyBaseNameSchema>;
export const formShippingShippingResultEntryDeliveryDateSchema = typeMasterDeliveryCompanyDeliveryDateSchema.optional();
export type FormShippingShippingResultEntryDeliveryDateSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryDateSchema>;
export const formShippingShippingResultEntryDeliveryCompanyTimeSpanIdSchema = typeMasterDeliveryCompanyTimeSpanIdSchema.optional();
export type FormShippingShippingResultEntryDeliveryCompanyTimeSpanIdSchemaType = InferType<typeof formShippingShippingResultEntryDeliveryCompanyTimeSpanIdSchema>;
export const formShippingShippingResultEntryShippingDetailValueShippingDetailIdSchema = typeShippingDetailIdSchema.optional();
export type FormShippingShippingResultEntryShippingDetailValueShippingDetailIdSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueShippingDetailIdSchema>;
export const formShippingShippingResultEntryShippingDetailValueSkuIdSchema = typeMasterProductSkuIdSchema.optional();
export type FormShippingShippingResultEntryShippingDetailValueSkuIdSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueSkuIdSchema>;
export const formShippingShippingResultEntryShippingDetailValueShippingScheduledQuantitySchema = typeShippingScheduledQuantitySchema.optional();
export type FormShippingShippingResultEntryShippingDetailValueShippingScheduledQuantitySchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueShippingScheduledQuantitySchema>;
export const formShippingShippingResultEntryShippingDetailValueShippingQuantitySchema = typeShippingQuantitySchema.optional();
export type FormShippingShippingResultEntryShippingDetailValueShippingQuantitySchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueShippingQuantitySchema>;
export const formShippingShippingResultEntryShippingDetailValueShippingCategoryIdSchema = typeShippingCategoryIdSchema.optional();
export type FormShippingShippingResultEntryShippingDetailValueShippingCategoryIdSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueShippingCategoryIdSchema>;
export const formShippingShippingResultEntryShippingDetailValueShippingDetailNoteSchema = typeCommonNoteSchema;
export type FormShippingShippingResultEntryShippingDetailValueShippingDetailNoteSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueShippingDetailNoteSchema>;
export const formShippingShippingResultEntryShippingDetailValueSchema = yup.object({
	shippingDetailId: formShippingShippingResultEntryShippingDetailValueShippingDetailIdSchema,
	skuId: formShippingShippingResultEntryShippingDetailValueSkuIdSchema,
	shippingScheduledQuantity: formShippingShippingResultEntryShippingDetailValueShippingScheduledQuantitySchema,
	shippingQuantity: formShippingShippingResultEntryShippingDetailValueShippingQuantitySchema,
	shippingCategoryId: formShippingShippingResultEntryShippingDetailValueShippingCategoryIdSchema,
	shippingDetailNote: formShippingShippingResultEntryShippingDetailValueShippingDetailNoteSchema,
}).optional();
export type FormShippingShippingResultEntryShippingDetailValueSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailValueSchema>;
export const formShippingShippingResultEntryShippingDetailSchema = yup.array(
	formShippingShippingResultEntryShippingDetailValueSchema
).required();
export type FormShippingShippingResultEntryShippingDetailSchemaType = InferType<typeof formShippingShippingResultEntryShippingDetailSchema>;
export const formShippingShippingResultEntrySchema = yup.object({
	orderCode: formShippingShippingResultEntryOrderCodeSchema,
	shippingCode: formShippingShippingResultEntryShippingCodeSchema,
	shippingScheduledDate: formShippingShippingResultEntryShippingScheduledDateSchema,
	shippingDate: formShippingShippingResultEntryShippingDateSchema,
	shippingNote: formShippingShippingResultEntryShippingNoteSchema,
	shipperId: formShippingShippingResultEntryShipperIdSchema,
	shipperCode: formShippingShippingResultEntryShipperCodeSchema,
	shipperName: formShippingShippingResultEntryShipperNameSchema,
	customerId: formShippingShippingResultEntryCustomerIdSchema,
	customerCode: formShippingShippingResultEntryCustomerCodeSchema,
	customerName: formShippingShippingResultEntryCustomerNameSchema,
	customerDeliveryDestinationId: formShippingShippingResultEntryCustomerDeliveryDestinationIdSchema,
	customerDeliveryDestinationCode: formShippingShippingResultEntryCustomerDeliveryDestinationCodeSchema,
	customerDeliveryDestinationName: formShippingShippingResultEntryCustomerDeliveryDestinationNameSchema,
	deliveryCompanyId: formShippingShippingResultEntryDeliveryCompanyIdSchema,
	deliveryCompanyCode: formShippingShippingResultEntryDeliveryCompanyCodeSchema,
	deliveryCompanyName: formShippingShippingResultEntryDeliveryCompanyNameSchema,
	deliveryCompanyBaseId: formShippingShippingResultEntryDeliveryCompanyBaseIdSchema,
	deliveryCompanyBaseCode: formShippingShippingResultEntryDeliveryCompanyBaseCodeSchema,
	deliveryCompanyBaseName: formShippingShippingResultEntryDeliveryCompanyBaseNameSchema,
	deliveryDate: formShippingShippingResultEntryDeliveryDateSchema,
	deliveryCompanyTimeSpanId: formShippingShippingResultEntryDeliveryCompanyTimeSpanIdSchema,
	shippingDetail: formShippingShippingResultEntryShippingDetailSchema,
}).required();
export type FormShippingShippingResultEntrySchemaType = InferType<typeof formShippingShippingResultEntrySchema>;
